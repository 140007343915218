import React, { useState, useEffect } from 'react';
import * as useStateRef from 'react-usestateref';
import { Button, Modal, message, Loading } from '@tencent/ten-design-react';
import md5 from 'crypto-js/md5';
import ImsTour from '../../lib/imsTour/imsTour';
import { Player } from '@tencent/vtour';
import { CubemapViewer } from '@tencent/vviewer';
import Tags from '../../components/tags/index';
import Mosaics from '../../components/mosaics/index';
import ModelTitle from '../../components/modelTitle/index';
import ModelLoading from '../../components/modelLoading/index';
import { HelperAside } from './subComponent/helperAside';
import { StyleAside } from './subComponent/styleAside';
import { StyleSimulator } from './subComponent/styleSimulator';
import { TagButtonGroup } from './subComponent/tagButtonGroup';
import { SceneList } from './subComponent/sceneList';
import { getQueryVariable, parseImage, debounce, vTourDataMaker, createTextTexture, compressBase64Image, dataURLtoFile } from '../../utils/tools';
import { waterMask } from '../../utils/waterMask';
import client from '../../utils/apiClient';
import uploadFile from '../../utils/uploadFile';
import formatJson from '../../utils/formatJson';
import convertorData from '../../utils/convertorData';
import modelIcon from '../../assets/images/svg/icon-scene-model.svg';
import panoramicIcon from '../../assets/images/svg/icon-scene-panoramic.svg';
import './index.css';

const axios = require('axios').default;

// === 常量初始化 ===
const { THREE } = ImsTour;
const MOSAIC_INIT_SIZE = 50; // 马赛克初始大小
const IV = 'mt11G34f816eHe1c'; // 加解密模型向量
const siderMenu = [
  { key: 'init', text: '初始位置', class: 'location' },
  { key: 'tag', text: '标签设置', class: 'tag' },
  { key: 'mosaic', text: '马赛克', class: 'mosaic' },
  { key: 'scenes', text: '场景关联', class: 'scene-link' },
  { key: 'style', text: '样式设置', class: 'style' },
];
const modalTitleMap = {
  init: '初始位置',
  tag: '标签',
  mosaic: '马赛克',
  scenes: '关联场景',
  style: '样式设置',
};
const panoNumberMeshArr = [];
const els = {};
// === 变量初始化 ===
let vTour;
let vTourData;
let DECORATION_INFO = {}; // 平台可修改的项目模型合集信息
let MODEL_DECORATION_INFO = {}; // 平台可修改的模型信息
let hasMosaicedon = false; // 开启打马赛克后，是否打过
let vMosaicModeFromList = false; // 从右侧列表进来的是否打马赛克中
let currentEditMosaicIndex = -1; // 当前编辑的马赛克序号，初始为 -1
let currentDeleteTag; // 暂存准备删除的 tag
let HAS_INITED = false; // 已经初始化过模型
let sdkType = ''; // 区分实验室跟贝壳
let MODEL_KEY = ''; // 加解密模型
let PROJECT_UUID = ''; // 加解密模型
let owner; // TODO 可能需要在切换渲染模型之后更新

function Detail() {
  const PROJECT_INFO = { // 建模后不可改的模型相关信息
    projectId: getQueryVariable('projectId'),
  };
  const [modelName, setModelName] = useState(''); // 模型名称
  const [tagsList, setTagsList] = useState([]); // 所有的标签 for 右侧列表
  const [showTags, setShowTags] = useState([]); // 当前屏幕内要展示的 tags
  const [mosaicsList, setMosaicsList] = useState([]); // 所有的马赛克 for 右侧列表
  const [showMosaics, setShowMosaics] = useState([]); // 当前屏幕内要展示的 mosaics
  const [currentPanoIndex, setCurrentPanoIndex] = useState(1); // 当前点位
  const [initPanoIndex, setInitPanoIndex] = useState(1); // 初始点位
  const [currentFov, setCurrentFov] = useState(100);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [initType, setInitType] = useState(''); // point: 点位设置  !point: 封面
  const [viewShotSrc, setViewShotSrc] = useState(''); // 封面图 base64 数据
  const [viewSenseSrc, setviewSenseSrc] = useState(''); // 初始角度 base64 数据
  const [viewShotSrcTmp, setViewShotSrcTmp] = useState(''); // modal 展示封面图
  const [modelIsLoading, setModelIsLoading] = useState(true); // 模型加载中
  const [panoImgLoading, setPanoImgLoading] = useState(false); // 全景点位图片加载中
  const [mosaicSize, setMosaicSize] = useState(0);
  const [uploadCoverStatus, setUploadCoverStatus] = useState(false); // 上传封面状态
  const [materialUrls, setMaterialUrls] = useState({}); // materialIds 对应的实际 Url
  const [simulatorStyles, setSimulatorStyles] = useState(null);
  const [modifyKey, setModifyKey] = useState('');
  const [currentEditingTag, setCurrentEditingTag] = useState(null);
  const [vrMode, setVrMode, vrModeRef] = useStateRef('pano');
  const [materialId, setMaterialId, materialIdRef] = useStateRef(''); // 当前选中的场景模型 id
  const [mosaicMode, setMosaicMode, mosaicModeRef] = useStateRef(false); // 是否打马赛克中
  // eslint-disable-next-line no-unused-vars
  const [tagModeType, setTagModeType, tagModeTypeRef] = useStateRef(''); // 是否打标签中及其类型
  const [editType, setEditType, editTypeRef] = useStateRef('init'); // init: 初始点位, tag: 标签设置, mosaic: 马赛克, scenes: 场景关联, style: 样式设置
  const [materialIdsInProject, setMaterialIdsInProject, materialIdsInProjectRef] = useStateRef([]);
  const [materialsInProject, setMaterialsInProject, materialsInProjectRef] = useStateRef([]);
  const [labSdkType, setLabSdkType, labSdkTypeRef] = useStateRef(''); // 区分实验室本身的 sdk

  function getProjects() {
    return client('PaaS/Project/DescribeProjects', { body: { ProjectIds: [PROJECT_INFO.projectId], Category: 'ImmersiveViewing' } });
  }

  function getMaterialFromProject() {
    return client('PaaS/Project/DescribeProjectMaterials', { body: { ProjectId: PROJECT_INFO.projectId } });
  }

  // 贝壳 SDK 初始化
  function initImsTour() {
    vTour = new ImsTour({
      mode: ImsTour.Mode.Panorama,
      fov: MODEL_DECORATION_INFO?.initInfo?.fov || 100,
      panoIndex: MODEL_DECORATION_INFO?.initInfo?.panoIndex || 30,
      longitude: MODEL_DECORATION_INFO?.initInfo?.longitude || 1.3037607459427605,
      latitude: MODEL_DECORATION_INFO?.initInfo?.latitude || -0.009540685247268463,
      initWithTransition: false,
      // 这个比需开启才能使用 canvas 的 toDataUrl 进行截图
      preserveDrawingBuffer: true,
      imageOptions: {
        size: 2000,
        quality: 100,
        format: 'jpg',
        transform: parseImage,
      },
      // 模型贴图参数
      textureOptions: {
        size: 1024,
        quality: 100,
        format: 'jpg',
        transform: parseImage,
      },
      onlyRenderIfNeeds: true,
    });
    vTour.appendTo(els.root);

    vTour.on('modeChange', (mode) => {
      setVrMode(mode);
    });

    // 模型加载完成
    vTour.on('modelLoaded', () => {
      modelLoaded();
    });

    // 视角更新，更新 tag 和 mosaic 位置
    vTour.on('cameraDirectionUpdate', () => {
      if (editTypeRef.current === 'tag' || editTypeRef.current === 'scenes') computePosition('tag', MODEL_DECORATION_INFO.tags);
      if (editTypeRef.current === 'mosaic') computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
    });

    // 视角更新，重新画点位
    vTour.on('cameraDirectionUpdate', debounce(500, () => {
      if (vTour.model?.intersectRaycaster) {
        panoCircleNumber();
      }
    }));

    vTour.on('wantsToMoveToPano', (panoIndex) => {
      if (panoIndex) setPanoImgLoading(true);
    });

    // 位置更新，更新 tag 和 mosaic 位置
    vTour.on('movingToPano', () => {
      setPanoImgLoading(false);
      if (editTypeRef.current === 'tag' || editTypeRef.current === 'scenes') computePosition('tag', MODEL_DECORATION_INFO.tags);
      if (editTypeRef.current === 'mosaic') computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
    });

    // 一次只能打一个，多次点击会不断更新位置
    vTour.on('wantsTapGesture', (raycaster) => {
      const intersects = vTour.model.intersectRaycaster(raycaster);
      if ((mosaicModeRef.current || tagModeTypeRef.current) && intersects.length) {
        const intersect = intersects[0];
        const { longitude, latitude } = vTour.getLongitudeAndLatitude();
        const { panoIndex } = vTour;
        // 打马赛克
        if (mosaicModeRef.current && !vMosaicModeFromList) {
          // mosaicUpdatingInfo 为每次打点必定更新的信息
          const mosaicUpdatingInfo = {
            position: intersect.point.clone(),
            longitude,
            latitude,
            panoIndex,
          };
          // 区分是新增还是修改
          if (currentEditMosaicIndex === -1) {
            // 新增
            const mosaicsLen = MODEL_DECORATION_INFO.mosaics.length;
            const initMosaic = Object.assign(mosaicUpdatingInfo, {
              sourceIndex: mosaicsLen === 0
                ? 0 : MODEL_DECORATION_INFO.mosaics[mosaicsLen - 1].sourceIndex + 1,
            });
            MODEL_DECORATION_INFO.mosaics.push(Object.assign(initMosaic, {
              height: MOSAIC_INIT_SIZE,
              width: MOSAIC_INIT_SIZE,
            }));
            currentEditMosaicIndex = initMosaic.sourceIndex; // MODEL_DECORATION_INFO.mosaics.length - 1;
          } else {
            // 修改
            const currentEditMosaic = MODEL_DECORATION_INFO.mosaics.find(mosaic => (
              mosaic.sourceIndex === currentEditMosaicIndex));
            const addedMosaic = Object.assign(mosaicUpdatingInfo, {
              sourceIndex: currentEditMosaic.sourceIndex, // 修改位置的时候直接继承新增的 sourceIndex
              height: currentEditMosaic.height,
              width: currentEditMosaic.width,
            });
            MODEL_DECORATION_INFO.mosaics.splice(-1, 1, Object.assign(addedMosaic, {
              height: addedMosaic.height,
              width: addedMosaic.width,
            }));
          }
          hasMosaicedon = true;
          setMosaicSize(0); // 重置放大比例
          computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
          setMosaicsList([].concat(MODEL_DECORATION_INFO.mosaics));
        }
        // 打标签
        if (tagModeTypeRef.current) {
          const currentEditTag = MODEL_DECORATION_INFO.tags[MODEL_DECORATION_INFO.tags.length - 1];
          const position = intersect.point.clone();
          const vrPoint = {
            position: [position.x, position.y, position.z],
            lonLat: [longitude, latitude],
            panoIndex,
          };
          addOrUpdateTag(MODEL_DECORATION_INFO.tags.length - 1, currentEditTag.mediaData, vrPoint);
        }
        return false;
      }
    });

    vTour.on('panoArrived', (panoIndex) => {
      setCurrentPanoIndex(panoIndex + 1);
      // 页面刚进来的时候模型还没加载，无法加工点位样式
      if (vTour.model?.intersectRaycaster) {
        panoCircleNumber();
      }
    });

    // 触发景深缩放，更新当前景深
    vTour.on('mouseWheel', (delta, nextFov, isFinal) => {
      if (isFinal) setCurrentFov(Math.round(nextFov));
    });
  }

  // 实验室 SDK 初始化
  async function initVTour(data, modelVsersion) {
    const instanceData = convertorData(data, MODEL_DECORATION_INFO, labSdkTypeRef.current);
    if (modelVsersion >= 1.3) {
      const newIv = md5((IV + PROJECT_UUID).split('').reverse()
        .join(''))
        .toString()
        .substr(0, 16);
      instanceData.data.kiv = [MODEL_KEY, newIv];
    }
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour = new CubemapViewer({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          ctlDampingFactor: 0.15,
        }),
      });
    } else {
      vTour = new Player({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          // ctlDampingFactor: 0.15,
          fov: MODEL_DECORATION_INFO.initInfo ? MODEL_DECORATION_INFO.initInfo.fov : 100,
          // preserveDrawingBuffer: true,
          enableKeyControl: false,
          autoResize: false,
          hotspotEnabled: true,
          compassEnabled: true,
          cursorEnabled: true,
          compassURL: 'https://cvr.tencentcs.com/invision-lab/compass.png',
          cursorURL: 'https://cvr.tencentcs.com/invision-lab/hotspot_alpha.jpg',
          hotspotURL: 'https://cvr.tencentcs.com/invision-lab/hotspot_alpha.jpg',
        }),
      });
    }
    await vTour.start();
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour.updateView({
        position: [0, 0.4, 0],
        lonLat: [0, 0],
        duration: 0,
        progress: 1,
      });
      setTimeout(() => {
        vTour.updateView({
          position: [0, 0, 0],
          lonLat: [0, 0],
          duration: 1500,
        });
      }, 2000);
    }
    // 模型加载完
    modelLoaded();
    // 如果显示区域需要变动，在变动时请调用 refresh 重置显示参数
    // window.addEventListener('resize', () => {
    //   vTour.refresh();
    // });

    // 开始前往下一个点位
    vTour.on('moveStart', (e) => {
      console.log('=====moveStart====', e);
    });
    vTour.on('moveEnd', () => {
      setPanoImgLoading(false);
      labSdkTypeRef.current !== 'lab-aerial' && setCurrentPanoIndex(parseInt(vTour.currentPano.index, 10) + 1);
      if (editTypeRef.current === 'tag' || editTypeRef.current === 'scenes') computePosition('tag', MODEL_DECORATION_INFO.tags);
      if (editTypeRef.current === 'mosaic') computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
    });
    // 点击某一点位
    vTour.on('pointerIntersect', (e) => {
      // 打马赛克
      if (mosaicModeRef.current && !vMosaicModeFromList) {
        // mosaicUpdatingInfo 为每次打点必定更新的信息
        const mosaicUpdatingInfo = {
          position: {
            x: e.position[0],
            y: e.position[1],
            z: e.position[2],
          },
          longitude: e.lonLat[0],
          latitude: e.lonLat[1],
          panoIndex: labSdkTypeRef.current === 'lab-aerial' ? 0 : parseInt(vTour.currentPano.index, 10),
        };
        // 区分是新增还是修改
        if (currentEditMosaicIndex === -1) {
          // 新增
          const mosaicsLen = MODEL_DECORATION_INFO.mosaics.length;
          const initMosaic = Object.assign(mosaicUpdatingInfo, {
            sourceIndex: mosaicsLen === 0
              ? 0 : MODEL_DECORATION_INFO.mosaics[mosaicsLen - 1].sourceIndex + 1,
          });
          MODEL_DECORATION_INFO.mosaics.push(Object.assign(initMosaic, {
            height: MOSAIC_INIT_SIZE,
            width: MOSAIC_INIT_SIZE,
          }));
          currentEditMosaicIndex = initMosaic.sourceIndex; // MODEL_DECORATION_INFO.mosaics.length - 1;
        } else {
          // 修改
          const currentEditMosaic = MODEL_DECORATION_INFO.mosaics.find(mosaic => (
            mosaic.sourceIndex === currentEditMosaicIndex));
          const addedMosaic = Object.assign(mosaicUpdatingInfo, {
            sourceIndex: currentEditMosaic.sourceIndex, // 修改位置的时候直接继承新增的 sourceIndex
            height: currentEditMosaic.height,
            width: currentEditMosaic.width,
          });
          MODEL_DECORATION_INFO.mosaics.splice(-1, 1, Object.assign(addedMosaic, {
            height: addedMosaic.height,
            width: addedMosaic.width,
          }));
        }
        hasMosaicedon = true;
        setMosaicSize(0); // 重置放大比例
        computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
        setMosaicsList([].concat(MODEL_DECORATION_INFO.mosaics));
      }
      if (mosaicModeRef.current || tagModeTypeRef.current) {
        // 打标签
        if (tagModeTypeRef.current) {
          const currentEditTag = MODEL_DECORATION_INFO.tags[MODEL_DECORATION_INFO.tags.length - 1];
          const vrPoint = { // TODO 先写死
            position: e?.position,
            lonLat: e?.lonLat,
            panoIndex: labSdkTypeRef.current === 'lab-aerial' ? 0 : parseInt(vTour.currentPano.index, 10),
          };
          addOrUpdateTag(MODEL_DECORATION_INFO.tags.length - 1, currentEditTag.mediaData, vrPoint);
        }
      }
    });

    // 开始切换场景
    vTour.on('sceneChangeStart', (e) => {
      console.log('=====sceneChangeStart====', e);
    });

    // 完成切换场景
    vTour.on('sceneChangeEnd', (e) => {
      console.log('=====sceneChangeEnd====', e);
    });

    // 观看模式切换
    vTour.on('modeChangeEnd', (e) => {
      console.log('====modeChangeEnd=====', e);
      if (vrModeRef.current !== e.mode) setVrMode(e.mode);
    });

    // 相机角度切换
    vTour.on('cameraChange', () => {
      if (editTypeRef.current === 'tag' || editTypeRef.current === 'scenes') computePosition('tag', MODEL_DECORATION_INFO.tags);
      if (editTypeRef.current === 'mosaic') computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
    });
  }

  function modelLoaded() {
    setModelIsLoading(false);
    panoCircleNumber();
    setCurrentPanoIndex(parseInt(vTour.currentPano?.index || vTour.panoIndex, 10) + 1);
    setInitPanoIndex(parseInt(vTour.currentPano?.index || vTour.panoIndex, 10) + 1); // 当前点位获取两个 sdk 不一样
    // 需要模型加载完才可以截屏
    if (MODEL_DECORATION_INFO.modelCover) {
      setViewShotSrc(MODEL_DECORATION_INFO.modelCover);
    }
    if (MODEL_DECORATION_INFO.modelInitView) {
      setviewSenseSrc(MODEL_DECORATION_INFO.modelInitView);
    }
  }

  // 获取模型原始数据 & 标签数据
  function getVTourData() {
    client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: [...materialIdsInProjectRef.current] } }).then((res) => {
      if (!res.data.Data) {
        message.error(res.data.Message, 1000);
      } else {
        const { MaterialInfoSet, FailedMaterialInfoSet } = res.data.Data;
        let data;
        if (FailedMaterialInfoSet.length) {
          // 有资源获取不到，maybe 不存在
          if (MaterialInfoSet.length) {
            const materialIds = [];
            // eslint-disable-next-line max-len
            MaterialInfoSet.forEach((item) => {
              if (materialIdRef.current === item.BasicInfo.MaterialId) data = item;
              materialIds.push(item.BasicInfo.MaterialId);
            });
            if (!data) [data] = MaterialInfoSet;
            setMaterialsInProject(res.data.Data.MaterialInfoSet);
            processVTourData(data);
            setMaterialIdsInProject(materialIds);
            setMaterialId(materialIds[0]);
          }
        } else {
          data = MaterialInfoSet.find(item => materialIdRef.current === item.BasicInfo.MaterialId);
          setMaterialsInProject(res.data.Data.MaterialInfoSet);
          processVTourData(data);
        }
      }
    });
  }

  // 处理模型数据
  function processVTourData(data) {
    owner = { // 初始化 owner for 上传
      Id: data.BasicInfo.MaterialId,
      Type: 'MATERIAL',
    };
    setModelName(data.BasicInfo.Name || '________');
    MODEL_KEY = data.Immersive3DModelMaterial.ModelKey || '';
    setTagsList([].concat(MODEL_DECORATION_INFO.tags || []));
    setMosaicsList([].concat(MODEL_DECORATION_INFO.mosaics || []));
    setCurrentFov(MODEL_DECORATION_INFO.initInfo ? MODEL_DECORATION_INFO.initInfo.fov : 100);
    if (MODEL_DECORATION_INFO.tags) {
      getMaterialUrls();
    } else {
      MODEL_DECORATION_INFO.tags = [];
    }
    if (!MODEL_DECORATION_INFO.mosaics) {
      MODEL_DECORATION_INFO.mosaics = [];
    }
    if (editTypeRef.current === 'tag' || editTypeRef.current === 'scenes') computePosition('tag', MODEL_DECORATION_INFO.tags); // 更新标签后需要刷新 tag
    if (!HAS_INITED) {
      if (data.Immersive3DModelMaterial.IndexUrl !== '') {
        sdkType = 'lab';
        getModelData(data.Immersive3DModelMaterial.IndexUrl);
      } else {
        sdkType = 'realsee';
        initImsTour();
        waterMask(vTour);
        const modelData = JSON.parse(data.Immersive3DModelMaterial.Index);
        vTourData = formatJson(modelData.ModelDetail);
        vTour.load(vTourData);
      }
      refreshCanvas();
      HAS_INITED = true;
    }
  }

  // 实验室 SDK 需额外获取全景数据
  function getModelData(url) {
    axios({
      method: 'GET',
      url,
      responseType: 'json',
    }).then((res) => {
      if (res.data.MaterialType === 'XRLabAerial') {
        // 星球模型
        vTourData = res.data.ModelDetail;
        setLabSdkType('lab-aerial');
        setEditType('tag');
      } else {
        setLabSdkType('');
        vTourData = vTourDataMaker(res.data.ModelDetail);
      }
      PROJECT_UUID = vTourData.projectID;
      initVTour(vTourData, res.data.ModelVersion);
    });
  }

  function getMaterialId(media) {
    if (!media.includes('//')) {
      let id = '';
      if (media.includes('@-@')) {
        [id] = media.split('@-@');
      } else {
        id = media;
      }
      return id;
    }
    return '';
  }

  // 防盗链预处理，媒资 id 换取实际的 url
  function getMaterialUrls() {
    const ids = {};
    MODEL_DECORATION_INFO.tags.forEach((tag) => {
      const { mediaSrc, coverSrc } = tag.mediaData;
      const mediaId = mediaSrc ? getMaterialId(mediaSrc) : '';
      const coverId = coverSrc ? getMaterialId(coverSrc) : '';
      if (mediaId && mediaId !== '') ids[mediaId] = mediaId;
      if (coverId && coverId !== '') ids[coverId] = coverId;
    });
    client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: Object.keys(ids) } }).then((res) => {
      if (!res.data.Data) {
        message.error(res.data.Message, 1000);
      } else {
        res.data.Data.MaterialInfoSet.forEach((material) => {
          switch (material.BasicInfo.MaterialType) {
            case 'IMAGE':
              ids[material.BasicInfo.MaterialId] = material.ImageMaterial.MaterialUrl;
              break;
            case 'AUDIO':
              ids[material.BasicInfo.MaterialId] = material.AudioMaterial.MaterialUrl;
              break;
            case 'VIDEO':
              ids[material.BasicInfo.MaterialId] = material.VideoMaterial.MaterialUrl;
              // ids[`${material.BasicInfo.MaterialId}_COVER`] = material.VideoMaterial.CoverUrl; // 不管有没有上传封面，先拿了再说
              break;
            default:
              break;
          }
        });
        setMaterialUrls(Object.assign({}, ids));
      }
    });
  }

  // 左侧菜单
  function handleEditType(key) {
    setEditType(key);
    switch (key) {
      case 'init':
        setShowTags([]);
        setShowMosaics([]);
        break;
      case 'tag':
        initTagsData(MODEL_DECORATION_INFO.tags || []);
        break;
      case 'mosaic':
        initMosaicsData(MODEL_DECORATION_INFO.mosaics || []);
        break;
      case 'scenes':
        initTagsData(MODEL_DECORATION_INFO.tags || []);
        break;
      case 'style':
        setShowTags([]);
        setShowMosaics([]);
        break;
      default:
        break;
    }
    resetEditMode();
    refreshCanvas();
  }

  // 初始化标签数据
  function initTagsData(tagsData) {
    setShowMosaics([]);
    if (tagsData.length <= 0) return;
    computePosition('tag', MODEL_DECORATION_INFO.tags);
  }

  // 初始化马赛克数据
  function initMosaicsData(mosaicsData) {
    setShowTags([]);
    if (mosaicsData.length <= 0) return;
    computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
  }

  // 点位加工Z画圈圈
  function panoCircleNumber() {
    const panoNumberGeometry = new THREE.CircleGeometry(0.22, 32);
    if (sdkType === 'lab') return;
    if (panoNumberMeshArr.length === 0) {
      // 初始化之后维护一份点位的 mesh，不要每次都重新 imsTour.scene.add
      for (let i = 0; i < vTourData.observers.length; i++) {
        // 点位序号
        const panoNumberMaterial = new THREE.MeshBasicMaterial({
          map: createTextTexture({
            text: vTourData.observers[i].index + 1,
          }),
          // depthTest: false,
        });
        const panoNumberMesh = new THREE.Mesh(panoNumberGeometry, panoNumberMaterial);
        const position = new THREE.Vector3().fromArray(vTourData.observers[i].standing_position);
        panoNumberMesh.position.copy(position);
        panoNumberMesh.renderOrder = 1;
        // panoNumberMesh.position.y -= 1.28; standing_position 才是地面的位置 position 是相机位置
        panoNumberMesh.rotateX(-Math.PI / 2);
        panoNumberMeshArr.push(panoNumberMesh);
      }
    }
    // 只需循环维护的一份点位的 mesh
    for (let i = 0; i < panoNumberMeshArr.length; i++) {
      const raycaster = new THREE.Raycaster();
      raycaster.ray.origin = vTour.camera.position;
      raycaster.ray.direction = panoNumberMeshArr[i].position.clone().sub(vTour.camera.position)
        .normalize();
      const distance = panoNumberMeshArr[i].position.distanceTo(vTour.camera.position);
      const intersects = vTour.model.intersectRaycaster(raycaster);
      if (intersects.length === 0 || intersects[0].distance - distance < -0.01 || distance > 10) {
        vTour.scene.remove(panoNumberMeshArr[i]);
        // 下面两个防止内存泄漏
        // panoNumberMeshArr[i].geometry·dispose();
        // panoNumberMeshArr[i].material·dispose();
        continue;
      }
      const coords = panoNumberMeshArr[i].position.clone().project(vTour.camera);
      if (Math.abs(coords.x) > 1 || Math.abs(coords.y) > 1 || Math.abs(coords.z) > 1) {
        vTour.scene.remove(panoNumberMeshArr[i]);
        // panoNumberMeshArr[i].geometry·dispose();
        // panoNumberMeshArr[i].material·dispose();
        continue;
      }
      vTour.scene.add(panoNumberMeshArr[i]);
    }
    refreshCanvas();
  }

  // 为了给一个干净的截屏
  function clearPanoCircleNumber() {
    for (let i = 0; i < panoNumberMeshArr.length; i++) {
      vTour.scene && vTour.scene.remove(panoNumberMeshArr[i]);
    }
    refreshCanvas();
  }

  function getVTourCameraPos() {
    if (sdkType === 'lab') {
      if (labSdkTypeRef.current === 'lab-aerial') {
        return vTour.camera.position;
      }
      return vTour.activeCamera.position;
    }
    return vTour.camera.position;
  }

  // 计算 tag 或 mosaic 位置
  function computePosition(type, objects) {
    const showObjectsTmp = [];
    const raycaster = new THREE.Raycaster();
    let coords;
    try {
      for (let i = 0; i < objects.length; i++) {
        const object = Object.assign({},  objects[i]); // 保证不污染 objects 里面的每一个对象
        const { x, y, z } = object.position;
        const position = object.position.clone ? object.position : new THREE.Vector3(x, y, z);

        raycaster.ray.origin = getVTourCameraPos();
        raycaster.ray.direction = sdkType === 'lab' ? position.clone().sub(raycaster.ray.origin) : position.clone().sub(vTour.camera.position)
          .normalize();

        if (sdkType === 'lab') {
          coords = vTour.computeScreenCoord(position, { maxDistance: 10 });
          if (!coords) continue;
        } else {
          const distance = position.distanceTo(getVTourCameraPos());
          // if (distance > 4) continue; // 太远的不展示
          const intersects = sdkType === 'lab' ? raycaster.intersectObject(vTour.model, true) : vTour.model.intersectRaycaster(raycaster);
          // 判断被墙面等模型挡住的
          if (intersects.length === 0 || intersects[sdkType === 'lab' ? intersects.length - 1 : 0].distance - distance < -0.01 || distance > 10) {
            continue;
          }
          coords = position.clone().project(sdkType === 'lab' ? vTour.activeCamera : vTour.camera);
          // 判断跑出当前视野区域的
          if (Math.abs(coords.x) > 1 || Math.abs(coords.y) > 1 || Math.abs(coords.z) > 1) {
            continue;
          }
        }
        object.left = `${((coords.x + 1) / 2) * 100}%`;
        object.top = `${((-coords.y + 1) / 2) * 100}%`;
        if (type === 'mosaic') {
          showObjectsTmp.push(object);
        } else {
          if ((editTypeRef.current === 'tag' && object.mediaData.type !== 'scenes') || (editTypeRef.current === 'scenes' && object.mediaData.type === 'scenes')) showObjectsTmp.push(object);
        }
      }
    } catch (e) {
      console.log('==========', e);
    }
    (type === 'tag' || type === 'scenes') ? setShowTags(showObjectsTmp) : setShowMosaics(showObjectsTmp);
  }

  // ==================标签 start======================
  // 打标签
  function changeToAddTag(type) {
    if (sdkType === 'lab') vTour.enablePanoMove = false;
    setTagModeType(type);
  }

  function updateEditTagPosition(offsetX, offsetY) {
    const vectorPosition = vTour.computeScenePos({ x: offsetX, y: offsetY });
    if (!vectorPosition) {
      message.error('当前位置无法添加标签，请移动 VR 场景视角后重新尝试', 2000);
      return;
    }
    const { x, y, z } = vectorPosition;
    const currentEditTagIndex = MODEL_DECORATION_INFO.tags.length - 1;
    const currentEditTag = MODEL_DECORATION_INFO.tags[currentEditTagIndex];
    const vrPoint = {
      position: [x, y, z],
      lonLat: vTour.getLongitudeAndLatitude(),
      panoIndex: labSdkTypeRef.current === 'lab-aerial' ? 0 : parseInt(vTour.currentPano.index, 10),
    };
    addOrUpdateTag(currentEditTagIndex, currentEditTag.mediaData, vrPoint);
  }

  // 往 vr 里面添加标签
  function addOrUpdateTag(index, mediaData, vrPoint, callback) {
    let tagUpdatingInfo = {};
    changeToAddTag(mediaData.type);
    if (sdkType === 'lab') {
      const vectorPosition = vTour.computeScenePos({ x: 0, y: 0 }); // 新标签默认在中点
      const vectorLonLat = vTour.getLongitudeAndLatitude();
      if (!vectorPosition) {
        message.error('当前位置无法添加标签，请移动 VR 场景视角后重新尝试', 2000);
        return;
      }
      callback && callback();

      const { x, y, z } = vectorPosition;
      tagUpdatingInfo = {
        position: {
          x: vrPoint?.position[0] || x,
          y: vrPoint?.position[1] || y,
          z: vrPoint?.position[2] || z,
        },
        longitude: vrPoint?.lonLat?.[0] || vectorLonLat[0] || 0,
        latitude: vrPoint?.lonLat?.[1] || vectorLonLat[1] || -7.016709298534876e-15,
        panoIndex: labSdkTypeRef.current === 'lab-aerial' ? 0 : (vrPoint?.panoIndex || parseInt(vTour.currentPano.index, 10) || 0),
        isTemp: true, // 临时标签
      };
    }
    if (sdkType === 'realsee' && vrPoint) {
      tagUpdatingInfo = {
        position: {
          x: vrPoint?.position[0],
          y: vrPoint?.position[1],
          z: vrPoint?.position[2],
        },
        longitude: vrPoint?.lonLat?.[0],
        latitude: vrPoint?.lonLat?.[1],
        panoIndex: vrPoint?.panoIndex,
        isTemp: true, // 临时标签
      };
    }
    const newTag = Object.assign(tagUpdatingInfo, {
      mediaData,
    });
    if (index === -1) {
      // 新增
      MODEL_DECORATION_INFO.tags.push(newTag);
    } else {
      // 更新
      MODEL_DECORATION_INFO.tags.splice(index, 1, newTag);
    }
    if (newTag.position) computePosition('tag', MODEL_DECORATION_INFO.tags);
  }

  function setEditingTag(tag) {
    setCurrentEditingTag(tag);
  }

  // 切换其他编辑模式需要取消原先状态
  function resetEditMode() {
    setTagModeType('');
    if (sdkType === 'lab') vTour.enablePanoMove = true;
    setMosaicMode(false);
    vMosaicModeFromList = false;
    hasMosaicedon = false;
    setMosaicMode(false);
  }

  function handleDeleteTag(tag) {
    currentDeleteTag = tag;
    setShowDeleteModal(true);
  }

  // 删除标签 fromList: 新建的标签面板取消会触发 deleteTag，已存在的标签取消时不应该删除
  function deleteTag(sourceIndex, fromList) {
    const tag = MODEL_DECORATION_INFO.tags.find(tag => tag.mediaData.sourceIndex === sourceIndex);
    if (!tag.isTemp && !fromList) return;
    setTagModeType('');
    if (sdkType === 'lab') vTour.enablePanoMove = true;
    let targetTagIndex = -1;
    const updateTags = MODEL_DECORATION_INFO.tags.filter((tag, index) => {
      if (tag.mediaData.sourceIndex === sourceIndex) {
        targetTagIndex = index;
      }
      return tag.mediaData.sourceIndex !== sourceIndex;
    });
    MODEL_DECORATION_INFO.tags = [].concat(updateTags);
    if (!tagsList[targetTagIndex]) { // 只是删除在前端刚打上的标签
      computePosition('tag', MODEL_DECORATION_INFO.tags);
    } else { // 删除数据库已有的标签
      DECORATION_INFO[materialIdRef.current] = MODEL_DECORATION_INFO;
      client('PaaS/Project/ModifyProject', {
        body: {
          ProjectId: PROJECT_INFO.projectId,
          ImmersiveViewingInfo: {
            DecorationInfo: JSON.stringify(DECORATION_INFO),
          } } }).then((res) => {
        if (res.data.Code === 'Success') {
          setShowDeleteModal(false); // 关闭确认弹窗
          getVTourData();
          message.success(`已删除该${modalTitleMap[editType]}`, 1000);
        } else {
          message.error('删除失败，请刷新重试', 1000);
        }
      });
    }
  }

  // 更新标签
  function updateCurrentTag(currentEditMedia, callback) {
    const updateTags = [].concat(MODEL_DECORATION_INFO.tags);
    updateTags.forEach((tag, index) => {
      if (currentEditMedia.sourceIndex === tag.mediaData.sourceIndex) {
        updateTags[index].mediaData = Object.assign({}, currentEditMedia);
      }
      tag.isTemp = false;
    });
    MODEL_DECORATION_INFO.tags = [].concat(updateTags);
    DECORATION_INFO[materialIdRef.current] = MODEL_DECORATION_INFO;
    client('PaaS/Project/ModifyProject', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        ImmersiveViewingInfo: {
          DecorationInfo: JSON.stringify(DECORATION_INFO),
        } } }).then((res) => {
      if (res.data.Code === 'Success') {
        setTagModeType('');
        if (sdkType === 'lab') vTour.enablePanoMove = true;
        callback();
        message.success('更新成功', 1000);
        getVTourData();
      } else {
        message.error('更新失败，请刷新重试', 1000);
      }
    });
  }
  // ==================标签 end======================

  // ==================马赛克 start======================
  // 打马赛克
  function changeToAddMosaic() {
    setMosaicMode(true);
    if (sdkType === 'lab') vTour.enablePanoMove = false;
  }

  // 更新马赛克大小
  function updateCurrentMosaicSize(number) {
    const mosaicsTmp = [].concat(MODEL_DECORATION_INFO.mosaics); // 注意不能直接赋值，引用赋值
    const currentEditMosaicArrayIndex = mosaicsTmp.findIndex(mosaic => mosaic.sourceIndex === currentEditMosaicIndex);
    mosaicsTmp[currentEditMosaicArrayIndex].width = MOSAIC_INIT_SIZE * (number / 100 + 1);
    mosaicsTmp[currentEditMosaicArrayIndex].height = MOSAIC_INIT_SIZE * (number / 100 + 1);
    MODEL_DECORATION_INFO.mosaics = mosaicsTmp;
    setMosaicSize(number);
    computePosition('mosaic', mosaicsTmp);
  }

  // 更新马赛克
  function updateMosaic() {
    DECORATION_INFO[materialIdRef.current] = MODEL_DECORATION_INFO;
    client('PaaS/Project/ModifyProject', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        ImmersiveViewingInfo: {
          DecorationInfo: JSON.stringify(DECORATION_INFO),
        } } }).then((res) => {
      if (res.data.Code === 'Success') {
        vMosaicModeFromList = false;
        hasMosaicedon = false;
        setMosaicMode(false);
        currentEditMosaicIndex = -1;
      } else {
        message.error('更新失败，请刷新重试', 1000);
      }
    });
  }

  // 准备删除马赛克
  function wantsToDeleteMosaic() {
    if (hasMosaicedon) { // 前端刚打上的马赛克
      vMosaicModeFromList = false;
      hasMosaicedon = false;
      setMosaicMode(false);
      currentEditMosaicIndex = -1;
      MODEL_DECORATION_INFO.mosaics.splice(-1, 1); // 一定是最后一个
      computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
      setMosaicsList([].concat(MODEL_DECORATION_INFO.mosaics));
    } else {
      setShowDeleteModal(true);
    }
  }

  // 删除马赛克
  function deleteMosaic() {
    MODEL_DECORATION_INFO.mosaics = [].concat(MODEL_DECORATION_INFO.mosaics.filter(mosaic => (
      mosaic.sourceIndex !== currentEditMosaicIndex)));
    DECORATION_INFO[materialIdRef.current] = MODEL_DECORATION_INFO;
    client('PaaS/Project/ModifyProject', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        ImmersiveViewingInfo: {
          DecorationInfo: JSON.stringify(DECORATION_INFO),
        } } }).then((res) => {
      if (res.data.Code === 'Success') {
        vMosaicModeFromList = false;
        setMosaicMode(false);
        setShowDeleteModal(false);
        currentEditMosaicIndex = -1;
        setMosaicsList([].concat(MODEL_DECORATION_INFO.mosaics));
        computePosition('mosaic', MODEL_DECORATION_INFO.mosaics);
      } else {
        message.error('删除失败，请刷新重试', 1000);
      }
    });
  }

  // 跳转至目标马赛克
  function foucusToMosaic(mosaic) {
    if (sdkType === 'lab') {
      if (labSdkTypeRef.current === 'lab-aerial' || mosaic.panoIndex === parseInt(vTour.currentPano.index, 10)) {
        // 目标马赛克在当前点位，就不需要移动点位了
        vTour.updateCamera({ lonLat: [mosaic.longitude, mosaic.latitude] });
      } else {
        vTour.moveTo(String(mosaic.panoIndex), { lonLat: [mosaic.longitude, mosaic.latitude] });
      }
    } else {
      vTour.updateWithStates({
        mode: 'Panorama',
        panoIndex: mosaic.panoIndex,
        longitude: mosaic.longitude,
        latitude: mosaic.latitude,
        fov: 100,
      });
    }
    vMosaicModeFromList = true;
    setMosaicMode(true);
    setMosaicSize(((mosaic.height - MOSAIC_INIT_SIZE)
    / MOSAIC_INIT_SIZE)
    * 100);
    currentEditMosaicIndex = -1;
    setTimeout(() => {
      currentEditMosaicIndex = mosaic.sourceIndex;
    }, 200);
  }
  // ==================马赛克 end======================

  // ==================初始信息 start======================
  // 设置初始信息
  function setInitInfo(type) {
    clearPanoCircleNumber();
    setInitType(type);
    // toDataURL png 太大，整体也需要处理压缩
    setTimeout(() => {
      if (sdkType === 'lab') {
        const newBase64 = vTour.takeScreenshot('image/jpeg');
        setViewShotSrcTmp(newBase64);
        setShowModal(true);
      } else {
        compressBase64Image(document.querySelector('canvas').toDataURL('image/jpeg'), 1.5, (newBase64) => {
          setViewShotSrcTmp(newBase64);
          setShowModal(true);
        });
      }
    }, 200);
  }

  // 上传封面图片
  async function uploadCover() {
    const file = await dataURLtoFile(viewShotSrcTmp, `cover-${(new Date()).getTime()}.jpeg`);
    const res = await uploadFile('IMAGE', { file }, owner);
    return res;
  }

  // 更新初始信息
  async function handleInitConfirm() {
    if (uploadCoverStatus) return;
    let uploadFeedbackUrl = '';
    setUploadCoverStatus(true);
    const res = await uploadCover();
    uploadFeedbackUrl = res.url;
    if (initType === 'point') {
      let lon = 0;
      let lat = 0;
      // 调用接口设置初始角
      if (sdkType === 'lab') {
        const [longitude, latitude] = vTour.getLongitudeAndLatitude();
        lon = longitude;
        lat = latitude;
      } else {
        const { longitude, latitude } = vTour.getLongitudeAndLatitude();
        lon = longitude;
        lat = latitude;
      }
      MODEL_DECORATION_INFO.initInfo = Object.assign({}, {
        panoIndex: currentPanoIndex - 1,
        fov: Math.round(vTour.getFov()),
        longitude: lon,
        latitude: lat,
      });
      MODEL_DECORATION_INFO.modelInitView = uploadFeedbackUrl; // res.materialId;
    } else {
      MODEL_DECORATION_INFO.modelCover = uploadFeedbackUrl; // res.materialId;
    }
    DECORATION_INFO[materialIdRef.current] = MODEL_DECORATION_INFO;
    client('PaaS/Project/ModifyProject', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        ImmersiveViewingInfo: {
          DecorationInfo: JSON.stringify(DECORATION_INFO),
        } } }).then((res) => {
      if (res.data.Code === 'Success') {
        panoCircleNumber();
        message.success('设置完成', 1000);
        initType === 'point' ? setviewSenseSrc(uploadFeedbackUrl) : setViewShotSrc(uploadFeedbackUrl);
        setUploadCoverStatus(false);
        setShowModal(false);
        if (initType !== 'point' && materialIdRef.current === materialIdsInProjectRef.current[0]) {
          // 如果更新的是封面而且是第一个
          modifyProjectCover(uploadFeedbackUrl);
        }
        if (initType === 'point') setInitPanoIndex(currentPanoIndex); // 更新成功，更新初始点位数据
      }
    });
  }
  // ==================初始信息 end======================

  // ==================场景关联 start====================

  function removeMaterialFromProject(id) {
    if (materialId === id) {
      return message.error('当前正在编辑的场景不能删除', 1000);
    }
    client('PaaS/Project/DeleteMaterialFromProject', { body: { ProjectId: PROJECT_INFO.projectId, MaterialIds: [id] } }).then((res) => {
      if (res.data.Data && res.data.Data.FailMaterialSet.length === 0) {
        // eslint-disable-next-line max-len
        const newMaterialsInProject = materialsInProjectRef.current.filter(item => item.BasicInfo.MaterialId !== id);
        setMaterialsInProject(newMaterialsInProject);
        setMaterialIdsInProject(materialIdsInProjectRef.current.filter(item => item !== id));
      }
    });
  }

  async function updateMaterialIds(materialIds) {
    const res = await describeMaterials(materialIdsInProjectRef.current.concat(materialIds));
    if (res.data.Data) {
      setMaterialsInProject(res.data.Data.MaterialInfoSet);
      setMaterialIdsInProject(materialIdsInProjectRef.current.concat(materialIds));
      if (materialsInProjectRef.current.length === 1) {
        setMaterialIdsInProject(materialIds);
        setMaterialId(materialIds[0]);
        MODEL_DECORATION_INFO = DECORATION_INFO[materialIds[0]] || {};
        els.root = document.getElementById('viewport-canvas');
        HAS_INITED = false; // 初始化模型
        getVTourData();
      }
    }
  }

  function describeMaterials(materialIds) {
    return client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: [...materialIds] } });
  }

  function modifyResourceOrderingView(materialIds) {
    const resources = materialIds.map(item => ({ Type: 'MATERIAL', Id: item }));
    client('PaaS/Material/ResourceLayoutView/ModifyResourceLayoutView', { body: { BelongId: PROJECT_INFO.projectId, BelongType: 'PROJECT', Resources: resources } }).then((res) => {
      if (res.data.Code === 'Success') {
        if (materialIdsInProjectRef.current[0] !== materialIds[0]) {
          // 项目内第一个场景更新了，需更新项目封面
          const newCover = materialsInProjectRef.current[0].decorationInfoSet.modelCover;
          if (newCover) modifyProjectCover(newCover);
        }
        setMaterialIdsInProject(materialIds);
      } else {
        message.error('调整场景顺序失败，请刷新后重试');
      }
    });
  }

  function modifyProjectCover(cover) {
    client('PaaS/Project/ModifyProject', { body: { ProjectId: PROJECT_INFO.projectId, CoverUrl: cover } });
  }

  // ==================场景关联 end======================

  // ==================样式设置 start======================
  function updateProjectDecoration(newDecoration) {
    client('PaaS/Project/ModifyProject', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        ImmersiveViewingInfo: {
          DecorationInfo: JSON.stringify(newDecoration),
        } } }).then((res) => {
      if (res.data.Code === 'Success') {
        message.success('更新成功', 1000);
      } else {
        message.error('更新失败，请刷新重试', 1000);
      }
    });
  }

  // ==================样式设置 end======================

  // function copyDemoProject() {
  //   return client('PaaS/Material/CopyMaterial', { body: {
  //     // Name: 'nameAfterCopy',
  //     ClassId: 9,
  //     SourceMaterialId: materialIdRef.current,
  //     Owner: {
  //       Type: 'PERSON',
  //       Id: user.TfUid,
  //     } } });
  // }

  function handleDeleteConfirm() {
    editTypeRef.current === 'tag' || editTypeRef.current === 'scenes' ? deleteTag(currentDeleteTag.mediaData.sourceIndex, true) : deleteMosaic();
  }

  function refreshCanvas() {
    const simulatorDom = document.getElementById('invision-mobile');
    let simulatorHeight = 0;
    let simulatorWidth = 0;
    if (simulatorDom) {
      simulatorHeight = simulatorDom.clientHeight;
      simulatorWidth = simulatorDom.clientWidth;
    }
    if (sdkType !== 'lab') {
      if (editTypeRef.current === 'style') {
        if (simulatorDom) {
          els.root.style.height = `${simulatorHeight}px`;
          els.root.style.width = `${simulatorWidth}px`;
          els.root.style.position = 'absolute';
          vTour.refresh({ height: simulatorHeight, width: simulatorWidth });
        }
      } else {
        els.root.style.height = `${document.body.clientHeight - 60 - 188}px`;
        els.root.style.width = `${document.body.clientWidth - 375}px`;
        vTour.refresh({ height: document.body.clientHeight - 60 - 188, width: document.body.clientWidth - 375 });
      }
    } else {
      if (editTypeRef.current === 'style') {
        if (simulatorDom) {
          els.root.style.height = `${simulatorHeight}px`;
          els.root.style.width = `${simulatorWidth}px`;
        }
      } else {
        els.root.style.height = `${document.body.clientHeight - 60 - 188}px`;
        els.root.style.width = `${document.body.clientWidth - 375}px`;
      }
      vTour?.resize?.();
    }
  }

  function selectMaterial(material) {
    const targetId = material.BasicInfo.MaterialId;
    if (modelIsLoading || targetId === materialId) return;
    setMaterialId(targetId);
    MODEL_DECORATION_INFO = DECORATION_INFO[targetId] || {};
    MODEL_DECORATION_INFO.style && setSimulatorStyles(MODEL_DECORATION_INFO.style); // 需重置新场景的 style
    HAS_INITED = false; // 重置模型
    setModelIsLoading(true);
    if (vTour?.dispose) {
      vTour.dispose();
    } else {
      els.root?.removeChild(els.root.lastElementChild);
    }
    setEditType('init');
    setShowTags([]);
    setShowMosaics([]);
    setViewShotSrc('');
    setviewSenseSrc('');
    if (!els.root) els.root = document.getElementById('viewport-canvas');
    getVTourData();
  }

  function updateSimulator(key, newStyle) {
    setSimulatorStyles(Object.assign({}, newStyle));
    setModifyKey(key);
  }

  function handleChangeMode(mode) {
    if (sdkType === 'lab') {
      if (labSdkTypeRef.current === 'lab-aerial') {
        vTour.changeMode(mode === 'pano' ? 'normal' : 'planet');
      } else {
        vTour.changeMode(mode);
      }
    } else {
      vTour.changeMode(mode === 'pano' ? ImsTour.Mode.Panorama : ImsTour.Mode.Topview);
    }
    setVrMode(mode);
  }

  useEffect(async () => {
    if (PROJECT_INFO.projectId) {
      const resProjects = await getProjects();
      const projectInfo = resProjects.data.Data.ProjectInfoSet[0];
      const immersiveViewingInfo = projectInfo.ImmersiveViewingInfo;
      let decoration = '';
      let materialIds = [];
      if (immersiveViewingInfo) { // 后端维护数据的方式，immersiveViewingInfo 不一定存在
        decoration = immersiveViewingInfo.DecorationInfo;
        materialIds = immersiveViewingInfo.DecorationMaterialIds; // 之前 DecorationInfo 里面的媒资都是链接，防盗链后改为 id，需要单独抽出来存
      }
      DECORATION_INFO = Object.assign({}, JSON.parse(decoration === '' ? '{}' : decoration));
      if (!materialIds.length) {
        const resMaterials = await getMaterialFromProject();
        materialIds = resMaterials.data.Data.MaterialIdSet;
      }
      if (materialIds.length) {
        setMaterialIdsInProject(materialIds);
        setMaterialId(materialIds[0]);
        MODEL_DECORATION_INFO = DECORATION_INFO[materialIds[0]] || {};
        MODEL_DECORATION_INFO.style && setSimulatorStyles(MODEL_DECORATION_INFO.style); // for 样式设置手机模拟器展示
        els.root = document.getElementById('viewport-canvas');
        HAS_INITED = false; // 初始化模型
        getVTourData();
      }
      // 如果显示区域需要变动，在变动时请调用 refresh 重置显示参数
      window.addEventListener('resize', debounce(500, refreshCanvas));
    }
    return (() => {
      window.removeEventListener('resize', debounce(500, refreshCanvas));
      panoNumberMeshArr.length = 0;
    });
  }, []);

  return (
    <section className="detail invision-container is-vertical theme-dark">
      <ModelTitle
        modelName={modelName}
        projectId={PROJECT_INFO.projectId}
        materialId={materialId} />
      <section className="invision-container model-details">
        <aside className="invision-aside invision-layout__sidebar" style={{ width: '80px' }}>
          {
            !modelIsLoading
              ? <div className="invision-menu invision-menu--vertical">
                <ul className="invision-menu__list">
                  {
                    siderMenu.filter((item) => {
                      if (labSdkTypeRef.current === 'lab-aerial' && item.key === 'init') {
                        return false;
                      }
                      return true;
                    }).map(item => (
                      <li key={`menu-${item.key}`} className={editType === item.key ? 'is-selected' : ''}>
                        <span className="invision-menu__item" onClick={() => {
                          handleEditType(item.key);
                        }}>
                          <i className={`invision-menu__item-icon invision-icon-${item.class}`}></i>
                        <span className="invision-menu__item-text">{item.text}</span>
                        </span>
                      </li>
                    ))
                  }
                </ul>
              </div>
              : null
          }
        </aside>
        <main className="invision-main model-details__main">
          <div className={`model-details__main-wrap ${editType === 'style' ? 'invision-mobile-simulator' : ''}`}>
            <div className="model-details__main-inner">
              {
                panoImgLoading
                  ? <div style={{ position: 'absolute', height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, .3)', zIndex: '1' }}>
                    <Loading />
                  </div>
                  : null
              }
              <div className="app-back">
                {
                  modelIsLoading
                    ? <ModelLoading platform="desktop" />
                    : null
                }
                <div id="viewport-canvas" className="viewport"></div>
                {
                  showTags.length > 0 && editType !== 'style' && vrMode !== 'mesh'
                    ? <Tags
                        sdkType={sdkType}
                        materialUrls={materialUrls}
                        inPreview={false}
                        showTags={showTags}
                        currentEditingTag={currentEditingTag}
                        updateEditTagPosition={updateEditTagPosition}
                      />
                    : null
                }
                {
                  showMosaics.length > 0 && editType !== 'style' && vrMode !== 'mesh'
                    ? <Mosaics
                    showMosaics={showMosaics}
                    currentEditMosaicIndex={currentEditMosaicIndex}
                    deleteMosaic={deleteMosaic}
                    wantsToDeleteMosaic={wantsToDeleteMosaic} />
                    : null
                }
              </div>
              {
                editType === 'style'
                  ? <StyleSimulator modifyKey={modifyKey} styles={simulatorStyles} refreshCanvas={refreshCanvas} />
                  : <>
                    <div className="invision-segment invision-segment--round model-details__segment">
                      <Button className={`invision-button size-sm ${vrMode === 'pano' ? 'is-selected' : ''}`} size="small" onClick={() => {
                        handleChangeMode('pano');
                      }}><img width={18} height={18} src={panoramicIcon} alt="" />全景视图</Button>
                      <Button className={`invision-button size-sm ${vrMode === 'mesh' ? 'is-selected' : ''}`} size="small" onClick={() => {
                        handleChangeMode('mesh');
                      }}><img width={18} height={18} src={modelIcon} alt="" />{labSdkType === 'lab-aerial' ? '星球视图' : '模型视图'}</Button>
                    </div>
                    <TagButtonGroup
                      editType={editType}
                      setInitInfo={setInitInfo}
                      mosaicMode={mosaicMode}
                      changeToAddMosaic={changeToAddMosaic}
                      updateMosaic={updateMosaic}
                    />
                    <div className="model-details__footer">
                      <span className="model-details__footer-item">当前景深：{ currentFov }</span>
                      {
                        labSdkType !== 'lab-aerial'
                          ? <span className="model-details__footer-item">当前点位：{ currentPanoIndex }/{ vTourData && vTourData?.observers?.length }</span>
                          : null
                      }
                    </div>
                  </>
              }
            </div>
          </div>
          <SceneList
            projectId={PROJECT_INFO.projectId}
            materialsInProject={materialsInProject}
            materialIdsInProject={materialIdsInProject}
            removeMaterialFromProject={removeMaterialFromProject}
            selectMaterial={selectMaterial}
            updateMaterialIds={updateMaterialIds}
            modifyResourceOrderingView={modifyResourceOrderingView} />
        </main>
        {
          editType === 'style'
            ? <StyleAside
              owner={owner}
              decorationInfo={DECORATION_INFO}
              materialId={materialId}
              materialIdsInProject={materialIdsInProject}
              materialsInProject={materialsInProject}
              updateSimulator={updateSimulator}
              updateProjectDecoration={updateProjectDecoration} />
            : <HelperAside
              modelIsLoading={modelIsLoading}
              sdkType={sdkType}
              vTour={vTour}
              vTourData={vTourData}
              materialId={materialId}
              materialsInProject={materialsInProject}
              materialUrls={materialUrls}
              editType={editType}
              owner={owner}
              initPanoIndex={initPanoIndex}
              viewSenseSrc={viewSenseSrc}
              viewShotSrc={viewShotSrc}
              tagsList={tagsList}
              mosaicSize={mosaicSize}
              mosaicMode={mosaicMode}
              mosaicsList={mosaicsList}
              currentEditMosaicIndex={currentEditMosaicIndex}
              updateCurrentMosaicSize={updateCurrentMosaicSize}
              foucusToMosaic={foucusToMosaic}
              handleDeleteTag={handleDeleteTag}
              setPanoImgLoading={setPanoImgLoading}
              changeToAddTag={changeToAddTag}
              addOrUpdateTag={addOrUpdateTag}
              deleteTag={deleteTag}
              updateCurrentTag={updateCurrentTag}
              setEditingTag={setEditingTag}
            />
        }
      </section>
      <Modal
        className="invision-dialog invision-media-preview setting-position size-md"
        title={`设置${initType === 'point' ? '初始角' : '封面'}`}
        destroy
        visible={showModal}
        onClose={() => {
          setShowModal(false);
          panoCircleNumber();
        }}
        footer={
          <div className="invision-dialog__button-wrap">
            <Button
              className="invision-button invision-button--primary invision-button--round size-sm"
              theme="primary"
              size="small"
              onClick={handleInitConfirm}
            >
              {uploadCoverStatus ? '上传中...' : '确定'}
            </Button>
            <Button
              className="invision-button invision-button--secondary invision-button--round size-sm"
              theme="default"
              size="small"
              onClick={() => {
                setShowModal(false);
                panoCircleNumber();
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <div className="invision-dialog__body">
          <p>{initType === 'point' ? '初始角' : '封面'}预览图</p>
          <div className="setting-position__image-wrap">
            <div className="setting-position__image" style={{ backgroundImage: `url(${viewShotSrcTmp})`,
              backgroundSize: 'cover' }} alt="预览图" ></div>
          </div>
          <h4 className="invision-dialog__messagetitle">设置当前预览图片为{initType === 'point' ? '初始角' : '封面'}？</h4>
        </div>
      </Modal>
      <Modal
        title={`删除${modalTitleMap[editType]}`}
        className="ten-modal invision-dialog invision-media-preview size-md"
        destroy
        width={400}
        visible={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        footer={
          <div className="invision-dialog__button-wrap">
            <Button
              className="invision-button invision-button--primary invision-button--round size-sm"
              theme="primary"
              size="small"
              onClick={handleDeleteConfirm}
            >
              确定
            </Button>
            <Button
              className="invision-button invision-button--secondary invision-button--round size-sm"
              theme="default"
              size="small"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <p>确认删除该{modalTitleMap[editType]}吗？</p>
      </Modal>
    </section>
  );
}

export default Detail;
