export default function formatJson(data) {
  if (data.ModelInfo.TextureList) {
    const panoramaList = data.PanoramaInfo.CubeList;
    const observerList = data.ObserverList;
    const textureList = data.ModelInfo.TextureList;
    const initObj = {
      model: {
        file_url: data.ModelInfo.Path,
        material_textures: [],
      },
      panorama: {
        list: [],
      },
      observers: [],
    };
    textureList.forEach((item) => {
      const textureUrl = data.ModelInfo.TextureBase + item;
      initObj.model.material_textures.push(textureUrl);
    });
    panoramaList.forEach((item) => {
      const tmpItem = {
        index: item.Index,
        up: data.PanoramaInfo.CubeBase + item.Up,
        down: data.PanoramaInfo.CubeBase + item.Down,
        right: data.PanoramaInfo.CubeBase + item.Right,
        left: data.PanoramaInfo.CubeBase + item.Left,
        front: data.PanoramaInfo.CubeBase + item.Front,
        back: data.PanoramaInfo.CubeBase + item.Back,
      };
      initObj.panorama.list.push(tmpItem);
    });
    observerList.forEach((item) => {
      const tmpItem = {
        visible_nodes: item.VisibleNodeList,
        accessible_nodes: item.AccessibleNodeList,
        index: item.Index,
        quaternion: {
          y: item.Quaternion.Y,
          x: item.Quaternion.X,
          z: item.Quaternion.Z,
          w: item.Quaternion.W,
        },
        standing_position: item.StandingPosition,
        position: item.Position,
        floor_index: item.FloorIndex,
      };
      initObj.observers.push(tmpItem);
    });
    return initObj;
  }
  // 处理贝壳结构光拍摄
  const panoramaList = data.PanoramaInfo.CubeList;
  const observerList = data.ObserverList;
  const initObj = {
    model: {
      file_url: data.ModelInfo.Url,
      material_textures: data.ModelInfo.TextureUrlList,
    },
    panorama: {
      list: [],
    },
    observers: [],
  };
  panoramaList.map((item) => {
    const tmpItem = {
      index: item.Index,
      up: item.UpUrl,
      down: item.DownUrl,
      right: item.RightUrl,
      left: item.LeftUrl,
      front: item.FrontUrl,
      back: item.BackUrl,
    };
    initObj.panorama.list.push(tmpItem);
  });
  observerList.map((item) => {
    const tmpItem = {
      visible_nodes: item.VisibleNodeList,
      accessible_nodes: item.AccessibleNodeList,
      index: item.Index,
      quaternion: {
        y: item.Quaternion.Y,
        x: item.Quaternion.X,
        z: item.Quaternion.Z,
        w: item.Quaternion.W,
      },
      standing_position: item.StandingPosition,
      position: item.Position,
      floor_index: item.FloorIndex,
    };
    initObj.observers.push(tmpItem);
  });
  return initObj;
}
