/*
 * @Descripttion:
 * @version: 0.0.1
 * @Author: maplexiao
 * @Date: 2020-11-20 16:13:09
 * @LastEditors: maplexiao
 * @LastEditTime: 2020-12-01 14:42:33
 */
import React from 'react';
import { useAuth } from './authProvider';

const UserContext = React.createContext();

function UserProvider(props) {
  const {
    data: { user },
  } = useAuth();
  return <UserContext.Provider value={user} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
