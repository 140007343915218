import React, { useState, useEffect } from 'react';
import * as useStateRef from 'react-usestateref';
import md5 from 'crypto-js/md5';
import PropTypes from 'prop-types';
import ModelLoading from '../../../components/modelLoading/index';
import { Modal, Button, message } from '@tencent/ten-design-react';
import ImsTour from '../../../lib/imsTour/imsTour';
import { Player } from '@tencent/vtour';
import { CubemapViewer } from '@tencent/vviewer';
import client from '../../../utils/apiClient';
import formatJson from '../../../utils/formatJson';
import { parseImage, vTourDataMaker } from '../../../utils/tools';
import convertorData from '../../../utils/convertorData';

const axios = require('axios').default;

const els = {};
const IV = 'mt11G34f816eHe1c'; // 加解密模型向量
let MODEL_KEY = ''; // 加解密模型
let PROJECT_UUID = ''; // 加解密模型
let vTour;
let vTourData;
let sdkType = '';

export const ViewModel = (props) => {
  const [modelIsLoading, setModelIsLoading] = useState(true); // 模型加载中
  const [vrMode, setVrMode] = useState('pano');
  const [labSdkType, setLabSdkType, labSdkTypeRef] = useStateRef(''); // 区分实验室本身的 sdk
  // 贝壳 SDK 初始化
  function initImsTour() {
    vTour = new ImsTour({
      mode: ImsTour.Mode.Panorama,
      initWithTransition: false,
      // 这个比需开启才能使用 canvas 的 toDataUrl 进行截图
      preserveDrawingBuffer: true,
      imageOptions: {
        size: 2000,
        quality: 100,
        format: 'jpg',
        transform: parseImage,
      },
      // 模型贴图参数
      textureOptions: {
        size: 1024,
        quality: 100,
        format: 'jpg',
        transform: parseImage,
      },
      onlyRenderIfNeeds: true,
    });
    vTour.appendTo(els.root);
    // 模型加载完成
    vTour.on('modelLoaded', () => {
      setModelIsLoading(false);
    });
  }

  // 实验室 SDK 初始化
  async function initVTour(data, modelVsersion) {
    const instanceData = convertorData(data, null, labSdkTypeRef.current);
    if (modelVsersion >= 1.3) {
      const newIv = md5((IV + PROJECT_UUID).split('').reverse()
        .join(''))
        .toString()
        .substring(0, 16);
      instanceData.data.kiv = [MODEL_KEY, newIv];
    }
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour = new CubemapViewer({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          ctlDampingFactor: 0.15,
        }),
      });
    } else {
      vTour = new Player({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          hotspotEnabled: true,
          compassEnabled: true,
          cursorEnabled: true,
          compassURL: 'https://cvr.tencentcs.com/invision-lab/compass.png',
          cursorURL: 'https://cvr.tencentcs.com/invision-lab/hotspot_alpha.jpg',
          hotspotURL: 'https://cvr.tencentcs.com/invision-lab/hotspot_alpha.jpg',
        }),
      });
    }
    await vTour.start();
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour.updateView({
        position: [0, 0.4, 0],
        lonLat: [0, 0],
        duration: 0,
        progress: 1,
      });
      setTimeout(() => {
        vTour.updateView({
          position: [0, 0, 0],
          lonLat: [0, 0],
          duration: 1500,
        });
      }, 2000);
    }
    setModelIsLoading(false);
  }

  // 获取模型原始数据 & 标签数据
  function getVTourData() {
    client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: [props.material.BasicInfo.MaterialId] } }).then((res) => {
      if (!res.data.Data) {
        message.success(res.data.Message, 1000);
      } else {
        const data = res.data.Data.MaterialInfoSet[0];
        processVTourData(data);
      }
    });
  }

  // 处理模型数据
  function processVTourData(data) {
    MODEL_KEY = data.Immersive3DModelMaterial.ModelKey || '';
    if (data.Immersive3DModelMaterial.IndexUrl !== '') {
      sdkType = 'lab';
      getModelData(data.Immersive3DModelMaterial.IndexUrl);
    } else {
      initImsTour();
      const modelData = JSON.parse(data.Immersive3DModelMaterial.Index);
      vTourData = formatJson(modelData.ModelDetail);
      vTour.load(vTourData);
    }
  }

  // 实验室 SDK 需额外获取全景数据
  function getModelData(url) {
    axios({
      method: 'GET',
      url,
      responseType: 'json',
    }).then((res) => {
      if (res.data.MaterialType === 'XRLabAerial') {
        // 星球模型
        vTourData = res.data.ModelDetail;
        setLabSdkType('lab-aerial');
      } else {
        vTourData = vTourDataMaker(res.data.ModelDetail);
        setLabSdkType('');
      }
      PROJECT_UUID = vTourData.projectID;
      initVTour(vTourData, res.data.ModelVersion);
    });
  }

  function handleChangeMode(mode) {
    if (sdkType === 'lab') {
      if (labSdkTypeRef.current === 'lab-aerial') {
        vTour.changeMode(mode === 'pano' ? 'normal' : 'planet');
      } else {
        vTour.changeMode(mode);
      }
    } else {
      vTour.changeMode(mode === 'pano' ? ImsTour.Mode.Panorama : ImsTour.Mode.Topview);
    }
    setVrMode(mode);
  }

  useEffect(() => {
    if (props.material?.BasicInfo.MaterialType === 'IMMERSIVE_3D_MODEL' && props.showViewModel) {
      els.root = document.getElementById('viewport-canvas');
      if (vTour) {
        if (vTour.dispose) {
          vTour.dispose();
        }
        if (els.root && els.root.lastElementChild) els.root.removeChild(els.root.lastElementChild);
      }
      if (props.material) {
        getVTourData();
      }
    }
  }, [props.showViewModel]);

  return (
    <Modal
      className="invision-dialog invision-media-preview preview-model"
      title=""
      footer=""
      width={1266}
      visible={props.showViewModel}
      onClose={() => {
        setModelIsLoading(true);
        props.closeViewModel();
      }}
    >
      <div className="invision-dialog__body">
        <div className="invision-media-preview__viewer view-model__wrap">
          {
            props.material?.BasicInfo.MaterialType === 'IMMERSIVE_3D_MODEL'
              ? <div
                className="invision-media-preview__viewer-wrap"
              >
                {
                  modelIsLoading
                    ? <ModelLoading platform="desktop" />
                    : null
                }
                <div id="viewport-canvas" className="viewport"></div>
              </div>
              : <div
                className="invision-media-preview__viewer-wrap"
              >
                <div className="viewport" style={{ display: 'flex', alignItems: 'center' }}><img src={props.material?.BasicInfo.MediaUrl} style={{ width: '100%' }} /></div>
              </div>
          }
          {
            props.material?.BasicInfo.MaterialType === 'IMMERSIVE_3D_MODEL'
              ? <div className="invision-segment invision-segment--round model-details__segment preview-details__segment">
                <Button className={`invision-button size-sm ${vrMode === 'pano' ? 'is-selected' : ''}`} size="small" onClick={() => {
                  handleChangeMode('pano');
                }}>全景视图</Button>
                <Button className={`invision-button size-sm ${vrMode === 'mesh' ? 'is-selected' : ''}`} size="small" onClick={() => {
                  handleChangeMode('mesh');
                }}>{labSdkType === 'lab-aerial' ? '星球视图' : '模型视图'}</Button>
              </div>
              : null
          }
          <div className="invision-media-view__name">{ props.material && props.material.BasicInfo.Name }</div>
        </div>
      </div>
    </Modal>
  );
};

ViewModel.propTypes = {
  showViewModel: PropTypes.bool,
  material: PropTypes.object,
  closeViewModel: PropTypes.func,
};
