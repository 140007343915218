import ImsTour, {
  THREE,
} from '../lib/imsTour/imsTour';

export function waterMask(imsTour) {
  // === 水印 ===
  const textureTop = new THREE.TextureLoader().load('https://cvr.tencentcs.com/vr-common/platfond-tencent.png?imageMogr2/quality/70/thumbnail/512x');
  const textureBottom = new THREE.TextureLoader().load('https://cvr.tencentcs.com/vr-common/north-point-circle.svg?t=20200115');
  const waterMaskGeometry = new THREE.CircleBufferGeometry(0.5, 32);
  const waterMaskMaterialTop = new THREE.MeshBasicMaterial({
    // map: 你可以替换成 map 贴图
    // color: 0x00a4ff,
    map: textureTop,
    depthTest: false,
    transparent: true,
    opacity: 0.5,
  });
  const waterMaskMaterialBottom = new THREE.MeshBasicMaterial({
    // map: 你可以替换成 map 贴图
    // color: 0x00a4ff,
    map: textureBottom,
    depthTest: false,
    transparent: true,
    opacity: 0.5,
  });
  const topWaterMashMesh = new THREE.Mesh(waterMaskGeometry, waterMaskMaterialTop);
  const bottomWaterMashMesh = new THREE.Mesh(waterMaskGeometry, waterMaskMaterialBottom);
  topWaterMashMesh.rotateX(Math.PI / 2);
  bottomWaterMashMesh.rotateX(-Math.PI / 2);
  bottomWaterMashMesh.renderOrder = 1;
  topWaterMashMesh.renderOrder = 1;

  imsTour.scene.add(topWaterMashMesh);
  imsTour.scene.add(bottomWaterMashMesh);

  // 切换全景点动画结束
  imsTour.on('panoArrived', (panoIndex) => {
    const standingPosition = new THREE.Vector3().fromArray(imsTour.options.observers[panoIndex].standing_position);
    topWaterMashMesh.visible = true;
    bottomWaterMashMesh.visible = true;
    // 你也可以写个动画，修改 opacity 让水印出现得自然一些
    topWaterMashMesh.position.copy(standingPosition);
    topWaterMashMesh.position.y += 2.9;
    bottomWaterMashMesh.position.copy(standingPosition);
    bottomWaterMashMesh.position.y += 0.5;
  });

  // 观看模式切换
  imsTour.on('modeChange', (mode) => {
    topWaterMashMesh.visible = mode === ImsTour.Mode.Panorama;
    bottomWaterMashMesh.visible = mode === ImsTour.Mode.Panorama;
  });
}
