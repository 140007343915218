import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PickMaterial } from '../../model/subComponent/pickMaterial';
import sceneModelIcon from '../../../assets/images/svg/icon-model.svg';
import iconClose from '../../../assets/images/svg/icon-close.svg';

import defaultCover from '../../../assets/images/screen-shot-default.png';
import { imageProcess } from '../../../utils/tools';

export const SceneList = (props) => {
  const [sceneInfoSet, setSceneInfoSet] = useState([]);
  const [pickMaterialVisible, setPickMaterialVisible] = useState(false);
  const [candidateList, setCandidateList] = useState([]);

  function onDragEnd(e) {
    const oldIds = props.materialIdsInProject.concat();
    const targetItem = oldIds.splice(e.source.index, 1);
    oldIds.splice(e.destination.index, 0, ...targetItem);
    const newSceneInfoSet = oldIds.map(item => sceneInfoSet.find(scene => scene.BasicInfo.MaterialId === item));
    setSceneInfoSet(newSceneInfoSet);
    props.modifyResourceOrderingView(oldIds);
  }

  async function reflashProjectList(materialIds) {
    props.updateMaterialIds(materialIds);
  }

  useEffect(async () => {
    if (props.materialsInProject?.length) {
      const candidatePickedList = [];
      const materialsWithDecoration = props.materialsInProject.map((item) => {
        candidatePickedList.push({
          ...item,
          isChecked: true,
          type: 'model',
        });
        const decoration = item.Immersive3DModelMaterial.Decoration;
        const decorationInfoSet = Object.assign({}, JSON.parse(decoration === '' ? '{}' : decoration));
        return Object.assign(item, { decorationInfoSet });
      });
      setSceneInfoSet(materialsWithDecoration);
      setCandidateList(candidatePickedList);
    }
  }, [props.materialsInProject]);

  return (
    <>
      <div className="scene-card__list">
        <h3 className="scene-card__title">项目场景</h3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {provided => (
              <div ref={provided.innerRef} className="scene-card__wrap" {...provided.droppableProps}>
                {sceneInfoSet.map((material, index) => (
                  <Draggable
                    key={material.BasicInfo.MaterialId}
                    draggableId={material.BasicInfo.MaterialId}
                    index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        className="scene-card__item"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // style={getItemStyle(
                        //   snapshot.isDragging,
                        //   provided.draggableProps.style
                        // )}
                      >
                        <div className="scene-card__img-wrap" onClick={() => {
                          props.selectMaterial(material);
                        }}>
                          <img className="scene-card__img" src={imageProcess(material.decorationInfoSet?.modelCover, '2.jpg') || defaultCover} alt="" />
                          <img
                            className="scene-card__icon"
                            src={sceneModelIcon}
                            alt=""
                          />
                        </div>
                        <p className="scene-card__name">{material.BasicInfo.Name}</p>
                        <img className="scene-card__delete" src={iconClose} alt="delete" onClick={() => {
                          props.removeMaterialFromProject(material.BasicInfo.MaterialId);
                        }} />
                      </div>
                    )}
                  </Draggable>
                ))}
                <div className="scene-card__item">
                  <div className="scene-card__add" onClick={() => {
                    setPickMaterialVisible(true);
                  }}>
                    <p className="scene-card__add-label">添加场景</p>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <PickMaterial
        actionType="project"
        projectId={props.projectId}
        pickType="add"
        pickMaterialVisible={pickMaterialVisible}
        setPickMaterialVisible={(isVisible) => {
          setPickMaterialVisible(isVisible);
        }}
        reflashProjectList={reflashProjectList}
        candidateList={candidateList}
      />
  </>);
};

SceneList.propTypes = {
  projectId: PropTypes.string,
  materialsInProject: PropTypes.array,
  materialIdsInProject: PropTypes.array,
  modifyResourceOrderingView: PropTypes.func,
  removeMaterialFromProject: PropTypes.func,
  selectMaterial: PropTypes.func,
  updateMaterialIds: PropTypes.func,
};
