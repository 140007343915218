import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Layout, Checkbox, Button } from 'tdesign-react';
import { DeleteIcon } from 'tdesign-icons-react';
import {
  imageProcess,
} from '../../../utils/tools';
import screenShotDefault from '../../../assets/images/screen-shot-default.png';

const { Header, Content, Footer, Aside } = Layout;

export const PointRelation = (props) => {
  const [list, setList] = useState([]);
  const [relationList, setRelationList] = useState([]);

  function handleSelectChange(checked, context) {
    const tempList = list.concat();
    tempList.forEach((item) => {
      if (item.BasicInfo.MaterialId === context.e.target.name) item.isChecked = checked;
    });
    setList(tempList);
  }

  function handleCreateRelation() {
    const indexList = [];
    const tempList = list.concat();
    tempList.forEach((item, index) => {
      if (item.isChecked && !item.isStock) {
        indexList.push(index);
        item.isStock = true;
        item.isChecked = false;
      }
    });
    if (indexList.length) {
      const tempRelationList = relationList.concat();
      tempRelationList.push({ list: indexList, name: generateName() });
      setRelationList(tempRelationList);
      setList(tempList);
    }
  }

  // 生成关联列表的名字，最后一项名字的数字往后加1
  function generateName() {
    if (relationList.length !== 0) {
      const lastName = relationList[relationList.length - 1]?.name;
      const index = lastName.replace('关联', '');
      return `关联${Number(index) + 1}`;
    }
    return '关联1';
  }

  function handleDeleteRelation(name) {
    const tempRelationList = relationList.concat();
    const targetIndex = tempRelationList.findIndex(item => item.name === name);
    const deleteTarget = tempRelationList.splice(targetIndex, 1)[0];
    const tempList = list.concat();
    tempList.forEach((item, index) => {
      if (deleteTarget.list.includes(index)) {
        item.isStock = false;
      }
    });
    setRelationList(tempRelationList);
    setList(tempList);
  }

  function handleFinishRelation() {
    props.setRelationInfos(relationList);
  }

  // 针对 isChecked，isStock 清洗数据
  function washInitData(list) {
    const relationTwoDimension = [];
    relationList.forEach((item) => {
      relationTwoDimension.push(item.list);
    });
    const relationOneDimension = relationTwoDimension.flat();
    const tempList = list.concat();
    tempList.forEach((item, index) => {
      if (relationOneDimension.includes(index)) {
        item.isStock = true;
      } else {
        item.isStock = false;
      }
      item.isChecked = false;
    });
    setList(tempList);
  }

  useEffect(() => {
    if (props.showPonitRelation) {
      washInitData(props.materialCandidateList);
    }
  }, [props.showPonitRelation]);

  useEffect(() => {
    if (props.needToClearPointInfos) {
      setRelationList([]);
    }
  }, [props.needToClearPointInfos]);

  return (
    <Dialog
      header="点位关系设置"
      className="invision-dialog--no-padding setting-point-relation__dialog"
      width={1300}
      footer={false}
      zIndex={10002}
      visible={props.showPonitRelation}
      onClose={() => {
        props.setShowPoint(false);
      }}
    >
      <Layout className="setting-point-relation__wrap">
        <Layout className="setting-point-relation__left">
          <Header>请选择空间位置相关的点位，并创建关联</Header>
          <Content className="setting-point-relation__left-scroll">
            {/* 高度需要按弹窗自定义 */}
            <ul className="file-cards__wrap file-cards--small is-in-modal">
              {/* is-active 激活态 「is-active」、 半透明 「is-lower」 */}
              {list.map(item => (
                <li key={item.BasicInfo.MaterialId} className={`file-card ${item.isChecked && 'is-active'} ${item.isStock && 'is-lower'}`}>
                  <div className="file-card__content">
                    <div className="file-card__img-wrap">
                      <img
                        className="file-card__img"
                        src={imageProcess(item.BasicInfo.PreviewUrl, '1.jpg') || screenShotDefault}
                        alt={item.BasicInfo.Name}
                      />
                    </div>
                    <Checkbox
                      className="file-card__checkbox"
                      name={item.BasicInfo.MaterialId}
                      disabled={item.isStock}
                      checked={item.isChecked}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="file-card__info">
                    <div className="file-card__text">
                      <p
                        className="file-card__title"
                        title={item.BasicInfo.Name}
                      >
                        {item.BasicInfo.Name}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Content>
        </Layout>
        <Aside width={270}>
          <Layout className="setting-point-relation__right">
            <Header>
              <Button
                className="invision-button invision-button--outline invision-button--round"
                theme="default"
                onClick={handleCreateRelation}
              >
                创建关联
              </Button>
            </Header>
            <Content className="setting-point-relation__right-scroll">
              {
                relationList.length
                  ? relationList.map((item, index) => (
                    <div key={`relation-${index}`} className="setting-point-relation__item">
                      <p className="setting-point-relation__item-name">{item.name}</p>
                      <p className="setting-point-relation__item-hint">
                        共{item.list.length}张
                      </p>
                      <Button
                        className="setting-point-relation__item-btn invision-button--icon"
                        shape="square"
                        variant="text"
                        onClick={() => {
                          handleDeleteRelation(item.name);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  ))
                  : <div className="invision-empty__tip">暂无关联</div>
              }
            </Content>
            <Footer>
              <div className="invision-button__group invision-button__group--center">
                <Button
                  className="invision-button invision-button--primary invision-button--round"
                  theme="primary"
                  // disabled={!relationList.length}
                  onClick={handleFinishRelation}
                >
                  完成
                </Button>
                <Button
                  className="invision-button invision-button--secondary invision-button--round"
                  theme="default"
                  onClick={() => {
                    props.setShowPoint(false);
                  }}
                >
                  取消
                </Button>
              </div>
            </Footer>
          </Layout>
        </Aside>
      </Layout>
    </Dialog>
  );
};

PointRelation.propTypes = {
  showPonitRelation: PropTypes.bool,
  needToClearPointInfos: PropTypes.bool,
  materialCandidateList: PropTypes.array,
  setShowPoint: PropTypes.func,
  setRelationInfos: PropTypes.func,
};
