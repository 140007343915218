/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import * as useStateRef from 'react-usestateref';
import { Button, Radio, message } from '@tencent/ten-design-react';
import { QRCodeSVG } from 'qrcode.react';
import ImsTour from '../../lib/imsTour/imsTour';
import md5 from 'crypto-js/md5';
import { Player } from '@tencent/vtour';
import { CubemapViewer } from '@tencent/vviewer';
import Tags from '../../components/tags/index';
import Mosaics from '../../components/mosaics/index';
import ModelLoading from '../../components/modelLoading/index';
import ModelTitle from '../../components/modelTitle/index';

import {
  getQueryVariable,
  parseImage,
  imageProcess,
} from '../../utils/tools';
import formatJson from '../../utils/formatJson';
import convertorData from '../../utils/convertorData';
import './index.css';
import client from '../../utils/apiClient';

const axios = require('axios').default;

// === 常量初始化 ===
const { THREE } = ImsTour;
const els = {};
const IV = 'mt11G34f816eHe1c'; // 加解密模型向量
const defaultScreenShot = 'https://cvr.tencentcs.com/vr-common/imsTour/screen-shot-default.png';
// === 变量初始化 ===
let vTour;
let vTourData = null;
let DECORATION_INFO = {}; // 平台可修改的项目模型合集信息
let MODEL_DECORATION_INFO = {}; // 平台可修改的模型信息
let MODEL_KEY = ''; // 加解密模型
let PROJECT_UUID = ''; // 加解密模型
let materialIdsInProject = []; // 项目内媒资 id 列表

function Preview() {
  const PROJECT_INFO = { // 建模后不可改的模型相关信息
    projectId: getQueryVariable('projectId'),
  };
  const tags = useRef([]); // 所有的标签
  const mosaics = useRef([]); // 所有的马赛克
  const [showTags, setShowTags] = useState([]); // 当前屏幕内要展示的 tags
  const [showMosaics, setShowMosaics] = useState([]); // 当前屏幕内要展示的 mosaics
  const [previewH5Link, setPreviewH5Link] = useState(''); // 预览二维码
  const [modelIsLoading, setModelIsLoading] = useState(true);
  const [materialUrls, setMaterialUrls] = useState({});
  const [showUs, setShowUs] = useState(false);
  const [projectName, setProjectName] = useState(''); // 项目名称，区别于项目标题
  const [style, setStyle] = useState(null);
  const [linkType, setLinkType] = useState('free'); // 分发链接类型 free: 自由参观 guide: 同屏带看
  const [relatedMaterials, setRelatedMaterials] = useState([]);
  const [materialId, setMaterialId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [labSdkType, setLabSdkType, labSdkTypeRef] = useStateRef('');

  function getProjects() {
    return client('PaaS/Project/DescribeProjects', { body: { ProjectIds: [PROJECT_INFO.projectId], Category: 'ImmersiveViewing' } });
  }

  function getMaterialFromProject() {
    return client('PaaS/Project/DescribeProjectMaterials', { body: { ProjectId: PROJECT_INFO.projectId } });
  }

  // 贝壳 SDK 初始化
  function initImsTour() {
    vTour = new ImsTour({
      // 初始化为全景观测状态状态
      mode: ImsTour.Mode.Panorama,
      fov: MODEL_DECORATION_INFO?.initInfo?.fov || 100,
      panoIndex: MODEL_DECORATION_INFO?.initInfo?.panoIndex || 30,
      longitude: MODEL_DECORATION_INFO?.initInfo?.longitude || 1.3037607459427605,
      latitude: MODEL_DECORATION_INFO?.initInfo?.latitude || -0.009540685247268463,
      // 取消初始化镜头过度动画
      initWithTransition: false,
      // 图片显示参数
      imageOptions: {
        size: 1024,
        quality: 80,
        format: 'jpg',
        transform: parseImage,
      },
      // 模型贴图参数
      textureOptions: {
        size: 512,
        quality: 80,
        format: 'jpg',
        transform: parseImage,
      },
      onlyRenderIfNeeds: true,
    });
    // 将渲染视图的 canvas 添加到 DOM 中
    vTour.appendTo(document.getElementById('preview-canvas'));

    // 如果显示区域需要变动，在变动时请调用 refresh 重置显示参数
    window.addEventListener('resize', () => {
      vTour.refresh();
    });

    // vTour.on('modeChange', (mode) => {
    //   setVrMode(mode);
    // });

    // 模型加载完成
    vTour.on('modelLoaded', () => {
      setModelIsLoading(false);
      computeTagsPosition('tag', tags.current);
      computeTagsPosition('mosaic', mosaics.current);
    });

    // 视角更新，更新 tag 位置
    vTour.on('cameraDirectionUpdate', () => {
      if (vTour.model.intersectRaycaster) {
        computeTagsPosition('tag', tags.current);
        computeTagsPosition('mosaic', mosaics.current);
      }
    });
    vTour.on('movingToPano', () => {
      computeTagsPosition('tag', tags.current);
      computeTagsPosition('mosaic', mosaics.current);
    });
  }

  // 实验室 SDK 初始化
  async function initVTour(data, modelVsersion) {
    const instanceData = convertorData(data, MODEL_DECORATION_INFO, labSdkTypeRef.current);
    if (modelVsersion >= 1.3) {
      const newIv = md5((IV + PROJECT_UUID).split('').reverse()
        .join(''))
        .toString()
        .substr(0, 16);
      instanceData.data.kiv = [MODEL_KEY, newIv];
    }
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour = new CubemapViewer({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          ctlDampingFactor: 0.15,
        }),
      });
    } else {
      vTour = new Player({
        container: els.root,
        ...instanceData,
        configs: Object.assign({}, {
          fov: MODEL_DECORATION_INFO?.initInfo?.fov || 100,
          preserveDrawingBuffer: true,
        }),
      });
    }
    await vTour.start();
    if (labSdkTypeRef.current === 'lab-aerial') {
      vTour.updateView({
        position: [0, 0.6, 0],
        lonLat: [0, 0],
        duration: 0,
        progress: 1,
      });
      setTimeout(() => {
        vTour.updateView({
          position: [0, 0, 0],
          lonLat: [0, 0],
          duration: 1500,
        });
      }, 2000);
    }
    // 模型加载完
    modelLoaded();

    // setTimeout(() => {
    //   vTour.moveTo('public.19');
    // }, 3000);
    // 如果显示区域需要变动，在变动时请调用 refresh 重置显示参数
    // window.addEventListener('resize', () => {
    //   vTour.refresh();
    // });

    // 开始前往下一个点位
    vTour.on('moveStart', (e) => {
      console.log('=====moveStart====', e);
    });

    // 点击某一点位
    vTour.on('pointerIntersect', (e) => {
      console.log('=====pointerIntersect====', e);
    });

    // 开始切换场景
    vTour.on('sceneChangeStart', (e) => {
      console.log('=====sceneChangeStart====', e);
    });

    // 完成切换场景
    vTour.on('sceneChangeEnd', (e) => {
      console.log('=====sceneChangeEnd====', e);
    });

    // 观看模式切换
    vTour.on('modeChange', (e) => {
      console.log('====modechange=====', e);
    });

    // 相机角度切换
    vTour.on('cameraDirectionChange', (e) => {
      console.log('====cameraDirectionChange=====', e);
    });
  }

  function modelLoaded() {
    setModelIsLoading(false);
  }

  function getVTourData() {
    client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: [...materialIdsInProject] } }).then((res) => {
      if (res.data.Data) {
        setRelatedMaterials(res.data.Data.MaterialInfoSet);
        const data = res.data.Data.MaterialInfoSet[0];
        if (data) {
          MODEL_KEY = data.Immersive3DModelMaterial.ModelKey || '';
          tags.current = MODEL_DECORATION_INFO.tags || [];
          mosaics.current = MODEL_DECORATION_INFO.mosaics || [];
          tags.current.length && getMaterialUrls();
          if (data.Immersive3DModelMaterial.IndexUrl !== '') {
            getModelData(data.Immersive3DModelMaterial.IndexUrl);
          } else {
            initImsTour();
            const modelData = JSON.parse(data.Immersive3DModelMaterial.Index);
            vTourData = formatJson(modelData.ModelDetail);
            vTour.load(vTourData);
          }
        }
      }
    });
  }

  // 实验室 SDK 需额外获取全景数据
  function getModelData(url) {
    axios({
      method: 'GET',
      url,
      responseType: 'json',
    }).then((res) => {
      if (res.data.MaterialType === 'XRLabAerial') {
        // 星球模型
        vTourData = res.data.ModelDetail;
        setLabSdkType('lab-aerial');
      } else {
        vTourData = vTourDataMaker(res.data.ModelDetail);
        setLabSdkType('');
      }
      PROJECT_UUID = vTourData.projectID;
      initVTour(vTourData, res.data.ModelVersion);
    });
  }

  // 实验室 SDK 数据格式化出点位信息
  function vTourDataMaker(data) {
    let panosArr = [];
    let cameraHeight = 0;
    if (data.scenes) {
      // 旧数据
      panosArr = data.scenes[0].panos;
      cameraHeight = data.configs[0].standHeight;
    } else {
      // 新数据
      panosArr = data.data.scenes[0].panos;
      cameraHeight = data.data.cameraHeight;
    }
    const observersArr = panosArr.map((pano) => {
      const [x, y, z] = pano.position;
      return {
        index: pano.index.split('.')[1],
        position: pano.position,
        standing_position: [x, y - cameraHeight, z],
        visible_nodes: pano.visibleNodes,
      };
    });
    return Object.assign(data, {
      observers: observersArr,
    });
  }

  function getMaterialId(media) {
    if (!media.includes('//')) {
      let id = '';
      if (media.includes('@-@')) {
        [id] = media.split('@-@');
      } else {
        id = media;
      }
      return id;
    }
    return '';
  }

  // 获取实际的 url
  function getMaterialUrls() {
    const ids = {};
    MODEL_DECORATION_INFO.tags.forEach((tag) => {
      const { mediaSrc, coverSrc } = tag.mediaData;
      const mediaId = mediaSrc ? getMaterialId(mediaSrc) : '';
      const coverId = coverSrc ? getMaterialId(coverSrc) : '';
      if (mediaId && mediaId !== '') ids[mediaId] = mediaId;
      if (coverId && coverId !== '') ids[coverId] = coverId;
    });
    client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: Object.keys(ids) } }).then((res) => {
      if (!res.data.Data) {
      } else {
        res.data.Data.MaterialInfoSet.forEach((material) => {
          switch (material.BasicInfo.MaterialType) {
            case 'IMAGE':
              ids[material.BasicInfo.MaterialId] = material.ImageMaterial.MaterialUrl;
              break;
            case 'AUDIO':
              ids[material.BasicInfo.MaterialId] = material.AudioMaterial.MaterialUrl;
              break;
            case 'VIDEO':
              ids[material.BasicInfo.MaterialId] = material.VideoMaterial.MaterialUrl;
              // ids[`${material.BasicInfo.MaterialId}_COVER`] = material.VideoMaterial.CoverUrl; // 不管有没有上传封面，先拿了再说
              break;
            default:
              break;
          }
        });
        setMaterialUrls(Object.assign({}, ids));
      }
    });
  }

  // 获取分享 ID 模型列表页已经调过这个接口 TODO
  function getShareToken() {
    client('PaaS/MaterialPolicy/ShareProjectResource', {
      body: {
        ProjectId: PROJECT_INFO.projectId,
        Permissions: ['R'],
        ExpiredSecond: 311040000, // 10年
      } }).then((res) => {
      if (res.data.Data) {
        setPreviewH5Link(`https://cvr.tencentcs.com/${process.env.REACT_APP_RUNNING_ENV === 'production' ? 'invision' : 'invision-dev'}/index.html?projectId=${PROJECT_INFO.projectId}&shareId=${res.data.Data.ShareId}`);
      }
    });
  }

  function computeTagsPosition(type, objects) {
    const showObjectsTmp = [];
    const raycaster = new THREE.Raycaster();
    for (let i = 0; i < objects.length; i++) {
      const object = Object.assign({},  objects[i]); // 保证不污染 objects 里面的每一个对象
      const { x, y, z } = object.position;
      const position = object.position.clone ? object.position : new THREE.Vector3(x, y, z);

      raycaster.ray.origin = vTour.camera.position;
      raycaster.ray.direction = position.clone().sub(vTour.camera.position)
        .normalize();

      const distance = position.distanceTo(vTour.camera.position);
      const intersects = vTour.model.intersectRaycaster(raycaster);
      // 判断被墙面等模型挡住的
      if (intersects.length === 0 || intersects[0].distance - distance < -0.01 || distance > 10) {
        continue;
      }
      const coords = position.clone().project(vTour.camera);
      // 判断跑出当前视野区域的
      if (Math.abs(coords.x) > 1 || Math.abs(coords.y) > 1 || Math.abs(coords.z) > 1) {
        continue;
      }
      object.left = `${((coords.x + 1) / 2) * 100}%`;
      object.top = `${((-coords.y + 1) / 2) * 100}%`;
      showObjectsTmp.push(object);
    }
    type === 'tag' ? setShowTags(showObjectsTmp) : setShowMosaics(showObjectsTmp);
  }

  function handleRadioChange(e) {
    setLinkType(e.target.value);
  }

  function renderShareDom(role) {
    let linkTitle = 'H5访问链接';
    let linkContent = previewH5Link;
    if (role === 'guide') {
      linkTitle = 'H5访问链接 - 讲解员端';
      linkContent = `${previewH5Link}&role=guide`;
    } else if (role === 'visit') {
      linkTitle = 'H5访问链接 - 观众端';
      linkContent = `${previewH5Link}&role=visit`;
    }
    return (<div className="invision-justify model-details__overview">
      <div className="invision-justify__col model-overview__item model-overview__item-qrcode">
        <p className="model-overview__title">手机扫码预览</p>
        <div className="model-overview__content">
          <QRCodeSVG className="model-overview__qrcode-image" value={linkContent} />
        </div>
      </div>
      <div className="invision-justify__col model-overview__item model-overview__item-h5">
        <p className="model-overview__title">{linkTitle}</p>
        <div className="model-overview__content">
          <div className="model-overview__url">
            {linkContent}
          </div>
          <div className="invision-button-group">
            <Button
              className="invision-button invision-button--primary invision-button--link size-sm"
              theme="text"
              size="small"
              onClick={() => window.open(linkContent)}
            >
              打开
            </Button>
            <Button
              className="invision-button invision-button--primary invision-button--link size-sm"
              theme="text"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(linkContent);
                message.success('复制成功');
              }}
            >
              复制
            </Button>
          </div>
        </div>
      </div>
    </div>);
  }

  useEffect(async () => {
    if (PROJECT_INFO.projectId) {
      const resProjects = await getProjects();
      const projectInfo = resProjects.data.Data.ProjectInfoSet[0];
      const immersiveViewingInfo = projectInfo.ImmersiveViewingInfo;
      let decoration = '';
      let materialIds = [];
      setProjectName(projectInfo.Name);
      if (immersiveViewingInfo) { // 后端维护数据的方式，immersiveViewingInfo 不一定存在
        decoration = immersiveViewingInfo.DecorationInfo;
        materialIds = immersiveViewingInfo.DecorationMaterialIds;
      }
      DECORATION_INFO = Object.assign({}, JSON.parse(decoration === '' ? '{}' : decoration));
      if (!materialIds.length) {
        const resMaterials = await getMaterialFromProject();
        materialIds = resMaterials.data.Data.MaterialIdSet;
      }
      if (materialIds.length) {
        materialIdsInProject = materialIds;
        setMaterialId(materialIds[0]);
        MODEL_DECORATION_INFO = DECORATION_INFO[materialIds[0]] || {};
        MODEL_DECORATION_INFO.style && setStyle(MODEL_DECORATION_INFO.style);
        els.root = document.getElementById('preview-canvas');
        getVTourData();
        getShareToken();
      }
    }
  }, []);

  return (
    <section className="invision-container is-vertical theme-dark">
      <ModelTitle modelName={projectName} projectId={PROJECT_INFO.projectId} materialId={materialId} />
      <section className="invision-container model-details__preview">
        <aside className="model-details__inspector">
          <div className="model-details__inspector-wrap">
            <div className="model-properties">
              <div className="model-properties__header">
                <h2 className="model-properties__title">预览分发</h2>
              </div>
              <div className="preview-config__item">
                <p className="preview-config__title">预览模式</p>
                <div className="preview-config__content">
                  <Radio.Group value={linkType} onChange={handleRadioChange}>
                    <Radio className="invision-radio" value="free" checked={true}>
                      自由参观
                    </Radio>
                    <Radio className="invision-radio" value="guide">
                      同屏带看
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            {
              previewH5Link && linkType === 'free'
                ? renderShareDom()
                : null
            }
            {
              previewH5Link && linkType === 'guide'
                ? <>
                  {
                    renderShareDom('guide')
                  }
                  {
                    renderShareDom('visit')
                  }
                </>
                : null
            }
          </div>
        </aside>
        <main className="invision-main model-details__main">
          <div className="model-details__main-wrap">
            <div className="device-simulator">
              <div className="device-simulator__phone">
              </div>
              <div className="device-simulator__screen">
                <div className="device-simulator__screen-content">
                  <div className="device-simulator__preview invision-mobile">
                    <div className="app-back">
                      {
                        modelIsLoading
                          ? <ModelLoading platform="mobile" />
                          : null
                      }
                      <div id="preview-canvas" className="preview-phone viewport"></div>
                      {
                        showTags.length > 0
                          ? <Tags
                            materialUrls={materialUrls}
                            showTags={showTags}
                            inPreview={true} />
                          : null
                      }
                      <Mosaics showMosaics={showMosaics} />
                    </div>
                    <div className={`invision-mod-container ${showUs ? 'show-all' : ''}`} style={{ width: '100%' }}>
                      <div className="invision-mod-container__toggle" onClick={() => setShowUs(val => !val)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="design-iconfont">
                          <path d="M7.60523911,5.12886275 C7.87276941,4.97805891 8.19234081,4.95964196 8.47253058,5.07347338 L8.58993425,5.13035471 L15.4935144,9.04943993 C15.9737958,9.32209026 16.1421188,9.93247048 15.8694744,10.4127623 C15.6163046,10.8587476 15.0719354,11.0357425 14.6109199,10.8404529 L14.5061819,10.7887305 L8.09499932,7.149 L1.4911835,10.8711372 C1.04444287,11.12296 0.48668933,10.9942143 0.192161448,10.589329 L0.129036461,10.4910396 C-0.122780736,10.0442892 0.00596207463,9.48652345 0.410838508,9.19198912 L0.509125779,9.12886275 L7.60523911,5.12886275 Z" fill="#FFF" fillRule="nonzero"/>
                        </svg>
                      </div>
                      <div className="invision-mod-about-us no-animation is-shown">
                        <div className="invision-info__wrap">
                          <div className="invision-info__left">
                            <p className="invision-info__name">{ style?.sceneTitle?.value || '标题' }</p>
                            <p className="invision-info__desc">{ style?.sceneDesc?.value || '描述' }</p>
                          </div>
                          <div className="invision-info__more" onClick={() => {
                            location.href = style?.sceneUrl?.value.url || 'https://cloud.tencent.com/solution/imd';
                          }}>{ style?.sceneUrl?.value.title || '了解更多'}&gt;&gt;</div>
                        </div>
                        <ul className="invision-mod-scene__list">
                          {
                            relatedMaterials.map((item, index) => {
                              if (index !== 0) {
                                return (
                                  <li key={item.BasicInfo.MaterialId} className="invision-mod-scene__item">
                                    <img src={imageProcess(item.BasicInfo.PreviewUrl, '2.jpg') || defaultScreenShot} alt={item.BasicInfo.Name} />
                                    <p className="invision-mod-scene__name">{item.BasicInfo.Name}</p>
                                  </li>
                                );
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </section>
  );
}

export default Preview;
