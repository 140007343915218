import React, { useState, useLayoutEffect } from 'react';
import { useAsync } from 'react-async';
import * as authClient from '../utils/authClient';
import FullPageLoading from '../components/fullPageLoading';
const AuthContext = React.createContext();

async function linjingAppData() {
  const { Data } = await authClient.getUser();
  if (!Data) {
    return { user: null };
  }
  return {
    user: Data,
  };
}

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = { user: null },
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: linjingAppData,
  });

  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);


  if (!firstAttemptFinished) {
    if (isPending) return <FullPageLoading />;
    if (isRejected) {
      return (
        <div css={{ color: 'red' }}>
          <p>Uh oh... There is a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const login = form => authClient.login(form).then((res) => {
    reload();
    return res;
  });
  const logout = () => authClient.logout().then(reload);
  const getVerifyCode = form => authClient.getVerifyCode(form);
  const getUser = form => authClient.getUser(form);

  return (
    <AuthContext.Provider value={{ data, login, logout, getVerifyCode, getUser }} {...props} />
  );
}
const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
