import React from 'react';
import { useAuth } from '../../../context/authProvider';
import { useUser } from '../../../context/userProvider';
import {
  Dropdown,
} from '@tencent/ten-design-react';
import { hidePhoneNumber } from '../../../utils/tools';

import logo from '../../../assets/images/svg/logo-s.svg';
import avatar from '../../../assets/images/svg/default-avatar.svg';

export const ModelHeader = () => {
  const { logout } = useAuth();
  const user = useUser();
  function onItemClick() {
    logout();
  }

  return (
    <header className="invision-header invision-layout__header">
      <nav className="invision-navbar">
        <div className="invision-navbar__logo">
          <a
            href="//invision.tencentcs.com/"
          >
            <img src={ logo } alt="腾讯临境" />
          </a>
        </div>
        <div className="invision-navbar__operation" style={{ display: 'flex' }}>
          <p style={{
            alignItems: 'center',
            display: 'flex',
            marginRight: '40px',
          }}><a style={{
            color: '#2864f0',
          }} href="https://cloud.tencent.com/document/product/1406/53329" target="_blank" rel="noreferrer"><span style={{
            height: '18px',
            width: '18px',
            lineHeight: '18px',
            display: 'inline-block',
            backgroundColor: '#2864f0',
            verticalAlign: 'middle',
            margin: '-1px 5px 0 0',
            color: '#FFFFFF',
            textAlign: 'center',
            borderRadius: '9px',
          }}>?</span>产品使用教程</a></p>
          <Dropdown
            data={ [{ text: '退出' }] }
            triggerType="hover"
            onItemClick={ onItemClick }
            renderItem={ item => (<span> {item.text} </span>) }
            width={ childElem => Math.max(childElem.offsetWidth, 100) }
          >
            <div className="account-wrap">
              <div className="account__avatar">
                <img src={ avatar } alt="" />
              </div>
                <span className="account__name">{hidePhoneNumber(user.Phone)}</span>
            </div>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
};
