import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';

export const ModelAside = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [submenuOpen, setSubmenuOpen] = useState(true);

  function switchSubmenu() {
    setSubmenuOpen(v => !v);
  }

  function changeTab(to) {
    props.changeTab(to);
    history.push(`${window.URL_BASE_NAME}/${to}/`);
  }

  return (
    <aside className="invision-aside invision-layout__sidebar">
      <div className="invision-menu">
        <ul className="invision-menu__list">
          {/* 選中態「is-selected」 */}
          <li className={`invision-menu__item ${location.pathname.includes('/project') ? 'is-selected' : ''}`} onClick={() => changeTab('project')}>
            <a className="invision-menu__item-content">
              <i className="invision-menu__item-icon invision-icon-project"></i>
              <span className="invision-menu__item-text">全景漫游项目</span>
            </a>
          </li>
          <li className={cn(
            'invision-menu__item',
            { 'is-children-open': submenuOpen },
            { 'is-selected': [1, 2].includes(location.pathname) },
          )} onClick={switchSubmenu}>
            <button className="invision-menu__item-content">
              <i className="invision-menu__item-icon invision-icon-material" />
              <span className="invision-menu__item-text">素材管理</span>
              <i className="invision-menu__item-arrow" />
            </button>
          </li>
          <li className={cn('invision-menu__item invision-menu__submenu-item', { 'is-open': submenuOpen }, { 'is-selected': location.pathname.includes('/model') })} onClick={() => changeTab('model')}>
            <a className="invision-menu__item-content">
              <div className="invision-menu__submenu-item-dot" />
              <span className="invision-menu__item-text">3D 模型</span>
            </a>
          </li>
          <li className={cn('invision-menu__item invision-menu__submenu-item', { 'is-open': submenuOpen }, { 'is-selected': location.pathname.includes('/panoramic') })} onClick={() => changeTab('panoramic')}>
            <a className="invision-menu__item-content ">
              <div className="invision-menu__submenu-item-dot" />
              <span className="invision-menu__item-text">全景图片</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

ModelAside.propTypes = {
  changeTab: PropTypes.func,
};
