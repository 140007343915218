import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import logoFooter from '../../assets/images/svg/login-footer-logo.svg';

ModelLoading.propTypes = {
  platform: PropTypes.string,
};

function ModelLoading(props) {
  const bgImageSrc = `https://cvr.tencentcs.com/vr-common/imsTour/${props.platform === 'mobile' ? 'loading-bg' : 'loading-bg-pc'}.jpg`;

  const [opacity, setOpacity] = useState(0);

  function loadBgImage() {
    const img = new Image();
    img.src = bgImageSrc;
    img.onload = function () {
      setOpacity(1);
    };
  }

  useEffect(() => {
    loadBgImage();
    return () => {
    };
  }, []);

  return (
    <div id="opening" className="opening" module="opening">
      <div className="opening-bg js-bg" style={{ backgroundImage: `url(${bgImageSrc})`, opacity }}>
        <div className="img-wrap">
          <img style={{ width: props.platform === 'mobile' ? '70%' : '25%' }} src={logoFooter} alt="powered by"/>
        </div>
      </div>
      <div className="opening-cover"></div>
    </div>
  );
}

export default ModelLoading;
