import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup, Tooltip } from '@tencent/ten-design-react';
import cn from 'classnames';
import iconMore from '../assets/images/svg/icon-more.svg';
import iconLink from '../assets/images/svg/icon-link.svg';

export const BtnMore = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const switchVisiblePorps = {
    onMouseEnter: () => setIsVisible(true),
    onMouseLeave: () => setIsVisible(false),
  };

  return (
    <Popup
      direction="bottom"
      show={isVisible}
      content={
        <div {...switchVisiblePorps} className="invision-poppu__btn-list">
          <button onClick={() => {
            props.rename();
            setIsVisible(false);
          }}>重命名</button>
          <button onClick={() => {
            props.move();
            setIsVisible(false);
          }}>移动到</button>
          <div className="devider__horizental"></div>
          <button onClick={() => {
            props.delete();
            setIsVisible(false);
          }}>删除</button>
        </div>
      }
    >
      <Button
        {...switchVisiblePorps}
        className={cn('file-card__info-btn', {
          'file-card__info-btn--default-hide': !isVisible,
        })}
        icon={<img src={iconMore} alt="btn-more" />}
      ></Button>
    </Popup>
  );
};

export const FileCardBtnGroup = props => (
  <div className="file-card__info-btn-wrap">
    <BtnMore
      rename={props.rename}
      move={props.move}
      delete={props.delete}
    />
    {
      props.navType === 'project'
        ? <Tooltip
          className="invision-tooltip file-card__link-tooltip"
          message={<div>查看漫游链接</div>}
          dark
        >
          <Button
            className="file-card__info-btn"
            icon={<img src={iconLink} alt="btn-Link" />}
            onClick={props.showPreviewProject}
          ></Button>
        </Tooltip>
        : null
    }
  </div>
);

FileCardBtnGroup.propTypes = {
  navType: PropTypes.string,
  rename: PropTypes.func,
  move: PropTypes.func,
  delete: PropTypes.func,
  showPreviewProject: PropTypes.func,
};

BtnMore.propTypes = {
  rename: PropTypes.func,
  move: PropTypes.func,
  delete: PropTypes.func,
};
