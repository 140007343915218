import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Checkbox } from '@tencent/ten-design-react';
import { useAuth } from '../../context/authProvider';
import logo from '../../assets/images/svg/login-logo.svg';
import logoFooter from '../../assets/images/svg/login-footer-logo.svg';
import copyRightLogo from '../../assets/images/svg/copyright-logo.svg';
import './index.css';


let currentVerifyCodeId = ''; // 接口用的校验码
let timeOut;

function Login() {
  const { getVerifyCode, login } = useAuth();
  const [verifyCode, setVerifyCode] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneErr, setPhoneErr] = useState('');
  const [time, setTime] = useState(60);
  const [show, setShow] = useState(false);
  const [codeErr, setCodeErr] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);
  const phoneVilade = /^[1][0-9]{10}$/.test(phone);

  async function handleVerifyCode() {
    if (!policyChecked) {
      message.warning('请先勾选用户隐私协议');
      return;
    }
    setShow(true);
    timeOut = setInterval(() => {
      setTime((time) => {
        if (time > 0) {
          return time - 1;
        }
        setShow(false);
        clearInterval(timeOut);
        return 60;
      });
    }, 1000);
    const { Code, Data } = await getVerifyCode(phone);
    if (Code === 'Success') {
      currentVerifyCodeId = Data.VerifyCodeId;
    } else {
      message.error('验证码发送频繁');
      setShow(false);
      clearInterval(timeOut);
    }
  }

  function handleSubmit() {
    login({ phone, verifyCode, verifyCodeId: currentVerifyCodeId }).then(({ Code }) => {
      if (Code !== 'Success') {
        setCodeErr(true);
      }
    });
  }

  function handlePolicyClick(type) {
    if (type === 'user') {
      window.open('https://rule.tencent.com/rule/preview/14cbaed3-c648-4d47-a9c4-8d2de56f8d9c');
    } else {
      window.open('https://rule.tencent.com/rule/preview/0f6696ea-7606-4587-b1ce-cdc75f3a4ef7');
    }
  }

  useEffect(() => (() => {
    clearInterval(timeOut);
  }), []);

  return (
    <div className="invision-root">
    <section className="invision-container is-vertical login">
    <main className="invision-main invision-layout__content" style={{ overflow: 'hidden' }}>
      <div className="invision-card invision-card--shadow login-card">
        <div className="invision-card__body login-card__content">
          <div className="invision-card__content">
            <div className="login-card__logo">
              <img src={ logo } alt="腾讯临境" />
            </div>
            <Form className="invision-form login-card__form">
              <Form.Item className="invision-form__item" error={ phoneErr }>
                <div className="invision-form__controls">
                  <div className="invision-form__controls-inner">
                    <Input className="invision-input invision-input--border-bottom size-md" value={phone} onChange={({ target: { value } }) => {
                      setPhone(value);
                      if (/^[1][0-9]{10}$/.test(value)) {
                        setPhoneErr(false);
                      }
                    }} onBlur={(e) => {
                      if (!e.target.value) {
                        setPhoneErr('请输入手机号！');
                        return;
                      }
                      setPhoneErr('');
                      if (!phoneVilade) {
                        setPhoneErr('手机号格式错误！');
                        return;
                      }
                      setPhoneErr('');
                    }} placeholder="请输入手机号" />
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="invision-form__item" statusIcon={ false } error={codeErr ? '请输入正确的验证码' : ''}>
                <div className="invision-form__controls">
                  <div className="invision-form__controls-inner">
                    <Input
                        className="invision-input invision-input--border-bottom size-md login-card__verification-code invision-button--round"
                        placeholder="请输入验证码"
                        value={verifyCode}
                        onChange={({ target: { value } }) => setVerifyCode(value)}
                        suffix={
                          show
                            ? <div style={{ width: '110px' }}>{time}秒后重试</div>
                            : <Button
                            className="invision-button invision-button--primary invision-button--round invision-button--outline size-sm"
                            theme="primary"
                            size="small"
                            round={ true }
                            disabled={!phoneVilade} onClick={() => handleVerifyCode()}
                          >
                            发送验证码
                          </Button>
                        }
                      />
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="invision-form__item login-card__helper-actions">
                  <div className="invision-form__controls invision-form__controls--text">
                    <div className="invision-form__controls-inner">
                    <p className="login-card__helper-text">未注册账号将会自动完成注册</p>
                    </div>
                  </div>
                </Form.Item>
              <Form.Item className="invision-form__item login-card__login-button">
                <div className="invision-form__controls">
                  <div className="invision-form__controls-inner">
                    <Button
                      className="invision-button invision-button--primary invision-button--round invision-button--block size-lg"
                      theme="primary"
                      size="large"
                      block={ true }
                      round={ true }
                      disabled={!(phoneVilade && verifyCode)} onClick={handleSubmit}
                    >
                      登录
                    </Button>
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="invision-form__item">
                <div className="invision-form__controls">
                  <div className="invision-form__controls-inner">
                    <Checkbox
                      className="invision-login__protocol"
                      checked={policyChecked}
                      onChange={(e) => {
                        setPolicyChecked(e.target.checked);
                      }}
                    >
                      我已阅读并同意
                      <Button
                        className="invision-button invision-button--primary invision-button--link"
                        theme="text"
                        onClick={() => {
                          handlePolicyClick('user');
                        }}
                      >
                        《用户协议》
                        <span style={{
                          display: 'inline-block',
                          width: '6px',
                          height: '6px',
                          backgroundColor: '#ff325a',
                          position: 'absolute',
                          top: 0,
                          right: '7px',
                          borderRadius: '4px',
                        }}></span>
                      </Button>
                      和
                      <Button
                        className="invision-button invision-button--primary invision-button--link"
                        theme="text"
                        onClick={() => {
                          handlePolicyClick('policy');
                        }}
                      >
                        《隐私政策》
                      </Button>
                    </Checkbox>
                  </div>
                </div>
              </Form.Item>
            </Form>
            <div>
              <img src={copyRightLogo} alt="腾讯临境·腾讯云三维建模" />
            </div>
          </div>
          <div className="login-card__materials">
            <i className="login-card__material-1"></i>
          </div>
        </div>
      </div>
    </main>
    <footer className="invision-footer" style={{ height: 'auto' }}>
      <div className="invision-copyright"><img src={ logoFooter } alt="腾讯临境" style={{ width: '264px' }} /><span style={{ float: 'right' }}>© {new Date().getFullYear()} 腾讯云</span></div>
    </footer>
  </section>
  </div>
  );
}

export default Login;
