import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import mosaicImage from '../../assets/images/mosaic.png';

Mosaics.propTypes = {
  showMosaics: PropTypes.array,
  currentEditMosaicIndex: PropTypes.number,
  wantsToDeleteMosaic: PropTypes.func,
};

function Mosaics(props) {
  return (
    <div id="viewport-mosaics" className="viewport">
      <div className="mosaic-board">
        {
          props.showMosaics.map((mosaic, index) => (
            <div key={index} onClick={() => {
            }} className="model-mosaic is-active" style={{ left: mosaic.left, top: mosaic.top, margin: `${-25 - mosaic.height / 2}px 0 0 ${-mosaic.height / 2}px`, pointerEvents: 'auto' }}>
              <div className="model-mosaic__title">马赛克{mosaic.sourceIndex}</div>
              <div className="model-mosaic__container" style={{ transform: `rotateX(${-(65 - parseFloat(mosaic.top)) / 6}deg) rotateY(${(50 - parseFloat(mosaic.left)) / 4}deg) translateZ(0)` }}>
                <div className="model-mosaic__preview" style={{ height: mosaic.height, width: mosaic.width }}>
                  <div
                    className="model-mosaic__preview-image"
                    style={{
                      backgroundImage: `url(${mosaicImage})`,
                      backgroundSize: 'cover',
                    }} ></div>
                </div>
                {
                  props.currentEditMosaicIndex === mosaic.sourceIndex
                    ? <div className="model-mosaic__delete-button" onClick={() => {
                      props.wantsToDeleteMosaic(mosaic.sourceIndex);
                    }}>
                      <i className="invision-icon-cross"></i>
                    </div>
                    : null
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default Mosaics;
