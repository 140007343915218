/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import * as useStateRef from 'react-usestateref';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Modal,
  Button,
  Checkbox,
  Breadcrumb,
  Tabs,
  Loading,
  message,
} from '@tencent/ten-design-react';
import client from '../../../utils/apiClient';
import { useUser } from '../../../context/userProvider';
import {
  getBreadCrumbList,
  debounce,
  imageProcess,
} from '../../../utils/tools';
import screenShotDefault from '../../../assets/images/screen-shot-default.png';

const PAGE_SIZE = 11;
let handleScrollListener = null;

export const PickMaterial = (props) => {
  const history = useHistory();
  const user = useUser();
  const pageNoForModel = useRef(0);
  const pageNoForPano = useRef(0);
  const currentClassId = useRef(0);
  const listTotal = useRef(0);
  const [modelCheckedAll, setModelCheckedAll] = useState(false);
  const [panoCheckedAll, setPanoCheckedAll] = useState(false);
  const [materialCandidateList, setMaterialCandidateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [breadCrumbModelList, setBreadCrumbModelList] = useState([]);
  const [breadCrumbPanoList, setBreadCrumbPanoList] = useState([]);
  const [currentTab, setCurrentTab, currentTabRef] = useStateRef('model');
  const [materialModelList, setMaterialModelList, materialModelListRef] = useStateRef([]);
  const [classModelList, setClassModelList, classModelListRef] = useStateRef([]);
  const [materialPanoList, setMaterialPanoList, materialPanoListRef] = useStateRef([]);
  const [classPanoList, setClassPanoList, classPanoListRef] = useStateRef([]);
  const [displayModelList, setDisplayModelList, displayModelListRef] = useStateRef([]);
  const [displayPanoList, setDisplayPanoList, displayPanoListRef] = useStateRef([]);

  async function describeClass(classId) {
    return client('PaaS/Material/DescribeClass', {
      body: {
        BaseClassId: classId,
        Platform: '',
        FirstLevel: true,
        Owner: { Id: user.TfUid, Type: 'PERSON' },
      } });
  }

  function handleDescribeClassResponse(res, classId) {
    if (!res.data.Data) {
      message.error('系统出错，请刷新重试');
    } else {
      if (classId === 9 || (classId !== 9 && classId !== 103 && currentTabRef.current === 'model')) {
        setBreadCrumbModelList([]);
        setBreadCrumbModelList(getBreadCrumbList(res.data.Data.PathInfoSet, 'model'));
      }
      if (classId === 103 || (classId !== 9 && classId !== 103 && currentTabRef.current === 'pano')) {
        setBreadCrumbPanoList([]);
        setBreadCrumbPanoList(getBreadCrumbList(res.data.Data.PathInfoSet, 'pano'));
      }
    }
  }

  // 获取文件夹&素材列表
  function getResourceList(classId, type) {
    currentClassId.current = classId;
    const owner = { Id: user.TfUid, Type: 'PERSON' };
    setIsLoading(true);
    client('PaaS/Material/ListMedia', { body: { ClassId: classId, Limit: PAGE_SIZE, Offset: type === 'model' ? pageNoForModel.current : pageNoForPano.current, Owner: owner, Platform: '', Sort: { Field: 'UpdateTime', Order: 'Desc' } } }).then(async (res) => {
      const classRes = await describeClass(classId);
      handleDescribeClassResponse(classRes, classId);
      handleResourceListResponse(res, type);
    });
  }

  function handleResourceListResponse(res, type) {
    if (!res.data.Data) {
      message.error('系统出错，请刷新重试');
    } else {
      setIsLoading(false);
      const { ClassTotalCount, MaterialTotalCount } = res.data.Data;
      listTotal.current = ClassTotalCount + MaterialTotalCount;
      if ((type === 'model' ? pageNoForModel.current : pageNoForPano.current) === 0 && res.data.Data.ClassInfoSet.length !== 0) {
        const classTemp = type === 'model' ? classModelListRef.current.concat() : classPanoListRef.current.concat();
        classTemp.push(...res.data.Data.ClassInfoSet);
        type === 'model' ? setClassModelList(classTemp) : setClassPanoList(classTemp);
      }
      type === 'model' ? pageNoForModel.current += PAGE_SIZE : pageNoForPano.current += PAGE_SIZE;
      const listTemp = type === 'model' ? materialModelListRef.current.concat() : materialPanoListRef.current.concat();
      listTemp.push(...res.data.Data.MaterialInfoSet);
      type === 'model' ? setMaterialModelList(listTemp) : setMaterialPanoList(listTemp);
      // listTotal.current = res.data.Data.MaterialTotalCount;
      const processQuery = type === 'model' ? classModelListRef.current.concat(materialModelListRef.current) : classPanoListRef.current.concat(materialPanoListRef.current);
      processQuery.forEach((item) => {
        let isInCandidateList = false;
        let type = '';
        if (item.ClassId) {
          isInCandidateList = false;
        } else {
          // eslint-disable-next-line max-len
          isInCandidateList = props.candidateList.findIndex(candidate => candidate.BasicInfo.MaterialId === item.BasicInfo.MaterialId) !== -1;
        }
        if (item.ClassId) {
          type = 'folder';
        } else if (item.BasicInfo.MaterialType === 'IMMERSIVE_3D_MODEL') {
          type = 'model';
        } else {
          type = 'pano';
        }
        item.isChecked = isInCandidateList;
        item.isStock = isInCandidateList;
        item.type = type;
      });
      type === 'model' ? setDisplayModelList(processQuery) : setDisplayPanoList(processQuery);
    }
  }

  function addMaterialToProject() {
    const materialIds = [];
    materialCandidateList.forEach((item) => {
      // eslint-disable-next-line max-len
      const isInCandidateList = props.candidateList.findIndex(candidate => candidate.BasicInfo.MaterialId === item.BasicInfo.MaterialId) !== -1;
      if (!isInCandidateList) materialIds.push(item.BasicInfo.MaterialId);
    });
    if (materialIds.length > 10) return message.error('项目场景不能超过 10 个');
    client('PaaS/Project/AddMaterialToProject', { body: { ProjectId: props.projectId, MaterialIds: materialIds } }).then(async (res) => {
      if (res.data.Data) {
        resetAllStatus();
        props.setPickMaterialVisible(false);
        props.reflashProjectList?.(materialIds);
        if (props.pickType === 'create') {
          // 如果是创建项目的操作路径，直接跳转编辑页
          setTimeout(() => { // 加个延时避免有些销毁还没做完
            history.push(`${window.URL_BASE_NAME}/detail?projectId=${props.projectId}`);
          }, 100);
        }
      }
    });
  }

  function jumpToTargetClass(classId) {
    if (!classId) return;
    if (currentTabRef.current === 'model') {
      pageNoForModel.current = 0;
      setClassModelList([]);
      setMaterialModelList([]);
      getResourceList(classId, 'model');
    } else {
      pageNoForPano.current = 0;
      setClassPanoList([]);
      setMaterialPanoList([]);
      getResourceList(classId, 'pano');
    }
  }

  function handleTabClick(e) {
    setCurrentTab(e);
  }

  function handleSelectModelResChange(e) {
    const tempList = displayModelListRef.current.concat();
    makeCheckedData(tempList, 'model', e);
  }

  function handleSelectPanoResChange(e) {
    const tempList = displayPanoListRef.current.concat();
    makeCheckedData(tempList, 'pano', e);
  }

  function makeCheckedData(list, type, e) {
    let tempCandidateList = materialCandidateList.concat();
    let currentItem;
    list.forEach((item) => {
      if (item.type !== 'folder' && (item.ClassId || item.BasicInfo.MaterialId) === e.target.name) {
        // eslint-disable-next-line no-param-reassign
        item.isChecked = e.target.checked;
        currentItem = item;
      }
    });
    if (e.target.checked) {
      tempCandidateList.push(currentItem);
    } else {
      type === 'model' ? setModelCheckedAll(false) : setPanoCheckedAll(false);
      // eslint-disable-next-line max-len
      tempCandidateList = tempCandidateList.filter(item => (item.ClassId || item.BasicInfo.MaterialId) !== e.target.name);
    }
    type === 'model' ? setDisplayModelList(list) : setDisplayPanoList(list);
    setMaterialCandidateList(tempCandidateList);
  }

  function handleModelCheckedAll(e) {
    setModelCheckedAll(e.target.checked);
    const tempList = displayModelListRef.current.concat();
    makeCheckedAllData(tempList, 'model', e);
  }

  function handlePanoCheckedAll(e) {
    setPanoCheckedAll(e.target.checked);
    const tempList = displayPanoListRef.current.concat();
    makeCheckedAllData(tempList, 'pano', e);
  }

  function makeCheckedAllData(list, type, e) {
    const otherCheckedList = (type === 'model' ? displayPanoListRef.current : displayModelListRef.current).filter(item => item.type !== 'folder' && item.isChecked);
    const tempList = list.concat();
    tempList.forEach((item) => {
      if (item.type !== 'folder') {
        // eslint-disable-next-line no-param-reassign
        item.isChecked = e.target.checked;
      }
    });
    type === 'model' ? setDisplayModelList(tempList) : setDisplayPanoList(tempList);
    if (type === 'model') {
      setMaterialCandidateList((e.target.checked ? list.filter(item => item.type !== 'folder') : []).concat(otherCheckedList));
    } else {
      setMaterialCandidateList(otherCheckedList.concat(e.target.checked ? list.filter(item => item.type !== 'folder') : []));
    }
  }

  function addListener() {
    handleScrollListener = debounce(300, handleScroll);
    document.querySelector('.is-in-modal').addEventListener('scroll', handleScrollListener);
  }

  function handleScroll() {
    const { scrollHeight, scrollTop, clientHeight } = document.querySelector('.is-in-modal');
    if (clientHeight + scrollTop + 5 >= scrollHeight) {
      if (currentTabRef.current === 'model') {
        if (pageNoForModel.current < listTotal.current - 1) {
          getResourceList(currentClassId.current, 'model');
        }
      } else {
        if (pageNoForPano.current < listTotal.current - 1) {
          getResourceList(currentClassId.current, 'pano');
        }
      }
    }
  }

  function resetAllStatus() {
    document.querySelector('.is-in-modal')?.removeEventListener('scroll', handleScrollListener);
    pageNoForModel.current = 0;
    pageNoForPano.current = 0;
    listTotal.current = 0;
    setMaterialModelList([]);
    setMaterialPanoList([]);
    setDisplayModelList([]);
    setDisplayPanoList([]);
    setBreadCrumbModelList([]);
    setBreadCrumbPanoList([]);
    setClassModelList([]);
    setClassPanoList([]);
  }

  useEffect(async () => {
    if (props.pickMaterialVisible) {
      props.actionType === 'project' && getResourceList(9, 'model');
      if (props.actionType === 'model') {
        setCurrentTab('pano');
        getResourceList(103, 'pano');
      }
      if (props.candidateList) setMaterialCandidateList(props.candidateList);
      addListener();
    }
  }, [props.pickMaterialVisible]);

  return (
    <Modal
      title="选择素材"
      width={1050}
      className="invision-dialog"
      visible={ props.pickMaterialVisible }
      onCancel={() => {
        resetAllStatus();
        props.setPickMaterialVisible(false);
      }}
      onClose={() => {
        resetAllStatus();
        props.setPickMaterialVisible(false);
      }}
      footer={
        <div className="invision-dialog__button-wrap">
          <Button
            className="invision-button invision-button--primary invision-button--round"
            theme="primary"
            disabled={materialCandidateList.length === 0}
            onClick={() => {
              if (props.actionType === 'model') {
                const viewInfoSet = materialCandidateList.map((item, index) => (
                  { Index: index, MaterialId: item.BasicInfo.MaterialId }));
                props.extendModelInfo(viewInfoSet, materialCandidateList);
              } else {
                addMaterialToProject();
              }
            }}
          >
            { props.pickType === 'create' ? '开始创建' : '添加' }
          </Button>
          <Button
            className="invision-button invision-button--secondary invision-button--round"
            theme="default"
            onClick={() => {
              resetAllStatus();
              props.setPickMaterialVisible(false);
            }}
          >
            取消
          </Button>
        </div>
      }
    >
      {
        props.pickMaterialVisible && props.actionType === 'project'
          ? <Tabs type="normal" defActiveKey="model" className="invision-tabs" style={{ height: 526, position: 'relative' }} onTabClick={handleTabClick}>
          <Tabs.TabPane
            icon={
              materialCandidateList.filter(item => item.type === 'model').length > 0 ? (
                <i className="invision-tabs__count">{materialCandidateList.filter(item => item.type === 'model').length}</i>
              ) : null
            }
            label="3D模型"
            key="model"
          >
            <div className="file-breadcrumb invision-justify invision-justify-content-space-between">
              <Breadcrumb>
                {
                  breadCrumbModelList.map(item => (
                    <Breadcrumb.Item key={item.key} onClick={() => {
                      jumpToTargetClass(item.key);
                    }}>{item.text}</Breadcrumb.Item>
                  ))
                }
              </Breadcrumb>
              <Checkbox
                checked={modelCheckedAll}
                onChange={handleModelCheckedAll}
              >
                全选
              </Checkbox>
            </div>
            <div className="file-cards__dialog-scroll">
              <ul className="file-cards__wrap file-cards--small is-in-modal">
                {/* is-active 可单独加这个class 显示激活态 */}
                {
                  displayModelList.length ? displayModelList.map((item, index) => (
                    <li className={cn('file-card', {
                      'is-active': item.isChecked,
                    })} key={index} onClick={() => {
                      jumpToTargetClass(item.ClassId || '');
                    }}>
                      <div className="file-card__content">
                        <div className="file-card__img-wrap">
                          {
                            item.type === 'folder'
                              ? <div className="file-card__empty-icon"></div>
                              : <img
                              className="file-card__img"
                              src={imageProcess(item.BasicInfo.PreviewUrl, '1.jpg') || screenShotDefault}
                              alt={item.Name || item.BasicInfo.Name}
                            />
                          }
                        </div>
                        {
                          item.type !== 'folder'
                            ? <Checkbox
                              className="file-card__checkbox"
                              name={item.ClassId || item.BasicInfo.MaterialId}
                              disabled={item.isStock}
                              checked={item.isChecked}
                              onChange={handleSelectModelResChange}
                            />
                            : null
                        }
                      </div>
                      <div className="file-card__info">
                        <div className="file-card__text">
                          <p
                            className="file-card__title"
                            title={item.Name || item.BasicInfo.Name}
                          >
                            {item.Name || item.BasicInfo.Name}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                    : null
                }
              </ul>
            </div>
            {
              isLoading
                ? <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
                  <Loading />
                </div>
                : null
            }
          </Tabs.TabPane>
          {/* <Tabs.TabPane
            icon={
              materialCandidateList.filter(item => item.type === 'pano').length > 0 ? (
                <i className="invision-tabs__count">
                {materialCandidateList.filter(item => item.type === 'pano').length}</i>
              ) : null
            }
            label="全景图片"
            key="pano"
          >
            <div className="file-breadcrumb invision-justify invision-justify-content-space-between">
              <Breadcrumb>
                {
                  breadCrumbPanoList.map(item => (
                    <Breadcrumb.Item key={item.key} onClick={() => {
                      jumpToTargetClass(item.key);
                    }}>{item.text}</Breadcrumb.Item>
                  ))
                }
              </Breadcrumb>
              <Checkbox
                checked={panoCheckedAll}
                onChange={handlePanoCheckedAll}
              >
                全选
              </Checkbox>
            </div>
            <ul className="file-cards__wrap file-cards--small is-in-modal">
              {
                displayPanoList.length ? displayPanoList.map((item, index) => (
                  <li className={cn('file-card', {
                    'is-active': item.isChecked,
                  })} key={index} onClick={() => {
                    jumpToTargetClass(item.ClassId || '');
                  }}>
                    <div className="file-card__content">
                      <div className="file-card__img-wrap">
                        {
                          item.type === 'folder'
                            ? <div className="file-card__empty-icon"></div>
                            : <img
                            className="file-card__img"
                            src="//static.www.tencent.com/uploads/2019/11/10/adc22315dd4c4f33277e89bbdb1cdc91.jpg"
                            alt={item.Name || item.BasicInfo.Name}
                          />
                        }
                      </div>
                      {
                        item.type !== 'folder'
                          ? <Checkbox
                          className="file-card__checkbox"
                          name={item.ClassId || item.BasicInfo.MaterialId}
                          checked={item.isChecked}
                          onChange={handleSelectPanoResChange}
                        />
                          : null
                      }
                    </div>
                    <div className="file-card__info">
                      <div className="file-card__text">
                        <p
                          className="file-card__title"
                          title={item.Name || item.BasicInfo.Name}
                        >
                          {item.Name || item.BasicInfo.Name}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
                  : null
              }
            </ul>
            {
              isLoading
                ? <div style={{ paddingTop: '60px' }}>
                  <Loading />
                </div>
                : null
            }
          </Tabs.TabPane> */}
        </Tabs>
          : null
      }
      {
        props.pickMaterialVisible && props.actionType === 'model'
          ? <Tabs type="normal" defActiveKey="pano" className="invision-tabs" style={{ height: 526, position: 'relative' }} onTabClick={handleTabClick}>
          <Tabs.TabPane
            icon={
              materialCandidateList.filter(item => item.type === 'pano').length > 0 ? (
                <i className="invision-tabs__count">{materialCandidateList.filter(item => item.type === 'pano').length}</i>
              ) : null
            }
            label="全景图片"
            key="pano"
          >
            <div className="file-breadcrumb invision-justify invision-justify-content-space-between">
              <Breadcrumb>
                {
                  breadCrumbPanoList.map(item => (
                    <Breadcrumb.Item key={item.key} onClick={() => {
                      jumpToTargetClass(item.key);
                    }}>{item.text}</Breadcrumb.Item>
                  ))
                }
              </Breadcrumb>
              <Checkbox
                checked={panoCheckedAll}
                onChange={handlePanoCheckedAll}
              >
                全选
              </Checkbox>
            </div>
            <div className="file-cards__dialog-scroll">
              <ul className="file-cards__wrap file-cards--small is-in-modal">
                {
                  displayPanoList.length ? displayPanoList.map((item, index) => (
                    <li className={cn('file-card', { 'file-folder': item.ClassId }, {
                      'is-active': item.isChecked,
                    })} key={index} onClick={() => {
                      jumpToTargetClass(item.ClassId || '');
                    }}>
                      <div className="file-card__content">
                        <div className="file-card__img-wrap">
                          {
                            item.type !== 'folder' && <img
                              className="file-card__img"
                              src={imageProcess(item.BasicInfo.PreviewUrl, '1.jpg') || screenShotDefault}
                              alt={item.Name || item.BasicInfo.Name}
                            />
                          }
                        </div>
                        {
                          item.type !== 'folder' && item.MaterialStatus.ContentReviewStatus !== 'FORBIDDEN'
                            ? <Checkbox
                            className="file-card__checkbox"
                            name={item.ClassId || item.BasicInfo.MaterialId}
                            checked={item.isChecked}
                            onChange={handleSelectPanoResChange}
                          />
                            : null
                        }
                      </div>
                      <div className="file-card__info">
                        <div className="file-card__text">
                          <p
                            className="file-card__title"
                            title={item.Name || item.BasicInfo.Name}
                          >
                            {item.Name || item.BasicInfo.Name}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))
                    : null
                }
              </ul>
            </div>
            {
              isLoading
                ? <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
                  <Loading />
                </div>
                : null
            }
          </Tabs.TabPane>
        </Tabs>
          : null
      }
    </Modal>
  );
};

PickMaterial.propTypes = {
  actionType: PropTypes.string,
  projectId: PropTypes.string,
  pickType: PropTypes.string,
  candidateList: PropTypes.array,
  pickMaterialVisible: PropTypes.bool,
  setPickMaterialVisible: PropTypes.func,
  reflashProjectList: PropTypes.func,
  extendModelInfo: PropTypes.func,
};
