/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import * as useStateRef from 'react-usestateref';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Button, Input, message, Loading, Checkbox, Breadcrumb, Popup, Upload } from '@tencent/ten-design-react';
import {
  getBreadCrumbList,
  debounce,
  imageProcess,
  formatDate,
} from '../../utils/tools';
import client from '../../utils/apiClient';
import { useUser } from '../../context/userProvider';
import uploadFile from '../../utils/uploadFile';
import { FileCardBtnGroup } from '../../components/fileCardBtns';
import { CreateProject } from './subComponent/createProject';
import { RenameProject } from './subComponent/renameProject';
import { PreviewProject } from './subComponent/previewProject';
import { PickMaterial } from './subComponent/pickMaterial';
import { ModelHeader } from './subComponent/modelHeader';
import { ModelAside } from './subComponent/modelAside';
import { ViewModel } from './subComponent/viewModel';
import { PointRelation } from './subComponent/pointRelation';

import screenShotDefault from '../../assets/images/screen-shot-default.png';
import modelIcon from '../../assets/images/svg/icon-scene-model.svg';
import panoramicIcon from '../../assets/images/svg/icon-scene-panoramic.svg';
import planetIcon from '../../assets/images/svg/icon-scene-planet.svg';

import './index.css';

const PAGE_LIMIT = 20; // TODO 多加载一页
const currentTitle = {
  project: '全景漫游项目',
  model: '3D 模型',
  panoramic: '全景图片',
};
let fileQueryLength = 0; // 全景图上传队列数量
let viewInfosForPanoData; // 建模接口的全景图数组
let taskTimer = null; // 轮询任务计时器
let taskCount = 0; // 任务数量
let isFetching = false; // 正在拉取列表数据
let handleScrollListener = null;
let currentClassId = '';

function Model() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const listTotal = useRef(0);
  const pageNo = useRef(0);
  const user = useUser();
  const [previewvisible, setPreviewvisible] = useState(false);
  const [pickMaterialVisible, setPickMaterialVisible] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [moveTarget, setMoveTarget] = useState(null);
  const [targetItem, setTargetItem] = useState(null);
  const [previewData, setPreviewData] = useState({});
  const [isLoading, setIsLoading, isLoadingRef] = useStateRef(false);
  const [loadingText, setLoadingText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  const [createType, setCreateType] = useState('project');
  const [isCreateModelBtnVisible, setIsCreateModelBtnVisible] = useState(false);
  const [showViewModel, setShowViewModel] = useState(false);
  const [clickTargetModel, setClickTargetModel] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [processModels, setProcessModels] = useState([]);
  const [showPonitRelation, setShowPonitRelation] = useState(false);
  const [materialCandidateList, setMaterialCandidateList] = useState([]);
  const [relationInfosForPanoData, setRelationInfosForPanoData] = useState([]); // 建模接口的点位关联数组
  const [currentProjectId, setCurrentProjectId] = useState(''); // cmepid_619cadf787a5db0001e9d404
  const [needToClearPointInfos, setNeedToClearPointInfos] = useState(false); // 是否需要清空关联列表
  const [displayList, setDisplayList, displayListRef] = useStateRef([]);
  const [actTab, setActTab, actTabRef] = useStateRef('project');
  const [materialList, setMaterialList, materialListRef] = useStateRef([]);
  const [classList, setClassList, classListRef] = useStateRef([]);
  const [projectList, setProjectList, projectListRef] = useStateRef([]);
  const [viewInfos, setViewInfos, viewInfosRef] = useStateRef([]);

  // 获取项目列表
  function getProjectList() {
    setIsLoading(true);
    client('PaaS/Project/DescribeProjects', {
      body: {
        Category: 'ImmersiveViewing',
        // Name,
        // Sort: {
        //   Field: 'UpdateTime',
        //   Order: 'Desc',
        // },
        Limit: PAGE_LIMIT,
        Offset: pageNo.current,
      } }).then(handleProjectListResponse);
  }

  function handleProjectListResponse(res) {
    setIsLoading(false);
    if (res.data.Data.ProjectInfoSet.length !== 0) {
      pageNo.current += PAGE_LIMIT;
      const listTemp = projectListRef.current.concat();
      listTemp.push(...res.data.Data.ProjectInfoSet);
      setProjectList(listTemp);
      listTotal.current = res.data.Data.TotalCount;
      handleDisplayList();
    }
  }

  // 全选操作
  function handleCheckedAll() {
    const selectedFilesTmp = selectedFiles.concat();
    if (selectedFilesTmp.length !== displayListRef.current.length) {
      displayListRef.current.forEach((item) => {
        const id = item.ProjectId || item.ClassId || item.BasicInfo.MaterialId;
        if (!selectedFilesTmp.includes(id)) {
          selectedFilesTmp.push(id);
        }
      });
      setSelectedFiles(selectedFilesTmp);
    } else {
      setSelectedFiles([]);
    }
  }

  // 创建项目
  function createProject(Name) {
    client('PaaS/Project/CreateProject', { body: { Category: 'IMMERSIVE_VIEWING', Name, Type: 'PERSON' } }).then(handleCreateProjectResponse);
  }

  function handleCreateProjectResponse(res) {
    if (!res.data.Data) {
      message.error(`错误信息：${res.data.Message}`);
    } else {
      setCurrentProjectId(res.data.Data.ProjectId);
      setShowCreate(false);
      if (selectedFiles.length > 0) {
        client('PaaS/Project/AddMaterialToProject', { body: { ProjectId: res.data.Data.ProjectId, MaterialIds: selectedFiles } }).then(async (res) => {
          if (res.data.Data) {
            message.success('创建成功，请前往全景漫游项目查看');
            setSelectedFiles([]);
          }
        });
      } else {
        setPickMaterialVisible(true);
        reflashProjectList();
      }
    }
  }

  // 删除项目
  function deleteProject(id) {
    client('PaaS/Project/DeleteProject', { body: { ProjectId: id } }).then(() => {
      const deletedProjectList = projectListRef.current.filter(item => item.ProjectId !== id);
      setProjectList(deletedProjectList);
      setDisplayList(deletedProjectList);
    });
  }

  // 批量删除项目
  function batchDeleteProjects() {
    for (let i = 0; i < selectedFiles.length; i++) {
      const id = selectedFiles[i];
      deleteProject(id);
    }
    setSelectedFiles([]);
  }

  // 刷新项目列表
  function reflashProjectList() {
    resetListParams();
    getProjectList();
  }

  // 刷新名字
  function reflashName(name) {
    const displayListTemp = displayList;
    displayListTemp.forEach((item) => {
      if ((item.ProjectId && targetItem.ProjectId === item.ProjectId)
        || (item.ClassId && targetItem.ClassId === item.ClassId)) item.Name = name;
      if (item.BasicInfo && targetItem?.BasicInfo?.MaterialId
        === item?.BasicInfo?.MaterialId) item.BasicInfo.Name = name;
    });
    setDisplayList(displayListTemp);
  }

  // 获取文件夹&素材列表
  async function getModelList() {
    if (isFetching) return;
    const owner = { Id: user.TfUid, Type: 'PERSON' };
    const platform = '';
    const classId = parseInt(currentClassId, 10) || (actTabRef.current === 'model' ? 9 : 103);
    setIsLoading(true);
    isFetching = true;
    const res = await client('PaaS/Material/ListMedia', { body: { ClassId: classId, Limit: PAGE_LIMIT, Offset: pageNo.current, Owner: owner, Platform: platform, Sort: { Field: 'UpdateTime', Order: 'Desc' } } });
    handleModelListResponse(res);
  }

  function handleModelListResponse(res) {
    if (!res.data.Data) {
      message.error('系统出错，请刷新重试');
    } else {
      setIsLoading(false);
      isFetching = false;
      const { ClassTotalCount, MaterialTotalCount } = res.data.Data;
      if (pageNo.current === 0 && res.data.Data.ClassInfoSet.length !== 0) {
        const classTemp = classListRef.current.concat();
        classTemp.push(...res.data.Data.ClassInfoSet);
        setClassList(classTemp);
      }
      pageNo.current += PAGE_LIMIT;
      const listTemp = materialListRef.current.concat();
      listTemp.push(...res.data.Data.MaterialInfoSet);
      setMaterialList(listTemp);
      listTotal.current = ClassTotalCount + MaterialTotalCount;
      handleDisplayList();
    }
  }

  // 复制模型
  // function copyModel(id) {
  //   client('PaaS/Material/CopyMaterial', { body: { ClassId: 9,
  // Owner: { Id: user.TfUid, Type: 'PERSON' }, SourceMaterialId: id } }).then(handleCopyResponse);
  // }

  // 创建文件夹
  function createClass(name, id) {
    client('PaaS/Material/CreateClass', { body: { ParentClassId: id || 9,
      Owner: { Id: user.TfUid, Type: 'PERSON' }, Name: name } }).then((res) => {
      handleCreateResponse(res, id);
    });
  }

  function handleCreateResponse(res, parentClassId) {
    if (!res.data.Data) {
      message.error(`错误信息：${res.data.Message}`);
    } else {
      setShowCreate(false);
      // 在当前文件夹下创建文件夹才需要重新拉取列表
      if ((currentClassId && JSON.parse(currentClassId) === parentClassId) || (parentClassId === 9 && actTabRef.current === 'model') || (parentClassId === 103 && actTabRef.current === 'panoramic')) {
        resetListParams();
        getModelList();
      }
    }
  }

  // 删除文件夹
  function deleteClass(id) {
    client('PaaS/Material/DeleteClass', { body: { ClassId: id, Owner: { Id: user.TfUid, Type: 'PERSON' } } }).then((res) => {
      if (res.data.Code === 'Success') setDisplayList(displayListRef.current.filter(item => item.ClassId !== id));
      if (res.data.Code === 'InvalidParameterValue.ClassNotEmpty') {
        const canNotBeDeleteClass = displayListRef.current.find(item => item.ClassId === id);
        message.error(`${canNotBeDeleteClass.Name}等文件夹不为空不能被删除`);
      }
    });
  }

  // 批量删除文件夹或素材
  function batchDeleteClassOrMaterial() {
    const classIds = [];
    const materialIds = [];
    selectedFiles.forEach((id) => {
      if (Number.isInteger(id)) {
        classIds.push(id);
      } else {
        materialIds.push(id);
      }
    });
    setSelectedFiles([]);
    for (let i = 0; i < classIds.length; i++) {
      const id = classIds[i];
      deleteClass(id);
    }
    for (let i = 0; i < materialIds.length; i++) {
      const id = materialIds[i];
      deleteMaterial(id);
    }
  }

  async function describeClass(classId) {
    return client('PaaS/Material/DescribeClass', {
      body: {
        BaseClassId: classId,
        Platform: '',
        FirstLevel: true,
        Owner: { Id: user.TfUid, Type: 'PERSON' },
      } });
  }

  function handleDescribeClassResponse(res) {
    if (!res.data.Data) {
      message.error('系统出错，请刷新重试');
    } else {
      // 每次生成面包屑先清空之前的
      setBreadCrumbList([]);
      setBreadCrumbList(getBreadCrumbList(res.data.Data.PathInfoSet, actTabRef.current));
    }
  }

  // 移动素材/文件夹
  function moveResource(desId) {
    if (currentClassId) {
      if (parseInt(currentClassId, 10) === desId) return message.warning('已在当前文件夹');
    } else {
      if (desId === 9 || desId === 103) return message.warning('已在当前文件夹');
    }
    let query;
    let url = '';
    if (moveTarget.ClassId) {
      url = 'PaaS/Material/ModifyClass';
      query = { ClassId: moveTarget.ClassId, ParentClassId: desId, Owner: { Id: user.TfUid, Type: 'PERSON' } };
    }
    if (moveTarget.BasicInfo) {
      url = 'PaaS/Material/ModifyMaterial';
      query = { MaterialId: moveTarget.BasicInfo.MaterialId, ClassId: desId };
    }
    client(url, { body: query }).then((res) => {
      if (res.data.Code === 'Success') {
        setDisplayList(displayListRef.current.filter((item) => {
          const moveId = moveTarget.ClassId || moveTarget.BasicInfo.MaterialId;
          return (item.ClassId || item.BasicInfo.MaterialId) !== moveId;
        }));
        setShowCreate(false);
      } else {
        message.error('无法移动到目标文件夹，请重新选择');
      }
    });
  }

  // 删除素材
  function deleteMaterial(id) {
    client('PaaS/Material/DeleteMaterial', { body: { MaterialId: id } }).then((res) => {
      if (res.data.Code === 'Success') {
        setDisplayList(displayListRef.current.filter((item) => {
          if (item.BasicInfo) {
            return item.BasicInfo.MaterialId !== id;
          }
          return true;
        }));
      }
    });
  }

  // 上传素材
  function uploadSelfMaterial(file, cb) {
    const nameReg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
    // const file = e.target.files[0];
    const name = file.name.split('.');
    name.pop();
    const nameWithoutExt = name.join('');
    if (file.type !== 'image/jpeg') return message.error('请上传 jpg 格式图片');
    if (file.size > 150 * 1024 * 1024) return message.error('请上传小于 150M 的图片');
    if (!nameReg.test(nameWithoutExt)) return message.error('图片名字只允许中英文或数字');
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const image = new Image();
      image.onload = async function () {
        const { width } = image;
        const { height } = image;
        if (width / height !== 2) return message.error('请上传宽高比例为 2:1 的图片');
        if (width * height < 1024 * 512) return message.error('请上传分辨率高于 1024 * 512 的图片');
        // 开始上传
        setLoadingText('上传中...');
        setIsLoading(true);
        const res = await uploadFile('IMAGE', { file }, { Id: user.TfUid, Type: 'PERSON' }, actTabRef.current === 'model' ? 103 : (parseInt(currentClassId, 10) || 103));
        if (res.materialId) {
          setLoadingText('');
          setIsLoading(false);
          if (cb) {
            cb(res.materialId);
          } else {
            resetListParams();
            getModelList();
          }
        }
      };
      image.src = data;
    };
    reader.readAsDataURL(file);
  }

  function preProcessUploadMaterial() {
    if (viewInfosRef.current.length === fileQueryLength) extendModelInfo(viewInfosRef.current);
  }

  function extendModelInfo(viewInfos, mCandidateList) {
    viewInfosForPanoData = viewInfos;
    setIsCreateModelBtnVisible(false); // 可以隐藏下拉按钮了
    setPickMaterialVisible(false);
    setShowCreate(true);
    setCreateType('panoModel');
    if (mCandidateList) setMaterialCandidateList(mCandidateList);
  }

  function extendPointInfo() {
    setShowPonitRelation(true);
  }

  function setRelationInfos(relationInfos) {
    setRelationInfosForPanoData(relationInfos);
    setShowPonitRelation(false);
  }

  function clearPointInfos() {
    setRelationInfosForPanoData([]);
    setNeedToClearPointInfos(true);
  }

  // 全景创建模型的名称与路径
  function processImmersive3DModel(name, id, type) {
    setShowCreate(false);
    const query = {
      Name: name || `model-${new Date().getTime()
        .toString()}`,
      CamType: type || 'XRLabPano',
      ViewInfos: viewInfosForPanoData,
      ClassId: id,
    };
    if (relationInfosForPanoData.length) {
      const viewGroupInfos = [];
      relationInfosForPanoData.forEach((item) => {
        viewGroupInfos.push(item.list);
      });
      Object.assign(query, { ViewGroupInfos: viewGroupInfos });
    }
    client('PaaS/Project/ProcessImmersive3DModel', { body: query }).then((res) => {
      if (res.data.Data.TaskId) {
        message.success('导入成功，正在合成，请稍后刷新查看');
        setViewInfos([]);
        setSelectedFiles([]);
        describeTasks();
      } else {
        message.error('全景图导入失败，请稍重试');
      }
    });
  }

  // 从全景图创建 3D 模型
  function createModelFromPano() {
    const viewInfos = selectedFiles.map((id, index) => ({ Index: index, MaterialId: id }));
    const candidatePanoList = [];
    const list = materialListRef.current.concat();
    selectedFiles.forEach((id) => {
      const target = list.find(item => item.BasicInfo.MaterialId === id);
      if (target) candidatePanoList.push(target);
    });
    extendModelInfo(viewInfos, candidatePanoList);
  }

  // 搜索
  function searchModel(val) {
    resetListParams();
    setMaterialList([]);
    setIsLoading(true);
    listTotal.current = 0;
    let query;
    let url = '';
    if (actTab === 'project') {
      url = 'PaaS/Project/SearchProject';
      query = {
        CategorySet: ['ImmersiveViewing'],
        Name: val,
        Limit: PAGE_LIMIT,
        Offset: pageNo.current,
      };
    } else {
      url = 'PaaS/Material/SearchMaterial';
      query = {
        SearchScopes: [{
          Platform: '',
          ClassId: 9,
          Owner: { Id: user.TfUid, Type: 'PERSON' },
        }],
        Text: val,
        Limit: PAGE_LIMIT,
        Offset: pageNo.current,
      };
    }
    client(url, {
      body: query }).then(handleSearchResponse);
  }

  function handleSearchResponse(res) {
    if (!res.data.Data) {
      message.error('系统出错，请刷新重试');
    } else {
      setIsLoading(false);
      if (actTab === 'project') {
        setProjectList(res.data.Data.ProjectBasicInfoSet);
      } else {
        setMaterialList(res.data.Data.MaterialInfoSet);
      }
      handleDisplayList();
    }
  }

  function handleDisplayList() {
    if (actTabRef.current === 'project') {
      setDisplayList(projectListRef.current);
    } else {
      setDisplayList(classListRef.current.concat(materialListRef.current));
    }
  }

  function addListener() {
    handleScrollListener = debounce(300, (() => {
      const { scrollHeight, scrollTop, clientHeight } = document.querySelector('.invision-container');
      if (clientHeight + scrollTop >= scrollHeight - 10) { // 留点 buffer
        if (pageNo.current <= listTotal.current - 1) {
          actTabRef.current === 'project' ? getProjectList() : getModelList();
        }
      }
    }));
    document.querySelector('.invision-container').addEventListener('scroll', handleScrollListener);
  }

  function jumpToTarget(id) {
    setSelectedFiles([]); // 选中的先取消
    if (actTab === 'project') {
      history.push(`${window.URL_BASE_NAME}/detail?projectId=${id}`);
    } else {
      if (!id || id === currentClassId) return;
      history.push(`${window.URL_BASE_NAME}/${actTab}/${id === 9 ? '' : id}`);
    }
  }

  function changeTab(value) {
    currentClassId = ''; // 避免在全景图片列表的深层文件夹内，切换到模型列表，getModelList 拿的还是原先的 classId
    setActTab(value);
    resetListParams();
    setSelectedFiles([]);
    setBreadCrumbList(getBreadCrumbList([], actTabRef.current));
    if (value === 'project') {
      getProjectList();
    } else {
      getModelList();
      if (value === 'model') describeTasks();
    }
  }

  // 处理选中素材
  function handleSelectFile(item) {
    // if (!item.BasicInfo) return; // 项目跟文件夹暂不处理
    const id = item.ProjectId || item.ClassId || item.BasicInfo.MaterialId;
    let selectedFilesTmp = selectedFiles.concat();
    if (selectedFilesTmp.includes(id)) {
      selectedFilesTmp = selectedFilesTmp.filter(item => item !== id);
    } else {
      selectedFilesTmp.push(id);
    }
    setSelectedFiles(selectedFilesTmp);
  }

  function handleRename(item) {
    setTargetItem(item);
    setShowRename(true);
  }

  function handleDelete(item) {
    if (item.ProjectId) {
      deleteProject(item.ProjectId);
    }
    if (item.ClassId) {
      deleteClass(item.ClassId);
    }
    if (item.BasicInfo) {
      deleteMaterial(item.BasicInfo.MaterialId);
    }
  }

  function comfirmCreate(name, id, type) {
    switch (createType) {
      case 'project':
        createProject(name);
        break;
      case 'folder':
        createClass(name, id);
        break;
      case 'panoModel':
        processImmersive3DModel(name, id, type);
        break;
      case 'move':
        moveResource(id);
    }
  }

  function resetListParams() {
    setMaterialList([]); // 避免编辑页后退直接累加
    setClassList([]);
    setProjectList([]);
    setDisplayList([]);
    setProcessModels([]);
    pageNo.current = 0;
    document.querySelector('.invision-container')?.removeEventListener('scroll', handleScrollListener);
    clearInterval(taskTimer);
    taskTimer = null;
  }

  function describeTasks() {
    client('PaaS/Task/DescribeTasks', {
      body: { TaskTypeSet: ['PROCESS_IMMERSIVE_3D_MODEL'],
        StatusSet: ['PROCESSING'] } }).then((res) => {
      // 任务数量有变化，拉取模型列表新数据
      if (!!res.data.Data.TaskInfoSet.length) {
        const models = res.data.Data.TaskInfoSet.map(item => (
          {
            CreateTime: item.CreateTime,
            Name: item.ProcessImmersive3DModelTask.ProcessImmersive3DModelInput.ProjectName,
            Progress: item.Progress,
            ClassId: item.ProcessImmersive3DModelTask.ProcessImmersive3DModelInput.ClassId,
          }
        ));
        const showProcessingModels = preprocessTaskModel(models);
        setProcessModels(showProcessingModels);
        if (showProcessingModels.length && !taskTimer) {
          taskPolling();
        }
      } else {
        setProcessModels([]);
        clearInterval(taskTimer);
        taskTimer = null;
      }
      if (res.data.Data.TaskInfoSet.length < taskCount) {
        resetListParams();
        getModelList();
      }
      taskCount = res.data.Data.TaskInfoSet.length;
    });
  }

  function preprocessTaskModel(models) {
    let classId = 9;
    if (currentClassId) classId = parseInt(currentClassId, 10);
    return models.filter(item => item.ClassId === classId);
  }

  function taskPolling() {
    taskTimer = setInterval(() => {
      describeTasks();
    }, 5000);
  }

  function getMediaIcon(model) {
    if (model.Immersive3DModelMaterial?.CamType === 'XRLabAerial') {
      return planetIcon;
    }
    if (model.Immersive3DModelMaterial?.CamType === 'XRLabPano') {
      return modelIcon;
    }
    return panoramicIcon;
  }

  useEffect(async () => {
    resetListParams();
    currentClassId = params.classId;
    // 设置当前 active 导航
    ['project', 'model', 'panoramic'].forEach((item) => {
      if (location.pathname.includes(item)) {
        setActTab(item);
        item === 'project' ? getProjectList() : getModelList();
        if (item === 'model') describeTasks(); // 模型列表下才需要查任务
      }
    });
    addListener();
    const res = await describeClass(parseInt(currentClassId, 10) || 9);
    handleDescribeClassResponse(res);
  }, [params.classId]);

  useEffect(() => () => {
    resetListParams();
  }, []);

  return (<>
    <section className="invision-container is-vertical invision-root">
      <ModelHeader />
      <section className="invision-container model-items">
        <ModelAside changeTab={(to) => {
          changeTab(to);
        }} />
        <main className="invision-main invision-layout__content">
          <div className="invision-justify invision-layout__content-header">
            <div className="invision-justify__col">
              <h1 className="invision-text-heading-1">{currentTitle[actTab]}</h1>
            </div>
            <div className="invision-justify__col">
              <div className="invision-searchbar">
                <Input.Search
                  className="invision-input invision-searchbar__input"
                  placeholder="请输入名称进行搜索"
                  value={searchKeyword}
                  onSearch={ (searchValue) => {
                    searchValue !== '' && searchModel(searchValue);
                  }}
                  onChange={ (e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  onClear={() => {
                    setSearchKeyword('');
                    actTab === 'project' ? getProjectList() : getModelList();
                  }} />
              </div>
            </div>
          </div>
          <div className="invision-layout__content-body">
            <div className="invision-justify invision-layout__toolbar--global">
              <div className="invision-justify__col" style={{ justifyContent: 'space-between' }}>
                {
                  actTab === 'project' && !selectedFiles.length
                    ? <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          setShowCreate(true);
                          setCreateType('project');
                        }}
                      >
                        创建项目
                      </Button>
                    </div>
                    : null
                }
                {
                  actTab === 'project' && selectedFiles.length
                    ? <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          batchDeleteProjects();
                        }}
                      >
                        删除
                      </Button>
                    </div>
                    : null
                }
                {
                  actTab === 'model' && !selectedFiles.length ? (
                    <div className="invision-button-group">
                      <Popup
                        direction="bottomcenter"
                        triggerType="manual"
                        show={isCreateModelBtnVisible}
                        content={
                          <div className="invision-poppu__btn-list">
                            <button>
                              <Upload
                                multiple={true}
                                accept="image/jpg,image/jpeg"
                                showFileList={false}
                                onChange={(files) => {
                                  fileQueryLength = files.length;
                                }}
                                request={(e) => {
                                  uploadSelfMaterial(e.file, (id) => {
                                    const viewInfosTemp = viewInfosRef.current;
                                    viewInfosTemp.push({ Index: viewInfosTemp.length, MaterialId: id });
                                    setViewInfos(viewInfosTemp);
                                    preProcessUploadMaterial();
                                  });
                                }}>
                                <span>本地上传</span>
                              </Upload>
                            </button>
                            <button onClick={() => {
                              setPickMaterialVisible(true);
                              setIsCreateModelBtnVisible(false);
                            }}>
                              <span>全景图片导入</span>
                            </button>
                          </div>
                        }
                      >
                        <Button
                          className="invision-button invision-button--primary invision-button--round"
                          theme="primary"
                          round={true}
                          onClick={() => {
                            setIsCreateModelBtnVisible(!isCreateModelBtnVisible);
                          }}
                        >
                          创建3D模型
                        </Button>
                      </Popup>
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          setShowCreate(true);
                          setCreateType('folder');
                          // setPickMaterialVisible(true);
                        }}
                      >
                        新建文件夹
                      </Button>
                      {/* <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                      >
                        采集端同步
                      </Button> */}
                    </div>
                  ) : null
                }
                {
                  actTab === 'model' && selectedFiles.length && (
                    <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--primary invision-button--round"
                        theme="primary"
                        disabled={selectedFiles.filter(id => !isNaN(id)).length !== 0}
                        round={true}
                        onClick={() => {
                          if (selectedFiles.length > 10) return message.error('项目场景不能超过 10 个');
                          setShowCreate(true);
                          setCreateType('project');
                        }}
                      >
                        创建项目
                      </Button>
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        disabled={!selectedFiles.length}
                        onClick={() => {
                          batchDeleteClassOrMaterial();
                        }}
                      >
                        删除
                      </Button>
                    </div>
                  )
                }
                {
                  actTab === 'panoramic' && !selectedFiles.length ? (
                    <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          // TODO
                        }}
                      >
                        <Upload
                          multiple={true}
                          accept="image/jpg,image/jpeg"
                          showFileList={false}
                          // onChange={(files) => {
                          //   if (files[0].status === 'done') uploadSelfMaterial(files[0].file);
                          // }}
                          request={async (e) => {
                            uploadSelfMaterial(e.file);
                          }}>
                          <span>上传全景图片</span>
                        </Upload>
                      </Button>
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          setShowCreate(true);
                          setCreateType('folder');
                          // setPickMaterialVisible(true);
                        }}
                      >
                        新建文件夹
                      </Button>
                    </div>
                  ) : null
                }
                {
                  actTab === 'panoramic' && selectedFiles.length && (
                    <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--primary invision-button--round"
                        theme="primary"
                        round={true}
                        disabled={selectedFiles.filter(id => !isNaN(id)).length !== 0}
                        onClick={createModelFromPano}
                      >
                        创建3D模型
                      </Button>
                      {/* <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                      >
                        创建项目
                      </Button> */}
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        disabled={!selectedFiles.length}
                        onClick={() => {
                          batchDeleteClassOrMaterial();
                        }}
                      >
                        删除
                      </Button>
                    </div>
                  )
                }
                {
                  displayList.length
                    ? <div className="invision-button-group">
                      <Button
                        className="invision-button invision-button--round invision-button--outline"
                        round={true}
                        onClick={() => {
                          handleCheckedAll();
                        }}
                      >
                        { displayList.length !== selectedFiles.length ? '全选' : '取消全选'}
                      </Button>
                    </div>
                    : null
                }
              </div>
            </div>
            <div className="file-breadcrumb">
              <Breadcrumb>
                {
                  breadCrumbList.map(item => (
                    <Breadcrumb.Item key={item.key} onClick={() => {
                      jumpToTarget(item.key);
                    }}>{item.text}</Breadcrumb.Item>
                  ))
                }
              </Breadcrumb>
            </div>
            <ul className="file-cards__wrap">
              {/* 生成中的模型列表单独维护 */}
              {
                actTab === 'model' && processModels.length ? processModels.map((model, index) => (
                  <li
                    key={`process-model-${index}`}
                    className={cn('file-card')}
                    >
                    <div className="file-card__content">
                      <div className="file-card__img-wrap">
                        <img
                          className="file-card__img"
                          src={screenShotDefault}
                        />
                      </div>
                    </div>
                    <div className="file-card__info">
                      <div className="file-card__text">
                        <p
                          className="file-card__title"
                          title={model.Name || '生成中...'}
                        >
                          {model.Name}
                        </p>
                        <span className="file-card__time">{`建模中 ${model.Progress}%`}</span>
                      </div>
                    </div>
                  </li>
                )) : null
              }
              {
                displayList.length ? displayList.map((model, index) => (
                  <li
                    key={`model-${index}`}
                    className={cn('file-card', { 'file-folder': (model.ProjectId && !model.CoverUrl) || model.ClassId }, { 'is-active': selectedFiles.includes(model.ProjectId || model.ClassId || model.BasicInfo.MaterialId) })}
                    >
                    <div className="file-card__content">
                      <div className="file-card__img-wrap" onClick={() => {
                        if (model.BasicInfo) {
                          if (model.BasicInfo.MaterialType === 'IMMERSIVE_3D_MODEL' || model.BasicInfo.MaterialType === 'IMAGE') {
                            setClickTargetModel(model);
                            setShowViewModel(true);
                          }
                        } else {
                          jumpToTarget(model.ProjectId || model.ClassId || '');
                        }
                      }}>
                        {
                          !((model.ProjectId && !model.CoverUrl) || model.ClassId) && <>
                              <img
                                className="file-card__img"
                                src={
                                  imageProcess(model.CoverUrl, '1.jpg')
                                  || imageProcess(model.BasicInfo.PreviewUrl, '1.jpg')
                                  || screenShotDefault}
                                alt={model.Name || model.BasicInfo.Name}
                              />
                              {
                                !model.ProjectId
                                  ? <img className="file-card__icon" src={getMediaIcon(model)} alt="" />
                                  : null
                              }
                            </>
                        }
                      </div>
                      <Checkbox
                        className="file-card__checkbox"
                        checked={selectedFiles.includes(model.ProjectId || model.ClassId || model.BasicInfo.MaterialId)}
                        onChange={() => {
                          handleSelectFile(model);
                        }}
                      />
                    </div>
                    <div className="file-card__info">
                      <div className="file-card__text">
                        <p
                          className="file-card__title"
                          title={model.Name || model.BasicInfo.Name}
                        >
                          {model.Name || model.BasicInfo.Name}
                        </p>
                        <span className="file-card__time">{formatDate(model.UpdateTime || model.BasicInfo.UpdateTime, 'yyyy年MM月dd日 hh:mm:ss')}</span>
                      </div>
                      <FileCardBtnGroup
                        navType={actTab}
                        rename={() => {
                          handleRename(model);
                        }}
                        move={() => {
                          // handleMove(model);
                          setCreateType('move');
                          setMoveTarget(model);
                          setShowCreate(true);
                        }}
                        delete={() => {
                          handleDelete(model);
                        }}
                        showPreviewProject={() => {
                          setPreviewData(model);
                          setPreviewvisible(true);
                        }}
                      />
                    </div>
                        {/*
                        <Button className="invision-button invision-button--text size-sm"
                        theme="text" size="small" onClick={() => {
                          // createProject();
                        }}>快速创建项目</Button>
                        <Button className="invision-button invision-button--text size-sm"
                        theme="text" size="small" onClick={() => {
                          // createProject();
                        }}>删除</Button> */}
                        {/* <Button className="invision-button invision-button--text size-sm"
                        theme="text" size="small"
                        onClick={() => history.push(
                          `${window.URL_BASE_NAME}/detail?projectId=${model.BasicInfo.MaterialId}`)}>
                        编辑</Button> */}
                        {/* <Button className="invision-button invision-button--text size-sm"
                        theme="text" size="small" onClick={() => {
                          copyModel(model.BasicInfo.MaterialId);
                        }}>复制</Button> */}
                  </li>
                )) : null
              }
            </ul>
            {
              isLoading
                ? <div style={{ position: 'absolute', width: '100%', top: '20px' }}>
                  <Loading text={loadingText} />
                </div>
                : null
            }
          </div>
        </main>
      </section>
    </section>
    {/* 预览项目 */}
    <PreviewProject
      previewvisible={previewvisible}
      setPreviewvisible={(isVisible) => {
        setPreviewvisible(isVisible);
      }}
      data={previewData}
    />
    {/* 选择素材 */}
    <PickMaterial
      actionType={actTab}
      projectId={currentProjectId}
      pickType="create"
      pickMaterialVisible={pickMaterialVisible}
      candidateList={[]}
      setPickMaterialVisible={(isVisible) => {
        setPickMaterialVisible(isVisible);
      }}
      extendModelInfo={extendModelInfo}
      // reflashProjectList={reflashProjectList}
    />
    {/* 创建项目/文件夹 */}
    <CreateProject
      showCreate={showCreate}
      createType={createType}
      actTab={actTab}
      comfirmCreate={comfirmCreate}
      relationInfosForPanoData={relationInfosForPanoData}
      setShowCreate={(isVisible) => {
        setShowCreate(isVisible);
        if (!isVisible) clearPointInfos();
      }}
      extendPointInfo={extendPointInfo} />
    {/* 重命名项目/文件夹/素材 */}
    <RenameProject
      showRename={showRename}
      targetItem={targetItem}
      reflashName={reflashName}
      setShowRename={(isVisible) => {
        setShowRename(isVisible);
      }} />
    {/* 快速展示模型 */}
    <ViewModel
      showViewModel={showViewModel}
      material={clickTargetModel}
      closeViewModel={() => {
        setShowViewModel(false);
      }}
      />
    <PointRelation
      showPonitRelation={showPonitRelation}
      needToClearPointInfos={needToClearPointInfos}
      materialCandidateList={materialCandidateList}
      setRelationInfos={setRelationInfos}
      setShowPoint={(isVisible) => {
        setShowPonitRelation(isVisible);
      }} />
    </>
  );
}

export default Model;
