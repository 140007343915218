export default function convertorData(infoRaw, decorationInfo, labSdkType) {
  if ('data' in infoRaw) {
    const info = infoRaw;
    if (labSdkType === 'lab-aerial') {
      // info.data.tileTemplate = 'https://pointcloud-dev-1257411467.cos.na-siliconvalley.myqcloud.com/panorama/ntl-park/{layer}/{face}/{y}/{x}.jpg';
      // info.data.faceMapping = ['r', 'l', 'u', 'd', 'f', 'b'];
      // info.data.tilingLayers = [
      //   {
      //     index: '0',
      //     faceRes: 512,
      //     tileRes: 512,
      //   },
      //   {
      //     index: '1',
      //     faceRes: 1024,
      //     tileRes: 512,
      //   },
      //   {
      //     index: '2',
      //     faceRes: 2048,
      //     tileRes: 512,
      //     leFov: 60, // 当fov小于60加载
      //   },
      //   {
      //     index: '3',
      //     faceRes: 4096,
      //     tileRes: 512,
      //     leFov: 30, // 当fov小于50加载
      //   },
      // ];
      info.data.tileTemplate = info.data.scenes[0].tileTemplate;
      // info.data.faceMapping = ['r', 'l', 'u', 'd', 'f', 'b'];
      info.data.tilingLayers = info.data.scenes[0].tilingLayers;
    } else {
      info.data.scenes[0].initLonLat = [
        decorationInfo?.initInfo?.longitude || 0,
        decorationInfo?.initInfo?.latitude || 0,
      ];
      info.data.scenes[0].initPano = decorationInfo?.initInfo ? String(decorationInfo.initInfo.panoIndex) : '0';
    }
    return {
      data: info.data,
      // configs: info.configs,
    };
  }

  const { model } = infoRaw.scenes[0];
  const { panos } = infoRaw.scenes[0];
  const { observers } = infoRaw;

  const baseURL = panos[0].cubemap.front[panos[0].cubemap.front.length - 1].split('pano')[0];

  const scene = {
    index: 'default',
    name: 'default',
    panos: panos.map((pano, index) => {
      const observer = observers[index];

      return {
        index: pano.index.toString().split('.')[1], // 旧数据有 default.0
        name: pano.index.toString(),
        scene: 'default',
        active: true,
        position: observer.position,
        visibleNodes: observer.visible_nodes.map(index => index.toString().split('.')[1]),
      };
    }),
    model: {
      index: '0',
      mesh: model.mesh,
      dollhouse: model.dollhouse,
    },
    initLonLat: [
      decorationInfo.initInfo ? decorationInfo.initInfo.longitude : -44.70456132272141,
      decorationInfo.initInfo ? decorationInfo.initInfo.latitude : -9.051093246072142],
    initPano: decorationInfo.initInfo ? String(decorationInfo.initInfo.panoIndex) : '0',
  };

  return {
    data: {
      scenes: [scene],
      tileTemplate: `${baseURL}pano/{panoIndex}_{resolution}_{face}.jpg`, // 新数据: `${baseURL}/pano/{sceneIndex}/{panoIndex}_{resolution}_{face}.jpg`
      tilingLayers: [ // 根据提供的分辨率可增减，但只能少不能多
        {
          faceRes: 512,
          tileRes: 512,
        },
        {
          faceRes: 512,
          tileRes: 1024,
        },
        {
          faceRes: 512,
          tileRes: 2048,
        },
      ],
      faceMapping: ['pos-x', 'neg-x', 'pos-y', 'neg-y', 'pos-z', 'neg-z'],
    },
  };
}
