import ImsTour from '../lib/imsTour/imsTour';
import DEFAULT_IMAGE from '../assets/images/video-thumb.png';
const { THREE } = ImsTour;

export function parseImage(url) {
  return (
    // let suffix = '?imageMogr2';
    // if (options.quality) suffix += `/quality/${options.quality}`;
    // if (options.size) suffix += `/thumbnail/${options.size}x`;
    // if (options.format) suffix += `/format/${options.format}`;
    `${url}/textureImage` // + '|watermark/2/text/MTMyNjY1MzYzNTU=/fontsize/20/fill/IzNEM0QzRA/dissolve/30/gravity/northeast/dx/50/dy/50/batch/1/degree/45'
  );
};

// 获取 url 参数
export function getQueryVariable(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
};

export function exportJson(name, data) {
  const link = document.createElement('a');
  link.download = `${name + new Date().getTime()}.json`;
  link.href = `data:text/plain,${JSON.stringify(data)}`;
  link.click();
};

export function isMobile() {
  const info = navigator.userAgent;
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad'];
  for (let i = 0; i < agents.length; i++) {
    if (info.indexOf(agents[i]) >= 0) return true;
  }
  return false;
};

export function compressBase64Image(
  base64,        // 源图片
  rate,          // 缩放比例
  callback,       // 回调
) {
  // 处理缩放，转格式
  const img = new Image();
  img.src = base64;
  img.onload = function () {
    const canvas = document.createElement('canvas');
    const w = this.width / rate;
    const h = this.height / rate;
    canvas.setAttribute('width', w);
    canvas.setAttribute('height', h);
    canvas.getContext('2d').drawImage(this, 0, 0, w, h);
    const base64 = canvas.toDataURL('image/jpeg');
    canvas.toBlob((blob) => {
      if (blob.size > 750 * 1334) {        // 如果还大，继续压缩
        compressBase64Image(base64, rate, callback);
      } else {
        callback(base64);
      }
    }, 'image/jpeg');
  };
};
// 创建数字点位画布
export function createTextTexture(obj) {
  const canvas = document.createElement('canvas');
  canvas.width = obj.width || 200;
  canvas.height = obj.height || 200;
  const ctx = canvas.getContext('2d');
  // 需要画线圈起来，才能填充
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(200, 0);
  ctx.lineTo(200, 200);
  ctx.lineTo(0, 200);
  ctx.closePath();
  ctx.fillStyle = '#2379FB';
  ctx.fill();
  ctx.font = obj.font || 'Bold 120px Arial';
  ctx.textAlign = 'center';
  ctx.fillStyle = obj.color || '#FFFFFF';
  ctx.fillText(obj.text, 100, 140); // 宽度 200，从 100 起开始画，textAlign=center 才有用

  const texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  return texture;
};
/**
* 频率控制 返回函数连续调用时，action 执行频率限定为 次 / delay
* @param delay  {number}    延迟时间，单位毫秒
* @param action {function}  请求关联函数，实际应用需要调用的函数
* @return {function}    返回客户调用函数
*/
export function throttle(delay, action) {
  let last = 0;
  return function () {
    const curr = +new Date();
    if (curr - last > delay) {
      action.apply(this, arguments);
      last = curr;
    }
  };
};

/**
* 空闲控制 返回函数连续调用时，空闲时间必须大于或等于 idle，action 才会执行
* @param idle   {number}    空闲时间，单位毫秒
* @param action {function}  请求关联函数，实际应用需要调用的函数
* @return {function}    返回客户调用函数
*/
export function debounce(idle, action) {
  let last;
  return function () {
    const ctx = this; const args = arguments;
    clearTimeout(last);
    last = setTimeout(() =>  {
      action.apply(ctx, args);
    }, idle);
  };
};

export function formatDate(datex, fmt) {
  const date = datex ? new Date(datex) : new Date();
  let str = '';
  const o = {
    'M+': date.getMonth() + 1,                 // 月份
    'd+': date.getDate(),                    // 日
    'h+': date.getHours(),                   // 小时
    'm+': date.getMinutes(),                 // 分
    's+': date.getSeconds(),                 // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(),             // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    str = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt)) {
      str = str.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
    }
  });
  return str;
};

export function dataURLtoFile(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
  return (fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }))
  );
};

export function getImageSize(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (theFile) {
      const image = new Image();
      image.src = theFile.target.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      image.onerror = () => reject('系统错误，请刷新后再试');
    };
  });
};

export function jugeUrl(URL) {
  const str = URL;
  const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
  const objExp = new RegExp(Expression);
  if (objExp.test(str) === true) {
    return true;
  }
  return false;
};

export function getBreadCrumbList(pathInfoSet, type) {
  let rootName = ''; // 全部（模型｜全景图片）
  switch (type) {
    case 'model':
      rootName = '模型';
      break;
    case 'panoramic':
      rootName = '全景图片';
      break;
    default:
      break;
  }
  if (pathInfoSet.length === 0) {
    return [
      {
        key: 9,
        text: rootName,
        val: {},
      },
    ];
  }
  const subSet = [];
  const pathInfoSetTemp = pathInfoSet.concat();
  const defaultIndex = pathInfoSetTemp.findIndex(item => item.ClassId === (type === 'model' ? 9 : 103)); // this.currentRootClassId
  if (defaultIndex < 0) return [];
  const subSetTemp = pathInfoSetTemp.splice(defaultIndex, pathInfoSetTemp.length);
  // if (this.currentRootClassId !== 9) {
  //   const defaultItem = pathInfoSetTemp.find(item => item.ClassId === 9);
  //   subSet.push({
  //     id: defaultItem?.ClassId,
  //     text: defaultItem?.Name,
  //     val: defaultItem,
  //   });
  // }
  subSetTemp.map((item) => {
    const { Name, ClassId } = item;
    subSet.push({
      key: ClassId,
      text: Name,
      val: item,
    });
  });
  subSet[0].text = rootName;
  return subSet;
};
// 实验室 SDK 数据格式化出点位信息
export function vTourDataMaker(data) {
  let panosArr = [];
  let cameraHeight = 0;
  if (data.scenes) {
    // 旧数据
    panosArr = data.scenes[0].panos;
    cameraHeight = data.configs[0].standHeight;
  } else {
    // 新数据
    panosArr = data.data.scenes[0].panos;
    cameraHeight = data.data.cameraHeight;
  }
  const observersArr = panosArr.map((pano) => {
    const [x, y, z] = pano.position;
    return {
      index: pano.index.includes('.') ? pano.index.split('.')[1] : pano.index,
      position: pano.position,
      standing_position: [x, y - cameraHeight, z],
      visible_nodes: pano.visibleNodes,
    };
  });
  return Object.assign(data, {
    observers: observersArr,
  });
};

export function imageProcess(url, processCode) {
  if (!url) return;
  if (url.includes('forbidden.svg')) return url;
  if (url.indexOf('file.myqcloud.com') !== -1) return url; // 这种链接走 cos，不处理
  const [originUrl, paramsUrl] = url.split('?');
  if (paramsUrl) {
    return `${originUrl}!${processCode}?${paramsUrl}`;
  }
  return `${originUrl}!${processCode}`;
};

export function hidePhoneNumber(phoneNumber) {
  const replaceStr = '****';
  if (phoneNumber.length < replaceStr.length) {
    return '';
  }
  const result = phoneNumber.split('');
  result.splice(3, 4, '****');
  return result.join('');
};

export function mediaUrlProcess(src, materialUrls) {
  if (jugeUrl(src)) {
    return src;
  }
  return materialUrls[src] || DEFAULT_IMAGE;
};
