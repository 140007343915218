import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@tencent/ten-design-react';

import iconWebLink from '../../../assets/images/svg/icon-web-link.svg';
import iconBackHome from '../../../assets/images/svg/icon-home.svg';
import iconHotLine from '../../../assets/images/svg/icon-hot-line.svg';
import modelIcon from '../../../assets/images/svg/icon-scene-model.svg';
import panoramicIcon from '../../../assets/images/svg/icon-scene-panoramic.svg';

export const StyleSimulator = (props) => {
  const [modifyKey, setModifyKey] = useState('');
  const [modelStyle, setModelStyle] = useState(null);

  useEffect(() => {
    setModelStyle(props.styles);
    setModifyKey(props.modifyKey);
  }, [props.modifyKey, props.styles]);

  useEffect(() => {
    props.refreshCanvas(); // 需要等 invision-mobile 渲染完了刷新 canvas
  }, []);

  return (
    <div className="invision-mobile__wrap" style={{ pointerEvents: 'none' }}>
      <div className="device-simulator__phone"></div>
      <div id="invision-mobile" className="invision-mobile">
        <div className="invision-segment invision-segment--round model-details__segment model-details__segment--mobile">
          <Button className="invision-button size-sm is-selected" size="small">
            <img width={20} height={20} src={modelIcon} alt="" />
            模型视图
          </Button>
          <Button className="invision-button size-sm" size="small">
            <img width={20} height={20} src={panoramicIcon} alt="" />
            全景视图
          </Button>
        </div>
        {
          modelStyle?.projectTitle?.turnOn
            ? <div className={`invision-mod-title__wrap ${modifyKey === 'projectTitle' ? 'invision-is-modify' : ''}`}>
              <h2 className="invision-mod-title__text">{ modelStyle?.projectTitle?.value || '项目标题'}</h2>
            </div>
            : null
        }
        <div className="invision-mod-tools__icons">
          {
            modelStyle?.backHome?.turnOn
              ? <div className={`invision-mod-tools__icon ${modifyKey === 'backHome' ? 'invision-is-modify' : ''}`}>
                <img src={iconBackHome} alt="icon" />
              </div>
              : null
          }
          {
            modelStyle?.hotLine?.turnOn
              ? <div className={`invision-mod-tools__icon ${modifyKey === 'hotLine' ? 'invision-is-modify' : ''}`}>
                <img src={iconHotLine} alt="icon" />
              </div>
              : null
          }
          {
            modelStyle?.projectUrl?.turnOn
              ? <div className={`invision-mod-tools__icon ${modifyKey === 'projectUrl' ? 'invision-is-modify' : ''}`}>
                <img src={iconWebLink} alt="icon" />
              </div>
              : null
          }
        </div>
        <div className="invision-mod-container">
          <div className="invision-mod-container__toggle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              className="design-iconfont"
            >
              <path
                d="M7.60523911,5.12886275 C7.87276941,4.97805891 8.19234081,4.95964196 8.47253058,5.07347338 L8.58993425,5.13035471 L15.4935144,9.04943993 C15.9737958,9.32209026 16.1421188,9.93247048 15.8694744,10.4127623 C15.6163046,10.8587476 15.0719354,11.0357425 14.6109199,10.8404529 L14.5061819,10.7887305 L8.09499932,7.149 L1.4911835,10.8711372 C1.04444287,11.12296 0.48668933,10.9942143 0.192161448,10.589329 L0.129036461,10.4910396 C-0.122780736,10.0442892 0.00596207463,9.48652345 0.410838508,9.19198912 L0.509125779,9.12886275 L7.60523911,5.12886275 Z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </svg>
          </div>
          <div className="invision-mod-about-us no-animation is-shown">
            <div className="invision-info__wrap">
              <div className="invision-info__left">
                <p className={`invision-info__name ${modifyKey === 'sceneTitle' ? 'invision-is-modify' : ''}`}>
                  { modelStyle?.sceneTitle?.value || '场景标题' }
                </p>
                <p className={`invision-info__desc ${modifyKey === 'sceneDesc' ? 'invision-is-modify' : ''}`}>
                  { modelStyle?.sceneDesc?.value || '场景描述' }
                </p>
              </div>
              <div className={`invision-info__more ${modifyKey === 'sceneUrl' ? 'invision-is-modify' : ''}`} onClick={() => {
                location.href = modelStyle?.sceneUrl?.value?.url || 'https://cloud.tencent.com/solution/imd';
              }}>{ modelStyle?.sceneUrl?.value?.title || '了解更多'}&gt;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StyleSimulator.propTypes = {
  modifyKey: PropTypes.string,
  styles: PropTypes.object,
  refreshCanvas: PropTypes.func,
};
