/*
 * @Descripttion:
 * @version: 0.0.1
 * @Author: maplexiao
 * @Date: 2020-11-20 17:07:09
 * @LastEditors: maplexiao
 * @LastEditTime: 2020-12-10 15:38:00
 */
import client from './apiClient';

const localStorageKey = '__linjing_token__';

function handleVerifyCodeResponse({ data }) {
  // window.localStorage.setItem(localStorageKey, token);
  return data;
}

function handleLoginResponse({ data }) {
  // window.localStorage.setItem(localStorageKey, token);
  return data;
}

function handleLogoutResponse({ data }) {
  return data;
}

function handleGetUserResponse({ data }) {
  return data;
}

async function getUser() {
  // const token = getToken();
  // if (token) {
  //   // return Promise.resolve(null);
  //   return new Promise((reslove) => {
  //     setTimeout(() => {
  //       reslove(null);
  //     }, 4000);
  //   });
  // }
  return client('SaaS/Account/DescribeAccount', { body: {} }).then(handleGetUserResponse);
}

async function getVerifyCode(phone) {
  return client('SaaS/Login/SendVerifyCode', { body: { Account: phone, AccountType: 'Phone' } }).then(handleVerifyCodeResponse);
}

async function login({ phone, verifyCode, verifyCodeId }) {
  return client('SaaS/Login/Login', { body: { PhoneInfo: { Phone: phone, VerifyCode: verifyCode, VerifyCodeId: verifyCodeId }, LoginType: 'Phone' } }).then(handleLoginResponse);
}

async function logout() {
  // window.localStorage.removeItem(localStorageKey);
  // return Promise.resolve();
  return client('SaaS/Login/Logout').then(handleLogoutResponse);
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export { getVerifyCode, login, logout, getToken, getUser };
