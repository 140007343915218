import React from 'react';
import PropTypes from 'prop-types';
import Aegis from '@tencent/aegis-web-sdk';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useUser } from './context/userProvider';
import Model from './pages/model';
import Login from './pages/login';
import Detail from './pages/detail';
import Preview from './pages/preview';
import '@tencent/ten-design-react/dist/ten.css'; // 加载 ten 样式
// import 'tdesign-react/dist/tdesign.min.css'; // 加载 tdesign 样式
import 'tdesign-react/es/style/index.css'; // 加载 tdesign 公共样式
import './index.css';
// 引入重构样式
import './lib/styles/2.7ab85f03.chunk.css';
import './lib/styles/main.9594acb3.chunk.css';

const history = createBrowserHistory();
let basename = process.env.REACT_APP_SELF_ENV ? `/${process.env.REACT_APP_SELF_ENV}/${process.env.REACT_APP_SELF_VERSION}` : '';
// 客户定制环境
if (process.env.REACT_APP_CUSTOMIZE_ENV) basename = `/${process.env.REACT_APP_CUSTOMIZE_ENV}`;
window.URL_BASE_NAME = basename;

if (!process.env.REACT_APP_SELF_ENV) { // 测试环境不上报
  // eslint-disable-next-line no-unused-vars
  const aegis = new Aegis({
    id: 'qhRTtAEQgTnqSgmJQe', // 项目ID，即上报id
    uin: '', // 用户唯一 ID（可选）
    reportApiSpeed: true, // 接口测速
    reportAssetSpeed: true, // 静态资源测速
    spa: true, // spa 页面需要开启，页面切换的时候上报pv
  });
}

function App() {
  return (
    <Router history={history}>
      <Switch>
        <AuthedRoute path={`${basename}/login`}>
          <Login />
        </AuthedRoute>
        <AuthedRoute exact path={`${basename}/project`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute path={`${basename}/project/:classId`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute exact path={`${basename}/model`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute path={`${basename}/model/:classId`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute exact path={`${basename}/panoramic`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute path={`${basename}/panoramic/:classId`}>
          <Model />
        </AuthedRoute>
        <AuthedRoute path={`${basename}/detail`}>
          <Detail />
        </AuthedRoute>
        <AuthedRoute path={`${basename}/preview`}>
          <Preview />
        </AuthedRoute>
        <Route>
          <Redirect to={`${basename}/project`} />
        </Route>
      </Switch>
    </Router>
  );
}

// 验证登录态
function AuthedRoute({ children, ...rest }) {
  const user = useUser() ;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (location.pathname.includes('login')) {
          return (user ? (
            <Redirect
                to={{
                  pathname: `${basename}/project`,
                  state: { from: location },
                }}
              />
          ) : (
            children
          ));
        }
        // if (location.pathname.includes('detail')) {
        //   document.querySelector('body').className = 'theme-dark';
        //   document.documentElement.setAttribute('theme-mode', 'dark');
        // } else {
        //   document.querySelector('body').className = '';
        //   document.documentElement.removeAttribute('theme-mode');
        // }
        return (user ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: `${basename}/login`,
                state: { from: location },
              }}
            />
        ));
      }}
    />
  );
}

AuthedRoute.propTypes = {
  children: PropTypes.object,
};

export default App;
