import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@tencent/ten-design-react';

export const TagButtonGroup = props => (
    <>
      {
        props.editType === 'init'
          ? <div className="model-details__action-buttons">
              <Button className="model-details__action-button" theme="primary" size="default" round={ true } onClick={() => {
                props.setInitInfo('screen');
              }}>设为封面</Button>
              <Button className="model-details__action-button" theme="primary" size="default" round={ true } onClick={() => {
                props.setInitInfo('point');
              }}>设为初始角</Button>
            </div>
          : null
      }
      {
        props.editType === 'mosaic'
          ? <div className="model-details__action-buttons">
              <Button className="model-details__action-button" theme="primary" size="default" round={ true } disabled={props.mosaicMode} onClick={ props.changeToAddMosaic }>{props.mosaicMode ? '添加马赛克中...' : '添加马赛克'}</Button>
              {
                props.mosaicMode
                  ? <Button className="model-details__action-button" theme="primary" size="default" round={ true } onClick={ props.updateMosaic }>更新马赛克</Button>
                  : null
              }
            </div>
          : null
      }
    </>
);

TagButtonGroup.propTypes = {
  editType: PropTypes.string,
  setInitInfo: PropTypes.func,
  mosaicMode: PropTypes.bool,
  changeToAddMosaic: PropTypes.func,
  updateMosaic: PropTypes.func,
};
