import React from 'react';
import './index.css';

function FullPageLoading() {
  return (
    <div className="loading-wrap mask">
      <div className="loading-inner">
        <div className="icon-loading">
          <i />
        </div>
        <div className="loading-hint">
          载入中⋯
        </div>
      </div>
    </div>
  );
}

export default FullPageLoading;
