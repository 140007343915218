import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Switch,
  Icon,
  message,
} from '@tencent/ten-design-react';
import uploadFile from '../../../utils/uploadFile';
import { jugeUrl } from '../../../utils/tools';
// import {
//   imageProcess,
// } from '../../../utils/tools';

import iconWebLink from '../../../assets/images/svg/icon-web-link.svg';
// import iconBackHome from '../../../assets/images/svg/icon-home.svg';
import iconHotLine from '../../../assets/images/svg/icon-hot-line.svg';
// import sceneModelIcon from '../../../assets/images/svg/icon-model.svg';
// import defaultCover from '../../../assets/images/screen-shot-default.png';

const defaultLodingPCImage = 'https://cvr.tencentcs.com/vr-common/imsTour/loading-bg-pc.jpg';
const defaultLodingMobileImage = 'https://cvr.tencentcs.com/vr-common/imsTour/loading-bg.jpg';
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
let DECORATION_INFO = {};
let ORIGINAL_DECORATION_INFO = {};
let MODEL_DECORATION_INFO = {};
let isComposition = false;

export const StyleAside = (props) => {
  const [modifyKey, setModifyKey] = useState('landPoster');
  const [customizeCover, setCustomizeCover] = useState(props.decorationInfo?.customizeCover || false);
  const [viewPwd, setViewPwd] = useState(props.decorationInfo?.viewPwd || false);
  const [viewPwdCode, setViewPwdCode] = useState(props.decorationInfo?.viewPwdCode || '');
  const [lodingPCImage, setLodingPCImage] = useState('');
  const [lodingMobileImage, setLodingMobileImage] = useState('');
  const [projectTitle, setProjectTitle] = useState({ turnOn: false, value: '', toAll: false });
  const [sceneTitle, setSceneTitle] = useState({ turnOn: false, value: '', toAll: false });
  const [sceneDesc, setSceneDesc] = useState({ turnOn: false, value: '', toAll: false });
  const [sceneUrl, setSceneUrl] = useState({ turnOn: false, value: { title: '', url: '' }, toAll: false });
  const [backHome, setBackHome] = useState({ turnOn: false, value: '', toAll: false });
  const [projectUrl, setProjectUrl] = useState({ turnOn: false, value: '', toAll: false });
  const [hotLine, setHotLine] = useState({ turnOn: false, value: '', toAll: false });

  // 自定义封面开关
  function handleCustomizeCoverChange(e) {
    setCustomizeCover(e.target.checked);
    DECORATION_INFO.customizeCover = e.target.checked;
  }

  // 访问密码开关
  function handleViewPwdChange(e) {
    setViewPwd(e.target.checked);
    DECORATION_INFO.viewPwd = e.target.checked;
  }

  // 设置访问密码
  function  handleViewPwdCode(e) {
    const value = e.target.value.length > 14 ? e.target.value.slice(0, 14) : e.target.value;
    setViewPwdCode(value);
    DECORATION_INFO.viewPwdCode = value;
  }

  // 选中设置项
  function handleItemChoose(key) {
    setModifyKey(key);
    props.updateSimulator(key, MODEL_DECORATION_INFO.style);
  }

  // 勾选设置项
  function handleItemCheck(e, key) {
    switch (key) {
      case 'projectTitle':
        setProjectTitle({ turnOn: e.target.checked, value: projectTitle.value, toAll: projectTitle.toAll });
        MODEL_DECORATION_INFO.style.projectTitle = Object.assign({}, {
          turnOn: e.target.checked,
          value: projectTitle.value,
          toAll: projectTitle.toAll,
        });
        break;
      case 'sceneTitle':
        setSceneTitle({ turnOn: e.target.checked, value: sceneTitle.value, toAll: sceneTitle.toAll });
        MODEL_DECORATION_INFO.style.sceneTitle = Object.assign({}, {
          turnOn: e.target.checked,
          value: sceneTitle.value,
          toAll: sceneTitle.toAll,
        });
        break;
      case 'sceneDesc':
        setSceneDesc({ turnOn: e.target.checked, value: sceneDesc.value, toAll: sceneDesc.toAll });
        MODEL_DECORATION_INFO.style.sceneDesc = Object.assign({}, {
          turnOn: e.target.checked,
          value: sceneDesc.value,
          toAll: sceneDesc.toAll,
        });
        break;
      case 'sceneUrl':
        setSceneUrl({ turnOn: e.target.checked, value: sceneUrl.value, toAll: sceneUrl.toAll });
        MODEL_DECORATION_INFO.style.sceneUrl = Object.assign({}, {
          turnOn: e.target.checked,
          value: sceneUrl.value,
          toAll: sceneUrl.toAll,
        });
        break;
      case 'backHome':
        setBackHome({ turnOn: e.target.checked, value: backHome.value, toAll: backHome.toAll });
        MODEL_DECORATION_INFO.style.backHome = Object.assign({}, {
          turnOn: e.target.checked,
          value: backHome.value,
          toAll: backHome.toAll,
        });
        break;
      case 'projectUrl':
        setProjectUrl({ turnOn: e.target.checked, value: projectUrl.value, toAll: projectUrl.toAll });
        MODEL_DECORATION_INFO.style.projectUrl = Object.assign({}, {
          turnOn: e.target.checked,
          value: projectUrl.value,
          toAll: projectUrl.toAll,
        });
        break;
      case 'hotLine':
        setHotLine({ turnOn: e.target.checked, value: hotLine.value, toAll: hotLine.toAll });
        MODEL_DECORATION_INFO.style.hotLine = Object.assign({}, {
          turnOn: e.target.checked,
          value: hotLine.value,
          toAll: hotLine.toAll,
        });
        break;
      default:
        break;
    }
    props.updateSimulator(key, MODEL_DECORATION_INFO.style);
  }

  // 文本相关更新
  function handleTextChange(e, key, innerKey) {
    switch (key) {
      case 'projectTitle':
      {
        const textContent = isComposition ? e.target.value : e.target.value.slice(0, 10);
        setProjectTitle({ turnOn: projectTitle.turnOn, value: textContent, toAll: projectTitle.toAll });
        MODEL_DECORATION_INFO.style.projectTitle = Object.assign({}, {
          turnOn: projectTitle.turnOn,
          value: textContent,
          toAll: projectTitle.toAll,
        });
        break;
      }
      case 'sceneTitle':
      {
        const textContent = isComposition ? e.target.value : e.target.value.slice(0, 10);
        setSceneTitle({ turnOn: sceneTitle.turnOn, value: textContent, toAll: sceneTitle.toAll });
        MODEL_DECORATION_INFO.style.sceneTitle = Object.assign({}, {
          turnOn: sceneTitle.turnOn,
          value: e.target.value,
          toAll: sceneTitle.toAll,
        });
        break;
      }
      case 'sceneDesc':
      {
        const textContent = isComposition ? e.target.value : e.target.value.slice(0, 40);
        setSceneDesc({ turnOn: sceneDesc.turnOn, value: textContent, toAll: sceneDesc.toAll });
        MODEL_DECORATION_INFO.style.sceneDesc = Object.assign({}, {
          turnOn: sceneDesc.turnOn,
          value: e.target.value,
          toAll: sceneDesc.toAll,
        });
        break;
      }
      case 'sceneUrl':
      {
        const newVal = { title: sceneUrl.value.title || '', url: sceneUrl.value.url || '' };
        if (innerKey === 'title') {
          const textContent = isComposition ? e.target.value : e.target.value.slice(0, 40);
          newVal.title = textContent;
        } else {
          newVal.url = e.target.value;
        }
        setSceneUrl({ turnOn: sceneUrl.turnOn, value: newVal, toAll: sceneUrl.toAll });
        MODEL_DECORATION_INFO.style.sceneUrl = Object.assign({}, {
          turnOn: sceneUrl.turnOn,
          value: newVal,
          toAll: sceneUrl.toAll,
        });
        break;
      }
      case 'projectUrl':
        setProjectUrl({ turnOn: projectUrl.turnOn, value: e.target.value, toAll: projectUrl.toAll });
        MODEL_DECORATION_INFO.style.projectUrl = Object.assign({}, {
          turnOn: projectUrl.turnOn,
          value: e.target.value,
          toAll: projectUrl.toAll,
        });
        break;
      case 'hotLine':
        setHotLine({ turnOn: hotLine.turnOn, value: e.target.value, toAll: hotLine.toAll });
        MODEL_DECORATION_INFO.style.hotLine = Object.assign({}, {
          turnOn: hotLine.turnOn,
          value: e.target.value,
          toAll: hotLine.toAll,
        });
        break;
      default:
        break;
    }
    props.updateSimulator(modifyKey, MODEL_DECORATION_INFO.style);
  }

  // 处理拼音输入的监听
  function handleComposition(e, key) {
    if (e.type === 'compositionend') {
      isComposition = false;
      if (!isComposition && isChrome) {
        handleTextChange(e, key);
      }
    } else {
      isComposition = true;
    }
  }

  function handleApplyToAllChange(e, key) {
    switch (key) {
      case 'projectTitle':
        setProjectTitle({ turnOn: projectTitle.turnOn, value: projectTitle.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.projectTitle = Object.assign({}, {
          turnOn: projectTitle.turnOn,
          value: projectTitle.value,
          toAll: e.target.checked,
        });
        break;
      case 'sceneTitle':
        setSceneTitle({ turnOn: sceneTitle.turnOn, value: sceneTitle.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.sceneTitle = Object.assign({}, {
          turnOn: sceneTitle.turnOn,
          value: sceneTitle.value,
          toAll: e.target.checked,
        });
        break;
      case 'sceneDesc':
        setSceneDesc({ turnOn: sceneDesc.turnOn, value: sceneDesc.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.sceneDesc = Object.assign({}, {
          turnOn: sceneDesc.turnOn,
          value: sceneDesc.value,
          toAll: e.target.checked,
        });
        break;
      case 'sceneUrl':
        setSceneUrl({ turnOn: sceneUrl.turnOn, value: sceneUrl.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.sceneUrl = Object.assign({}, {
          turnOn: sceneUrl.turnOn,
          value: sceneUrl.value,
          toAll: e.target.checked,
        });
        break;
      case 'backHome':
        setBackHome({ turnOn: backHome.turnOn, value: backHome.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.backHome = Object.assign({}, {
          turnOn: backHome.turnOn,
          value: backHome.value,
          toAll: e.target.checked,
        });
        break;
      case 'projectUrl':
        setProjectUrl({ turnOn: projectUrl.turnOn, value: projectUrl.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.projectUrl = Object.assign({}, {
          turnOn: projectUrl.turnOn,
          value: projectUrl.value,
          toAll: e.target.checked,
        });
        break;
      case 'hotLine':
        setHotLine({ turnOn: hotLine.turnOn, value: hotLine.value, toAll: e.target.checked });
        MODEL_DECORATION_INFO.style.hotLine = Object.assign({}, {
          turnOn: hotLine.turnOn,
          value: hotLine.value,
          toAll: e.target.checked,
        });
        break;
      default:
        break;
    }
    props.updateSimulator(key, MODEL_DECORATION_INFO.style);
  }

  // 预处理，字段校验&应用到全部
  function preUpdateToProject(callback) {
    const parsmKeys = Object.keys(MODEL_DECORATION_INFO.style);
    for (let i = 0; i < parsmKeys.length; i++) {
      const item = MODEL_DECORATION_INFO.style[parsmKeys[i]];
      if (item.turnOn) {
        const { result, msg } = validateItem(parsmKeys[i], item);
        if (!result) {
          message.error(msg);
          return;
        }
      }
    }
    if (DECORATION_INFO.viewPwd) {
      const { result, msg } = validateItem('viewCode', DECORATION_INFO.viewPwdCode || '');
      if (!result) {
        message.error(msg);
        return;
      }
    }
    parsmKeys.forEach((key) => {
      const item = MODEL_DECORATION_INFO.style[key];
      if (item.toAll) {
        props.materialIdsInProject.forEach((id) => {
          if (!DECORATION_INFO[id]) DECORATION_INFO[id] = { style: {} };
          if (!DECORATION_INFO[id].style) DECORATION_INFO[id] = Object.assign(DECORATION_INFO[id], { style: {} });
          const { style } = DECORATION_INFO[id];
          style[key] = item;
        });
      } else {
        DECORATION_INFO[props.materialId] = Object.assign({}, MODEL_DECORATION_INFO);
      }
    });
    callback();
  }

  // 获取“更新”按钮状态
  function getUpdateButtonStatus() {
    if (customizeCover && !lodingPCImage && !lodingMobileImage) {
      return true;
    }
    if (projectTitle.turnOn && !projectTitle.value) {
      return true;
    }
    if (JSON.stringify(ORIGINAL_DECORATION_INFO) !== JSON.stringify(DECORATION_INFO)) {
      return true;
    }
    return false;
  }

  // 校验字段
  function validateItem(key, item) {
    if (key === 'projectUrl') return { result: jugeUrl(item.value), msg: '链接格式不正确，请重新输入' };
    if (key === 'sceneUrl') return { result: jugeUrl(item.value.url), msg: '链接格式不正确，请重新输入' };
    if (key === 'viewCode') {
      const reg = new RegExp(/^[A-Za-z0-9]{6,14}$/);
      return { result: reg.test(item), msg: '访问密码格式不正确，请重新输入' };
    }
    return { result: true, msg: '' };
  }

  // 校验图片
  function validateFile(file) {
    if (file.size / 1024 > 200) {
      message.warning('图片大小不超过 200 K', 1000);
      return false;
    }
    return true;
  }

  // 初始化面板数据
  function initSettingData() {
    const styles = MODEL_DECORATION_INFO.style;
    // if (DECORATION_INFO.customizeCover) setCustomizeCover(DECORATION_INFO.customizeCover);
    if (DECORATION_INFO.loadingPCImage) setLodingPCImage(DECORATION_INFO.loadingPCImage);
    if (DECORATION_INFO.loadingMobileImage) setLodingMobileImage(DECORATION_INFO.loadingMobileImage);
    if (styles.projectTitle) setProjectTitle(styles.projectTitle);
    if (styles.sceneTitle) setSceneTitle(styles.sceneTitle);
    if (styles.sceneDesc) setSceneDesc(styles.sceneDesc);
    if (styles.sceneUrl) setSceneUrl(styles.sceneUrl);
    if (styles.backHome) setBackHome(styles.backHome);
    if (styles.hotLine) setHotLine(styles.hotLine);
    if (styles.projectUrl) setProjectUrl(styles.projectUrl);
  }

  // 组装 decoration 数据
  function processDecorationInfo(key, value) {
    switch (key) {
      case 'loadingPCImage':
        DECORATION_INFO.loadingPCImage = value;
        break;
      case 'loadingMobileImage':
        DECORATION_INFO.loadingMobileImage = value;
        break;
      default:
        break;
    }
  }

  // 上传图片
  async function handleLoadingImageUpload(e, type) {
    const imageFile = e.target.files[0];
    if (imageFile) {
      if (validateFile(imageFile)) {
        const { url } = await uploadFile('IMAGE', { file: imageFile }, props.owner);
        if (type === 'pc') {
          setLodingPCImage(url);
          processDecorationInfo('loadingPCImage', url);
        } else {
          setLodingMobileImage(url);
          processDecorationInfo('loadingMobileImage', url);
        }
      }
    } else {
      message.error('图片加载失败，请重新选择', 1000);
    }
  }

  // 更新 decoration
  async function handleUpdateProject() {
    // 提交前与处理
    preUpdateToProject(() => {
      props.updateProjectDecoration(DECORATION_INFO);
    });
  }

  useEffect(() => {
    DECORATION_INFO = props.decorationInfo;
    MODEL_DECORATION_INFO = DECORATION_INFO[props.materialId] || {};
    if (!MODEL_DECORATION_INFO.style) MODEL_DECORATION_INFO.style = {}; // 大部分项目不会有 style 这个字段，需先初始化
    initSettingData();
  }, [props.decorationInfo, props.materialId]);

  useEffect(() => {
    ORIGINAL_DECORATION_INFO = props.decorationInfo;
  }, []);

  return (
    <aside className="model-details__inspector model-details__style">
      <div className="invision-config__menu">
        <p className="invision-config__menu-title">更改模版</p>
        <button
          onClick={() => handleItemChoose('landPoster')}
          className={`invision-config__menu-item ${
            modifyKey === 'landPoster' ? 'invision-is-active' : ''
          }`}
        >
          开屏封面
        </button>
        <hr />
        <p className="invision-config__menu-title">选择样式元素</p>
        <p className="invision-config__menu-title--sm">文本元素</p>
        <button
          onClick={() => handleItemChoose('projectTitle')}
          className={`invision-config__menu-item ${
            modifyKey === 'projectTitle' ? 'invision-is-active' : ''
          }`}
        >
          <Checkbox className="invision-checkbox" checked={projectTitle.turnOn} onChange={(e) => {
            handleItemCheck(e, 'projectTitle');
          }}>
            项目标题
          </Checkbox>
        </button>
        <button
          onClick={() => handleItemChoose('sceneTitle')}
          className={`invision-config__menu-item ${
            modifyKey === 'sceneTitle' ? 'invision-is-active' : ''
          }`}
        >
          <Checkbox className="invision-checkbox" checked={sceneTitle.turnOn} onChange={(e) => {
            handleItemCheck(e, 'sceneTitle');
          }}>场景标题</Checkbox>
        </button>
        <button
          onClick={() => handleItemChoose('sceneDesc')}
          className={`invision-config__menu-item ${
            modifyKey === 'sceneDesc' ? 'invision-is-active' : ''
          }`}
        >
          <Checkbox className="invision-checkbox" checked={sceneDesc.turnOn} onChange={(e) => {
            handleItemCheck(e, 'sceneDesc');
          }}>场景描述</Checkbox>
        </button>
        <button
          onClick={() => handleItemChoose('sceneUrl')}
          className={`invision-config__menu-item ${
            modifyKey === 'sceneUrl' ? 'invision-is-active' : ''
          }`}
        >
          <Checkbox className="invision-checkbox" checked={sceneUrl.turnOn} onChange={(e) => {
            handleItemCheck(e, 'sceneUrl');
          }}>场景链接</Checkbox>
        </button>
        <p className="invision-config__menu-title--sm">侧边工具栏</p>
        <div className="invision-config__menu-config">
          <button
            onClick={() => handleItemChoose('backHome')}
            className={`invision-config__menu-item ${
              modifyKey === 'backHome' ? 'invision-is-active' : ''
            }`}
          >
            <Checkbox className="invision-checkbox" checked={backHome.turnOn} onChange={(e) => {
              handleItemCheck(e, 'backHome');
            }}>
              返回主场景
            </Checkbox>
          </button>
          <button
            onClick={() => handleItemChoose('hotLine')}
            className={`invision-config__menu-item ${
              modifyKey === 'hotLine' ? 'invision-is-active' : ''
            }`}
          >
            <Checkbox className="invision-checkbox" checked={hotLine.turnOn} onChange={(e) => {
              handleItemCheck(e, 'hotLine');
            }}>热线电话</Checkbox>
          </button>
          <button
            onClick={() => handleItemChoose('projectUrl')}
            className={`invision-config__menu-item ${
              modifyKey === 'projectUrl' ? 'invision-is-active' : ''
            }`}
          >
            <Checkbox className="invision-checkbox" checked={projectUrl.turnOn} onChange={(e) => {
              handleItemCheck(e, 'projectUrl');
            }}>网页链接</Checkbox>
          </button>
        </div>
        <div className="invision-config__menu-title--sm">
          <Button
            className="invision-button invision-button--primary invision-button--round size-sm"
            theme="primary"
            size="small"
            round={true}
            disabled={getUpdateButtonStatus()}
            onClick={handleUpdateProject}
          >
            更新
          </Button>
        </div>
      </div>
      <div className="invision-config">
        {/* 开屏封面 设置内容 */}
        {modifyKey === 'landPoster' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">开屏封面设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>自定义封面</p>
                  <Switch className="invision-switch" defaultChecked={customizeCover} onChange={handleCustomizeCoverChange} />
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">PC端封面文件（1920*1080）</p>
                <p className="invision-config__hint">支持jpg/png格式，200KB以内</p>
                <div className="invision-config__upload">
                  <img
                    className="invision-config__upload-img invision-config__upload-img--cover"
                    src={lodingPCImage || defaultLodingPCImage}
                    alt=""
                  />
                  <Button className="invision-config__upload-btn" disabled={!customizeCover}>
                    <Icon type="refresh" />
                    重新上传
                    <input type="file" title="封面上传" onChange={(e) => {
                      handleLoadingImageUpload(e, 'pc');
                    }} />
                  </Button>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">移动端封面文件（375*1334）</p>
                <p className="invision-config__hint">支持jpg/png格式，200KB以内</p>
                <div className="invision-config__upload">
                  <img
                    className="invision-config__upload-img"
                    src={lodingMobileImage || defaultLodingMobileImage}
                    alt=""
                  />
                  <Button className="invision-config__upload-btn" disabled={!customizeCover}>
                    <Icon type="refresh" />
                    重新上传
                    <input type="file" title="封面上传" onChange={(e) => {
                      handleLoadingImageUpload(e, 'mobile');
                    }} />
                  </Button>
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>访问密码</p>
                  <Switch className="invision-switch" defaultChecked={viewPwd} onChange={handleViewPwdChange} />
                </div>
              </div>
              {
                viewPwd
                  ? <div className="invision-config__item">
                    {/* <p className="invision-config__text">标题文本</p> */}
                    <p className="invision-config__hint">只支持6-14位阿拉伯数字和英文字母，不支持空格和其他字符</p>
                    <div className="invision-config__input">
                      <input
                        maxLength={14}
                        placeholder="请输入密码"
                        value={viewPwdCode}
                        onChange={(e) => {
                          handleViewPwdCode(e);
                        }}
                      />
                      {/* <span className="invision-config__input-limit">{projectTitle.value.length}/10</span> */}
                    </div>
                  </div>
                  : null
              }
            </div>
          </>
        )}
        {/* 项目标题 设置内容 */}
        {modifyKey === 'projectTitle' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">项目标题设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">标题文本</p>
                <div className="invision-config__input">
                  <input
                    maxLength={10}
                    placeholder="请输入标题"
                    value={projectTitle.value}
                    onChange={(e) => {
                      handleTextChange(e, 'projectTitle');
                    }}
                    onCompositionStart={(e) => {
                      handleComposition(e, 'projectTitle');
                    }}
                    onCompositionEnd={(e) => {
                      handleComposition(e, 'projectTitle');
                    }}
                  />
                  <span className="invision-config__input-limit">{projectTitle.value.length}/10</span>
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={projectTitle.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'projectTitle');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 场景标题 设置内容 */}
        {modifyKey === 'sceneTitle' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">场景标题设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">标题文本</p>
                <div className="invision-config__input">{/* invision-is-error */}
                  <input
                    maxLength={10}
                    placeholder="请输入标题"
                    value={sceneTitle.value}
                    onChange={(e) => {
                      handleTextChange(e, 'sceneTitle');
                    }}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  />
                  <span className="invision-config__input-limit">{sceneTitle.value.length}/10</span>
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={sceneTitle.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'sceneTitle');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 场景描述 设置内容 */}
        {modifyKey === 'sceneDesc' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">场景描述设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">描述文本</p>
                <div className="invision-config__input">
                  <textarea
                    maxLength={40}
                    placeholder="请输入描述"
                    value={sceneDesc.value}
                    onChange={(e) => {
                      handleTextChange(e, 'sceneDesc');
                    }}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  />
                  <span className="invision-config__input-limit">{sceneDesc.value.length}/40</span>
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={sceneDesc.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'sceneDesc');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 场景链接 设置内容 */}
        {modifyKey === 'sceneUrl' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">场景链接设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">链接标题</p>
                <div className="invision-config__input">
                  <input
                    maxLength={10}
                    placeholder="请输入链接标题"
                    value={sceneUrl.value.title}
                    onChange={(e) => {
                      handleTextChange(e, 'sceneUrl', 'title');
                    }}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  />
                  <span className="invision-config__input-limit">{sceneUrl.value.title?.length || 0}/10</span>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">链接地址</p>
                <div className="invision-config__input">
                  <textarea
                    placeholder="请输入链接地址"
                    value={sceneUrl.value.url}
                    onChange={(e) => {
                      handleTextChange(e, 'sceneUrl', 'url');
                    }}
                  />
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={sceneUrl.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'sceneUrl');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 返回主场景 设置内容 */}
        {modifyKey === 'backHome' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">返回主场景设置</p>
            </div>
            <div className="invision-config__body">
              {/* <div className="invision-config__item">
                <p className="invision-config__text">工具图标</p>
                <div className="scene-jump__config-icon-wrap">
                  <div className="scene-jump__config-icon">
                    <img src={iconBackHome} alt="icon" />
                  </div>
                </div>
              </div> */}
              {/* <div className="invision-config__item">
                <p className="invision-config__text">链接地址</p>
                <Input.Textarea
                  className="invision-textarea--config"
                  placeholder="请输入链接地址"
                  autosize={false}
                />
              </div> */}
              {/* <div className="invision-config__item invision-flex-auto">
                <p className="invision-config__text">选择主场景</p>
                <div className="invision-config__scene-cards">
                  {props.materialsInProject.map((scene, index) => (
                    <div key={`scene-${index}`} className="scene-card__item">
                      <div className="scene-card__img-wrap">
                        <img className="scene-card__img" src={scene.BasicInfo.PreviewUrl || defaultCover} alt="" />
                        <img className="scene-card__icon" src={sceneModelIcon} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={backHome.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'backHome');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 热线电话 设置内容 */}
        {modifyKey === 'hotLine' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">热线电话设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">工具图标</p>
                <div className="scene-jump__config-icon-wrap">
                  <div className="scene-jump__config-icon">
                    <img src={iconHotLine} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">电话号码</p>
                <div className="invision-config__input">
                  <input
                    placeholder="请输入电话号码"
                    value={hotLine.value}
                    onChange={(e) => {
                      handleTextChange(e, 'hotLine');
                    }}
                  />
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={hotLine.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'hotLine');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* 网页链接 设置内容 */}
        {modifyKey === 'projectUrl' && (
          <>
            <div className="invision-config__header">
              <p className="invision-config__title">网页链接设置</p>
            </div>
            <div className="invision-config__body">
              <div className="invision-config__item">
                <p className="invision-config__text">工具图标</p>
                <div className="scene-jump__config-icon-wrap">
                  <div className="scene-jump__config-icon">
                    <img src={iconWebLink} alt="icon" />
                  </div>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">链接地址</p>
                <div className="invision-config__input">
                  <textarea
                    placeholder="请输入链接地址"
                    value={projectUrl.value}
                    onChange={(e) => {
                      handleTextChange(e, 'projectUrl');
                    }}
                  />
                </div>
              </div>
              <div className="invision-config__item">
                <div className="invision-config__switch">
                  <p>应用于全部场景</p>
                  <Switch className="invision-switch" defaultChecked={projectUrl.toAll} onChange={(e) => {
                    handleApplyToAllChange(e, 'projectUrl');
                  }} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </aside>
  );
};

StyleAside.propTypes = {
  owner: PropTypes.object,
  decorationInfo: PropTypes.object,
  materialId: PropTypes.string,
  materialIdsInProject: PropTypes.array,
  materialsInProject: PropTypes.array,
  updateSimulator: PropTypes.func,
  updateProjectDecoration: PropTypes.func,
};
