import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {
  Modal,
  Button,
  Form,
  message,
} from '@tencent/ten-design-react';
import client from '../../../utils/apiClient';
import {
  formatDate,
} from '../../../utils/tools';

import screenShotDefault from '../../../assets/images/screen-shot-default.png';

export const PreviewProject = (props) => {
  const [decorationInfo, setDecorationInfo] = useState(null);
  const [h5Link, setH5Link] = useState('');

  function formatPreUrl(info) {
    let url = '';
    if (info?.PreviewUrl) {
      const preUrl = info.PreviewUrl;
      url = preUrl.includes('thumbnail_resize') ? `${preUrl}.large.png` : preUrl;
    } else {
      url = screenShotDefault;
    }
    return url;
  }

  function getMaterialFromProject() {
    return client('PaaS/Project/DescribeProjectMaterials', { body: { ProjectId: props.data.ProjectId } });
  }

  function describeMaterials(materialId) {
    return client('PaaS/Material/DescribeMaterials', { body: { MaterialIds: [materialId] } });
  }

  // 获取分享 ID
  async function getShareToken() {
    const res = await client('PaaS/MaterialPolicy/ShareProjectResource', { body: { ProjectId: props.data.ProjectId, Permissions: ['R'] } });
    if (res.data.Data) setH5Link(`https://cvr.tencentcs.com/${process.env.REACT_APP_RUNNING_ENV === 'production' ? 'invision' : 'invision-dev'}/index.html?projectId=${props.data.ProjectId}&shareId=${res.data.Data.ShareId}`);
  }

  // async function getBatchShareLinks(materialIds) {
  //   const links = [];
  //   for (let i = 0; i < materialIds.length; i++) {
  //     const res = await getShareToken(materialIds[i]);
  //     if (res.data.Data) links.push(`https://cvr.tencentcs.com/${process.env.REACT_APP_RUNNING_ENV === 'production' ? 'invision' : 'invision-dev'}/index.html?projectId=${materialIds[i] || ''}&shareId=${res.data.Data.ShareId}`);
  //   }
  //   setH5Link(links);
  // }

  useEffect(async () => {
    if (!props.previewvisible) return;
    const res = await getMaterialFromProject();
    const materialIds = res.data.Data.MaterialIdSet;
    await getShareToken();
    if (materialIds.length) {
      const resMaterial = await describeMaterials(materialIds[0]);
      const defaultMaterial = resMaterial.data.Data.MaterialInfoSet[0];
      if (!defaultMaterial) return;
      const decoration = defaultMaterial.Immersive3DModelMaterial.Decoration;
      setDecorationInfo(Object.assign({}, JSON.parse(decoration === '' ? '{}' : decoration)));
    }
  }, [props.previewvisible]);

  return (
    <Modal
      className="invision-dialog invision-media-preview preview-model size-lg"
      title=" "
      footer=""
      visible={ props.previewvisible }
      onCancel={() => props.setPreviewvisible(false)}
      onClose={() => props.setPreviewvisible(false)}
    >
      <div className="invision-dialog__body">
        <div className="invision-media-preview__viewer">
          <div
            className="invision-media-preview__viewer-wrap"
            style={{
              backgroundImage: `url(${formatPreUrl(decorationInfo?.modelCover)})`,
              backgroundSize: 'cover',
            }}
          >
          </div>
        </div>
        <div className="invision-justify invision-media-preview__content preview-model__content">
          <div className="invision-justify__col preview-model__about">
            <h2 className="invision-media-preview__title">
                <span>{props.data.Name}</span>
            </h2>
            <Form
              className="invision-form invision-form--readonly preview-model__details"
              labelWidth={ '7em' }
            >
              <Form.Item
                className="invision-form__item"
                label={
                  <span className="invision-form__label">归属项目：</span>
                }
              >
                <div className="invision-form__controls invision-form__controls--text">
                  <div className="invision-form__controls-inner">
                    <span className="invision-form__text">{`/${props.data.Name}`}</span>
                    {/* <span className="invision-form__text">{data.BasicInfo && data.BasicInfo.ClassPath}</span> */}
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                className="invision-form__item"
                label={
                  <span className="invision-form__label">模型编号：</span>
                }
              >
                <div className="invision-form__controls invision-form__controls--text">
                  <div className="invision-form__controls-inner">
                  <span className="invision-form__text">{props.data.ProjectId}</span>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                className="invision-form__item"
                label={
                  <span className="invision-form__label">建模时间：</span>
                }
              >
                <div className="invision-form__controls invision-form__controls--text">
                  <div className="invision-form__controls-inner">
              <span className="invision-form__text">{formatDate(props.data.CreateTime, 'yyyy年MM月dd日 hh:mm:ss')}</span>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                className="invision-form__item preview-model__h5"
                label={
                  <span className="invision-form__label">H5访问链接：</span>
                }
              >
                <div className="invision-form__controls invision-form__controls--text">
                  <div className="invision-form__controls-inner">
                    <span className="invision-form__text">{h5Link}</span>
                    <div className="invision-button-group" style={{ marginTop: -5 }}>
                      <Button
                        className="invision-button invision-button--primary invision-button--link size-sm"
                        theme="text"
                        size="small"
                        onClick={() => window.open(h5Link)}
                      >
                        打开
                      </Button>
                      <Button
                        className="invision-button invision-button--primary invision-button--link size-sm"
                        theme="text"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(h5Link);
                          message.success('复制成功');
                        }}
                      >
                        复制
                      </Button>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
          {
            h5Link[0] && <div className="invision-justify__col preview-model__qrcode">
              <div className="preview-model__qrcode-wrap">
                  <QRCode style={{ width: '', height: '' }} className="preview-model__qrcode-image" value={ h5Link[0] } />
                  <p className="preview-model__qrcode-text">手机扫码预览</p>
              </div>
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

PreviewProject.propTypes = {
  previewvisible: PropTypes.bool,
  setPreviewvisible: PropTypes.func,
  data: PropTypes.object,
};
