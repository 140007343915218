import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Select,
  Icon,
  Switch,
  Modal,
  message,
} from '@tencent/ten-design-react';
import { Loading } from 'tdesign-react';
import uploadFile from '../../../utils/uploadFile';
import { jugeUrl, mediaUrlProcess } from '../../../utils/tools';
import iconUpload from '../../../assets/images/svg/icon-upload.svg';
import pngVoiceBg from '../../../assets/images/voice-bg.png';

const tagOptions = [{
  key: 'text',
  value: '文本标签',
},
{
  key: 'image',
  value: '图片标签',
},
{
  key: 'audio',
  value: '音频标签',
},
{
  key: 'video',
  value: '视频标签',
},
{
  key: 'hyperLink',
  value: '链接标签',
}];
const isChrome = navigator.userAgent.indexOf('Chrome') > -1;

let isComposition = false;
let currentEditMediaSrcObj = {
  coverSrc: '',
  mediaSrc: '',
};
let hasChange = false; // 有改动过的标识

export const TagSetting = (props) => {
  const [currentTagType, setCurrentTagType] = useState({ key: 'text', value: '文本标签' });
  const [currentEditMedia, setCurrentEditMedia] = useState({
    sourceIndex: -1,
    type: '',
    name: '',
    desc: '',
    link: '',
    coverSrc: '',
    mediaSrc: '',
    scene: {
      key: '',
      link: '',
      icon: '',
    },
    autoUnfold: '1',
  });
  const [errorTitleObj, setErrorTitleObj] = useState({ status: '', text: '' });
  const [errorDescObj, setErrorDescObj] = useState({ status: '', text: '' });
  const [showConfirm, setShowConfirm] = useState(false); // 标识是否改动过
  const [isUpLoading, setIsUpLoading] = useState(false); // 正在上传素材
  const [isCoverUpLoading, setIsCoverUpLoading] = useState(false);
  const [uploadImagePreview, setUploadImagePreview] = useState('');
  const [uploadAudioPreview, setUploadAudioPreview] = useState('');
  const [uploadVideoPreview, setUploadVideoPreview] = useState('');
  const [uploadCoverPreview, setUploadCoverPreview] = useState('');

  function handleSelectChange(e) {
    currentEditMedia.name = '';
    currentEditMedia.desc = '';
    const mediaTmp = Object.assign({}, currentEditMedia);
    mediaTmp.type = e.key;
    setCurrentEditMedia(Object.assign({}, mediaTmp));
    setCurrentTagType(e);
    // props.changeToAddTag(e.key);
  }

  // 标签自动展开与否
  function handleAutoUnfold(e) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    mediaTmp.autoUnfold = e.target.checked ? '1' : '0';
    setCurrentEditMedia(Object.assign({}, mediaTmp));
  }

  // 处理拼音输入的监听
  function handleComposition(e) {
    if (e.type === 'compositionend') {
      isComposition = false;
      if (!isComposition && isChrome) {
        handleTitleChange(e);
      }
    } else {
      isComposition = true;
    }
  }

  function handleTitleChange(e) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    if (isComposition) {
      mediaTmp.name = e.target.value;
    } else {
      let textLen = 20;
      if (currentTagType.key === 'text' || currentTagType.key === 'hyperLink') {
        textLen = 200;
      }
      if (e.target.value.length > textLen) {
        setErrorTitleObj({ status: 'error', text: `标签标题不超过${textLen}个字符` });
        mediaTmp.name = e.target.value.substr(0, textLen);
      } else {
        setErrorTitleObj({ status: '', text: '' });
        mediaTmp.name = e.target.value;
      }
    }
    setCurrentEditMedia(Object.assign({}, mediaTmp));
    hasChange = true;
  }

  function handleDescChange(e) {
    if (e.target.value.length > 200) {
      setErrorDescObj({ status: 'error', text: '标签描述不超过200个字符' });
    } else {
      setErrorDescObj({ status: '', text: '' });
      const mediaTmp = Object.assign({}, currentEditMedia);
      mediaTmp.desc = e.target.value;
      setCurrentEditMedia(Object.assign({}, mediaTmp));
    }
    hasChange = true;
  }

  function handleLinkChange(e) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    mediaTmp.link = e.target.value;
    setCurrentEditMedia(Object.assign({}, mediaTmp));
  }

  function handleLinkBlur(e) {
    if (!jugeUrl(e.target.value)) {
      setErrorDescObj({ status: 'error', text: '输入的链接不可用' });
    }
    hasChange = true;
  }

  function handleLinkFocus() {
    setErrorDescObj({ status: '', text: '' });
  }

  async function handleUploadCallback(material, type) {
    // 视频标签上传媒资视频跟封面会出现连续两次调用， setCurrentEditMedia，后来的会覆盖前面的
    const mediaTmp = currentEditMediaSrcObj;
    if (type === 'COVER') {
      mediaTmp.coverSrc = material;
    } else {
      mediaTmp.mediaSrc = material;
    }
    currentEditMediaSrcObj = Object.assign({}, mediaTmp);
    hasChange = true;
  }

  // 校验音视频图片
  function validateFile(file, type) {
    if (type === 'VIDEO') {
      if (file.size / 1024 / 1024 > 200) {
        message.warning('视频大小不超过 200 MB', 1000);
        return false;
      }
    }
    if (type === 'AUDIO') {
      if (file.size / 1024 / 1024 > 20) {
        message.warning('音频大小不超过 20 MB', 1000);
        return false;
      }
    }
    if (type === 'IMAGE') {
      if (file.size / 1024 / 1024 > 2) {
        message.warning('图片大小不超过 2 MB', 1000);
        return false;
      }
    }
    return true;
  }

  async function handleFileUpload(e, type) {
    const file = e.target.files[0];
    if (file) {
      if (validateFile(file, type)) {
        type === 'COVER' ? setIsCoverUpLoading(true) : setIsUpLoading(true);
        const { url, materialId } = await uploadFile(type === 'COVER' ? 'IMAGE' : type, { file }, props.owner);
        switch (type) {
          case 'IMAGE':
            setUploadImagePreview(url);
            break;
          case 'AUDIO':
            setUploadAudioPreview(url);
            break;
          case 'VIDEO':
            setUploadVideoPreview(url);
            break;
          case 'COVER':
            setUploadCoverPreview(url);
            break;
          default:
            break;
        }
        handleUploadCallback(materialId, type, e.file);
        type === 'COVER' ? setIsCoverUpLoading(false) : setIsUpLoading(false);
      }
    } else {
      message.error('文件加载失败，请重新选择', 1000);
    }
  }

  function getComfirmButtonStatus() {
    const type = currentTagType.key;
    switch (type) {
      case 'text':
        return !currentEditMedia.name;
      case 'hyperLink':
        return !currentEditMedia.name || !currentEditMedia.link || errorDescObj.status === 'error';
      case 'image':
        return !currentEditMedia.name || !currentEditMediaSrcObj.mediaSrc || errorDescObj.status === 'error';
      case 'audio':
        return !currentEditMedia.name || !currentEditMediaSrcObj.mediaSrc;
      case 'video':
        return !currentEditMedia.name || !currentEditMediaSrcObj.mediaSrc || !currentEditMediaSrcObj.coverSrc || errorDescObj.status === 'error';
      default:
        break;
    }
    return false;
  }

  // 确认，更新标签数组
  async function handleConfirm() {
    const mediaTmp = Object.assign({}, currentEditMedia);
    mediaTmp.coverSrc = currentEditMediaSrcObj.coverSrc;
    mediaTmp.mediaSrc = currentEditMediaSrcObj.mediaSrc;
    if (!mediaTmp.name) {
      message.warning('标签标题不能为空', 1000);
      return;
    }
    if (mediaTmp.name.length > 20) {
      message.warning('标签标题不超过20个字符', 1000);
      return;
    }
    props.updateCurrentTag(mediaTmp, () => {
      setCurrentEditMedia({
        sourceIndex: -1,
        type: '',
        name: '',
        desc: '',
        link: '',
        coverSrc: '',
        mediaSrc: '',
        scene: {
          key: '',
          link: '',
          icon: '',
        },
        autoUnfold: '1',
      });
      resetFormStatus();
      props.handleShowTagSetting(false, '');
    });
  }

  function handleCancel() {
    resetFormStatus();
    props.handleShowTagSetting(false, '');
    props.deleteTag(currentEditMedia.sourceIndex);
  }

  function handleCloseSetting() {
    if (hasChange) {
      setShowConfirm(true);
    } else {
      props.handleShowTagSetting(false, '');
      props.deleteTag(currentEditMedia.sourceIndex);
    }
  }

  // 表单状态重置
  function resetFormStatus() {
    setUploadImagePreview('');
    setUploadCoverPreview('');
    setUploadVideoPreview('');
    currentEditMediaSrcObj.coverSrc = '';
    currentEditMediaSrcObj.mediaSrc = '';
    setErrorTitleObj({ status: '', text: '' });
    setErrorDescObj({ status: '', text: '' });
    hasChange = false;
  }

  useEffect(() => {
    const tagTypeIndex = tagOptions.findIndex(item => props.currentEditMedia.type === item.key);
    setCurrentTagType(tagOptions[tagTypeIndex]);
    setCurrentEditMedia(props.currentEditMedia);
    switch (props.currentEditMedia.type.toUpperCase()) {
      case 'IMAGE':
        if (props.currentEditMedia.mediaSrc) {
          setUploadImagePreview(props.currentEditMedia.mediaSrc);
          currentEditMediaSrcObj.mediaSrc = props.currentEditMedia.mediaSrc;
        }
        break;
      case 'AUDIO':
        if (props.currentEditMedia.mediaSrc) {
          setUploadAudioPreview(props.currentEditMedia.mediaSrc);
          currentEditMediaSrcObj.mediaSrc = props.currentEditMedia.mediaSrc;
        }
        break;
      case 'VIDEO':
        if (props.currentEditMedia.mediaSrc) {
          setUploadVideoPreview(props.currentEditMedia.mediaSrc);
          currentEditMediaSrcObj.mediaSrc = props.currentEditMedia.mediaSrc;
        }
        if (props.currentEditMedia.coverSrc) {
          setUploadCoverPreview(props.currentEditMedia.coverSrc);
          currentEditMediaSrcObj.coverSrc = props.currentEditMedia.coverSrc;
        }
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div className="invision-config__header">
        <h3 className="invision-config__title">标签设置</h3>
        <button onClick={handleCloseSetting} className="invision-config__close ">
          <i className="invision-icon-cross" />
        </button>
      </div>
      <div className="invision-config__body">
       <div className="invision-config__item">
          <p className="invision-config__text">标签自动展开</p>
          <div>
            {
              currentEditMedia.sourceIndex !== -1
                ? <Switch className="invision-switch" defaultChecked={currentEditMedia.autoUnfold === '1'} onChange={handleAutoUnfold} />
                : null
            }
          </div>
        </div>
        <div className="invision-config__item">
          <p className="invision-config__text">标签类型</p>
          <Select
            value={currentTagType}
            size="small"
            optionKey="key"
            optionText="value"
            className="invision-select invision-select--border-none size-sm"
            valueType="data"
            options={tagOptions}
            onChange={handleSelectChange}
          />
        </div>
        {/* 文本标签设置内容 */}
        {
          currentTagType.key === 'text'
            ? <div>
              <div className="invision-config__item">
                <p className="invision-config__text">文本信息</p>
                <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <textarea
                    maxLength={200}
                    placeholder="请输入文本信息"
                    value={currentEditMedia.name || ''}
                    onChange={handleTitleChange}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  ></textarea>
                  <span className="invision-config__input-limit">{currentEditMedia.name.length}/200</span>
                </div>
              </div>
              {/* <div className="invision-config__item">
                <p className="invision-config__text">
                  <Checkbox className="invision-checkbox">添加链接</Checkbox>
                </p>
                <div className="invision-config__input" style={{ display: 'none' }}>
                  <textarea placeholder="请输入链接地址" />
                </div>
              </div> */}
            </div>
            : null
        }
         {/* 链接标签设置内容 */}
         {
          currentTagType.key === 'hyperLink'
            ? <div>
              <div className="invision-config__item">
                <p className="invision-config__text">链接信息</p>
                <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <textarea
                    maxLength={200}
                    placeholder="请输入链接标题"
                    value={currentEditMedia.name || ''}
                    onChange={handleTitleChange}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  ></textarea>
                  <span className="invision-config__input-limit">{currentEditMedia.name.length}/200</span>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">链接地址</p>
                <div className={`invision-config__input ${errorDescObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <textarea
                    value={currentEditMedia.link || ''}
                    placeholder="请输入以http(s)://开头的链接"
                    onChange={handleLinkChange}
                    onBlur={handleLinkBlur}
                    onFocus={handleLinkFocus} />
                </div>
              </div>
            </div>
            : null
        }
        {/* 图片标签设置内容 */}
        {
          currentTagType.key === 'image'
            ? <div>
              <div className="invision-config__item">
                <p className="invision-config__text">图片文件</p>
                <p className="invision-config__hint">支持jpg/png格式，2MB以内</p>
                <div className="invision-config__upload">
                  {
                    uploadImagePreview
                      ? <>
                        <img
                          className="invision-config__upload-img invision-config__upload-img--cover"
                          src={mediaUrlProcess(uploadImagePreview, props.materialUrls)}
                          alt=""
                        />
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                              <Icon type="refresh" />
                              重新上传
                              <input type="file" title="" onChange={(e) => {
                                handleFileUpload(e, 'IMAGE');
                              }} />
                            </Button>
                        }
                      </>
                      : <div className="invision-config__upload-area">
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                              <img width={14} height={14} src={iconUpload} alt="" />
                              点击上传
                              <input type="file" title="" />
                            </Button>
                        }
                        {/* <p className="invision-config__hint">拖拽至此区域</p> */}
                        {/* 这个不用可删除 */}
                        <input type="file" title="" onChange={(e) => {
                          handleFileUpload(e, 'IMAGE');
                        }} />
                      </div>
                  }
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">图片标题</p>
                <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <input
                    maxLength={10}
                    placeholder="请输入链接标题"
                    value={currentEditMedia.name || ''}
                    onChange={handleTitleChange}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition}
                  ></input>
                  <span className="invision-config__input-limit">{currentEditMedia.name.length}/10</span>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">图片描述</p>
                <div className={`invision-config__input ${errorDescObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <textarea maxLength={200} placeholder="请输入文本信息" value={currentEditMedia.desc || ''} onChange={handleDescChange}></textarea>
                  <span className="invision-config__input-limit">{currentEditMedia.desc.length}/200</span>
                </div>
              </div>
            </div>
            : null
        }
        {/* 音频标签设置内容 */}
        {
          currentTagType.key === 'audio'
            ? <div>
              <div className="invision-config__item">
                <p className="invision-config__text">音频文件</p>
                <p className="invision-config__hint">支持mp3格式，20MB以内</p>
                <div className="invision-config__upload invision-config__upload-voice">
                  {
                    uploadAudioPreview
                      ? <>
                        <img
                          className="invision-config__upload-img invision-config__upload-img--cover"
                          src={pngVoiceBg}
                          alt=""
                        />
                        <div className="invision-config__upload-voice__inner">
                          <i className="invision-icon-audio"></i>
                          <span>音频</span>
                        </div>
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                              <Icon type="refresh" />
                              重新上传
                              <input type="file" title="" onChange={(e) => {
                                handleFileUpload(e, 'AUDIO');
                              }} />
                            </Button>
                        }
                      </>
                      : <div className="invision-config__upload-area">
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                            <img width={12} height={12} src={iconUpload} alt="" />
                            点击上传
                          </Button>
                        }
                      <input type="file" title="" onChange={(e) => {
                        handleFileUpload(e, 'AUDIO');
                      }} />
                    </div>
                  }
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">音频标题</p>
                <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <input
                    maxLength={10}
                    value={currentEditMedia.name || ''}
                    placeholder="请输入音频标题"
                    onChange={handleTitleChange}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition} />
                  <span className="invision-config__input-limit">{currentEditMedia.name.length}/10</span>
                </div>
              </div>
            </div>
            : null
        }
        {/* 视频标签设置内容 */}
        {
          currentTagType.key === 'video'
            ? <div>
              <div className="invision-config__item">
                <p className="invision-config__text">视频文件</p>
                <p className="invision-config__hint">支持mp4格式，200MB以内</p>
                <div className="invision-config__upload">
                  {
                    uploadVideoPreview
                      ? <>
                        <video
                          className="invision-config__upload-img invision-config__upload-img--cover"
                          src={mediaUrlProcess(uploadVideoPreview, props.materialUrls)}
                          alt=""
                        />
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                              <Icon type="refresh" />
                              重新上传
                              <input type="file" title="" onChange={(e) => {
                                handleFileUpload(e, 'VIDEO');
                              }} />
                            </Button>
                        }
                      </>
                      : <div className="invision-config__upload-area">
                        {
                          isUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                            <img width={14} height={14} src={iconUpload} alt="" />
                            点击上传
                            <input type="file" title="" />
                          </Button>
                        }
                        {/* <p className="invision-config__hint">拖拽至此区域</p> */}
                        {/* 这个不用可删除 */}
                        <input type="file" title="" onChange={(e) => {
                          handleFileUpload(e, 'VIDEO');
                        }} />
                      </div>
                  }
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">视频封面</p>
                <p className="invision-config__hint">支持jpg/png格式，2MB以内</p>
                <div className="invision-config__upload">
                  {
                    uploadCoverPreview
                      ? <>
                        <img
                          className="invision-config__upload-img invision-config__upload-img--cover"
                          src={mediaUrlProcess(uploadCoverPreview, props.materialUrls)}
                          alt=""
                        />
                        {
                          isCoverUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                              <Icon type="refresh" />
                              重新上传
                              <input type="file" title="" onChange={(e) => {
                                handleFileUpload(e, 'COVER');
                              }} />
                            </Button>
                        }
                      </>
                      : <div className="invision-config__upload-area">
                        {
                          isCoverUpLoading
                            ? <Loading loading={true} text="正在上传..." size="small" />
                            : <Button className="invision-config__upload-btn">
                            <img width={14} height={14} src={iconUpload} alt="" />
                            点击上传
                            <input type="file" title="" />
                          </Button>
                        }
                        <input type="file" title="" onChange={(e) => {
                          handleFileUpload(e, 'COVER');
                        }} />
                      </div>
                  }
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">视频标题</p>
                <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <input
                    maxLength={10}
                    value={currentEditMedia.name || ''}
                    placeholder="请输入视频标题"
                    onChange={handleTitleChange}
                    onCompositionStart={handleComposition}
                    onCompositionEnd={handleComposition} />
                  <span className="invision-config__input-limit">{currentEditMedia.name.length}/10</span>
                </div>
              </div>
              <div className="invision-config__item">
                <p className="invision-config__text">视频描述</p>
                <div className={`invision-config__input ${errorDescObj.status === 'error' ? 'invision-is-error' : ''}`}>
                  <textarea maxLength={200} value={currentEditMedia.desc || ''} onChange={handleDescChange} placeholder="请输入视频描述"></textarea>
                  <span className="invision-config__input-limit">{currentEditMedia.desc.length}/200</span>
                </div>
              </div>
            </div>
            : null
        }
      </div>
      <div className="invision-config__footer">
        <Button
          className="invision-button invision-button--round size-md"
          disabled={getComfirmButtonStatus()}
          onClick={handleConfirm}
        >
          确认
        </Button>
        <Button
          className="invision-button invision-button--round invision-button--danger size-md"
          onClick={handleCancel}
        >
          取消
        </Button>
      </div>
      <Modal
        className="ten-modal invision-dialog invision-media-preview size-md"
        title="标签内容有变更"
        destroy
        width={400}
        visible={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        footer={
          <div className="invision-dialog__button-wrap">
            <Button
              className="invision-button invision-button--primary invision-button--round size-sm"
              theme="primary"
              size="small"
              onClick={handleConfirm}
            >
              确定
            </Button>
            <Button
              className="invision-button invision-button--secondary invision-button--round size-sm"
              theme="default"
              size="small"
              onClick={() => {
                props.handleShowTagSetting(false, '');
                props.deleteTag(currentEditMedia.sourceIndex);
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <p>是否保存当前标签?</p>
      </Modal>
  </>);
};

TagSetting.propTypes = {
  owner: PropTypes.object,
  materialUrls: PropTypes.object,
  currentEditMedia: PropTypes.object,
  handleShowTagSetting: PropTypes.func,
  updateCurrentTag: PropTypes.func,
  changeToAddTag: PropTypes.func,
  deleteTag: PropTypes.func,
};
