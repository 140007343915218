import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from '@tencent/ten-design-react';
import { TreeSelect, Button, Form, Input, Tooltip, Radio } from 'tdesign-react';
import { InfoCircleIcon } from 'tdesign-icons-react';
import { useUser } from '../../../context/userProvider';
import client from '../../../utils/apiClient';
import iconLoading from '../../../assets/images/svg/icon-loading.svg';

const { FormItem } = Form;
const titleMap = {
  project: '创建项目',
  folder: '创建文件夹',
  move: '移动到',
  panoModel: '创建 3D 模型',
};
const labelMap = {
  project: '项目名称',
  folder: '文件夹名称',
  panoModel: '模型名称',
};
const createTypeArr = ['folder', 'move', 'panoModel'];
let treeDataCan = [];

export const CreateProject = (props) => {
  const user = useUser();
  const [treeValue, setTreeValue] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [createName, setCreateName] = useState('');
  const [modelType, setModelType] = useState('XRLabPano');

  async function getTreeData() {
    const rootClassInfo = [
      {
        ClassId: (props.actTab === 'model' || props.createType === 'panoModel') ? 9 : 103,
        Name: (props.actTab === 'model' || props.createType === 'panoModel') ? '3D 模型' : '全景图片',
        ParentClassId: 0,
        Owner: { Id: user.TfUid, Type: 'PERSON' },
      },
    ];
    treeDataCan = rootClassInfo.map(item => ({
      label: item.Name,
      value: item.ClassId,
      item,
      owner: item.Owner,
      children: true,
    }));
    setTreeData(treeDataCan);
  }

  async function describeClass(classId) {
    return client('PaaS/Material/DescribeClass', {
      body: {
        BaseClassId: classId,
        Platform: '',
        FirstLevel: true,
        Owner: { Id: user.TfUid, Type: 'PERSON' },
      } });
  }

  async function handleTreeItemSelect(item) {
    if (item.data.owner && item.model) {
      const res = await describeClass(item.data.value);
      if (!res.data.Data) return;
      const childrenSet = [];
      if (res.data.Data.ClassInfoSet.length) {
        res.data.Data.ClassInfoSet.forEach((dataItem) => {
          childrenSet.push({
            value: dataItem.ClassId,
            label: dataItem.Name,
            item: dataItem,
            owner: item.data.owner,
            children: true,
          });
        });
        return childrenSet;
      }
      return [];
    }
  }

  function getClassId() {
    return props.createType === 'project' ? '' : treeValue;
  }

  function preCheckForm() {
    if (props.createType !== 'move' && !createName) return true;
    if (props.createType !== 'project' && !treeValue) return true;
    return false;
  }

  function handleRadioChange(e) {
    setModelType(e);
  }

  function handlePointRelationClick() {
    props.extendPointInfo();
  }

  useEffect(async () => {
    if (!props.showCreate) {
      setTreeValue(null);
      setCreateName('');
    } else {
      setTreeData([]);
      getTreeData();
    }
  }, [props.showCreate]);

  return (
    <Modal
      title={titleMap[props.createType]}
      width={500}
      className="invision-dialog"
      onClose={() => {
        props.setShowCreate(false);
      }}
      footer={
        <div className="invision-dialog__button-wrap">
          <Button
            className="invision-button invision-button--primary invision-button--round"
            theme="primary"
            disabled={preCheckForm()}
            onClick={() => {
              props.createType === 'panoModel' ? props.comfirmCreate(createName, getClassId(), modelType) : props.comfirmCreate(createName, getClassId());
            }}
          >
            确认
          </Button>
          <Button
            className="invision-button invision-button--secondary invision-button--round"
            theme="default"
            onClick={() => {
              props.setShowCreate(false);
            }}
          >
            取消
          </Button>
        </div>
      }
      visible={props.showCreate}
    >
      <Form>
        {
          props.createType !== 'move'
            ?  <FormItem
              className="invision-form__item"
              label={<div className="invision-form__label">{labelMap[props.createType]}</div>}
            >
              <div className="invision-form__controls">
                <div className="invision-form__controls-inner">
                  <Input
                    className="invision-input invision-input--border-none size-sm"
                    placeholder={`请输入${labelMap[props.createType]}`}
                    value={createName}
                    onChange={(value) => {
                      setCreateName(value);
                    }}
                  />
                </div>
              </div>
            </FormItem>
            : null
        }
        {
          props.createType === 'panoModel'
            ? <FormItem
                className="invision-form__item"
                label={<div className="invision-form__label">模型类型</div>}
              >
                <Radio.Group defaultValue={modelType} value={modelType} onChange={handleRadioChange}>
                  <Radio className="invision-radio" value="XRLabPano">
                    漫游模型
                  </Radio>
                  <Radio className="invision-radio" value="XRLabAerial">
                    星球模型
                  </Radio>
                </Radio.Group>
              </FormItem>
            : null
        }
        {
          createTypeArr.includes(props.createType)
            ? <FormItem
              className="invision-form__item"
              label={<div className="invision-form__label">保存位置</div>}
            >
              <div className="invision-form__controls">
                <div className="invision-form__controls-inner">
                  {
                    treeData.length > 0
                      ? <TreeSelect
                          className="invision-input invision-input--border-none size-sm"
                          data={treeData}
                          clearable
                          placeholder="请选择"
                          value={treeValue}
                          popupProps={{
                            overlayClassName: 'invision-t-popup',
                          }}
                          treeProps={{
                            load: handleTreeItemSelect,
                          }}
                          onChange={(val) => {
                            setTreeValue(val);
                          }}
                           />
                      : <img
                        className="invision-message__icon spin-self--forever"
                        src={iconLoading}
                        alt="icon-loading"
                      />
                  }
                </div>
              </div>
            </FormItem>
            : null
        }
        {
          props.createType === 'panoModel' && <FormItem label="高级设置">
          <div className="invision-text--link">
            <Button
              className="invision-button invision-button--primary invision-button--link"
              theme="text"
              onClick={handlePointRelationClick}
            >
              {props.relationInfosForPanoData.length ? `${props.relationInfosForPanoData.length}组点位关系` : '点位关系设置'}
            </Button>
            <Tooltip content="若全景图数量较多，建议设置点位关系以提高3D模型精准度" theme="light">
              <InfoCircleIcon />
            </Tooltip>
          </div>
        </FormItem>
        }
      </Form>
    </Modal>
  );
};

CreateProject.propTypes = {
  showCreate: PropTypes.bool,
  createType: PropTypes.string,
  actTab: PropTypes.string,
  relationInfosForPanoData: PropTypes.array,
  setShowCreate: PropTypes.func,
  comfirmCreate: PropTypes.func,
  extendPointInfo: PropTypes.func,
};
