import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { mediaUrlProcess, throttle, imageProcess } from '../../utils/tools';
import './index.css';
import iconPlay from '../../assets/images/svg/icon-play.svg';
import iconLocation from '../../assets/images/svg/icon-location.svg';
const tagOffset = { // 标签的偏移，针对打标签位置不准的修复
  text: '0px 0px 0px 0px',
  image: '-45px 0px 0px 0px',
  audio: '0px 0px 0px 0px',
  video: '-45px 0px 0px 0px',
  hyperLink: '0px 0px 0px 0px',
};
const tagTypeMap = {
  text: 'text',
  image: 'image',
  audio: 'audio',
  video: 'video',
  hyperLink: 'hyperLink',
  scenes: 'jump',
};
let canvasDom;
let throttleMouseMove;
let dragTarget;

const Tags = (props) => {
  // 判断是否展开标签
  function isCenterTag(left, top) {
    return ((parseFloat(left) > 15 && parseFloat(left) < 85) && (parseFloat(top) > 20 && parseFloat(top) < 80));
  }

  function tagEvent(e, autoUnfold) {
    if (!autoUnfold || autoUnfold === '1') return; // 自动展开不需要点击事件
    const parentTarget = e.target.parentNode;
    const classList = Array.from(parentTarget.classList);
    if (classList.includes('invision-is-collapse')) {
      parentTarget.classList.remove('invision-is-collapse');
    } else {
      parentTarget.classList.add('invision-is-collapse');
    }
  }

  function calculateTagClassName(tag) {
    let className = '';
    if (tag.mediaData.autoUnfold !== undefined) {
      className = tag.mediaData.autoUnfold === '1' && isCenterTag(tag.left, tag.top) ? '' : 'invision-is-collapse';
    } else {
      className = isCenterTag(tag.left, tag.top) ? '' : 'invision-is-collapse';
    }
    if (props.currentEditingTag?.mediaData.sourceIndex === tag.mediaData.sourceIndex) className = `${className} invision-is-active`;
    return className;
  }

  function handleTagMouseDown(e) {
    dragTarget = e.currentTarget;
    throttleMouseMove = throttle(16.6, handleTagMouseMove);
    document.addEventListener('mousemove', throttleMouseMove);
  }

  function handleTagMouseMove(e) {
    const { left, top } = canvasDom.getBoundingClientRect();
    const { clientX, clientY } = e;
    dragTarget.style.left = `${clientX - left - 26}px`; // 26 标签圆点的一半宽度
    dragTarget.style.top = `${clientY - top - 26}px`; // 26 标签圆点的一半高度
  }

  function handleTagMouseUp(e) {
    document.removeEventListener('mousemove', throttleMouseMove);
    const { left, top, width, height } = canvasDom.getBoundingClientRect();
    const { clientX, clientY } = e;
    const offsetX = (clientX - left - 26) / width * 2 - 1;
    const offsetY = -((clientY - top - 26) / height) * 2 + 1;
    props.updateEditTagPosition(offsetX, offsetY);
  }

  useEffect(() => {
    canvasDom = document.getElementById('viewport-canvas');
  }, []);

  return (
    <div className="viewport">
      <div className="tag-board">
        {
          props.showTags.map((tag, index) => (
            <div
              key={index}
              className={`model-tag invision-is-show model-tag--${tagTypeMap[tag.mediaData.type]} ${calculateTagClassName(tag)}`}
              style={{
                left: tag.left,
                top: tag.top,
                margin: tagOffset[tag.mediaData.type],
                cursor: props.sdkType === 'lab' && tag.isTemp ? 'grab' : 'auto',
                pointerEvents: props.inPreview ? 'none' : 'auto',
                transform: `rotateX(${-(65 - parseFloat(tag.top)) / 6}deg) rotateY(${(50 - parseFloat(tag.left)) / 4}deg) translateZ(0)`,
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (props.sdkType === 'lab' && tag.isTemp) handleTagMouseDown(e);
              }}
              // onMouseMove={(e) => {
              //   tag.isTemp && handleTagMouseMove(e);
              // }}
              onMouseUp={(e) => {
                if (props.sdkType === 'lab' && tag.isTemp) handleTagMouseUp(e);
              }}
            >
              <div className="model-tag__panel">
                {
                  tag.mediaData.type !== 'scenes'
                    ? <div className="model-tag__arrow">
                      <div className="model-tag__arrow-tail"></div>
                      <div className="model-tag__arrow-head" onClick={(e) => {
                        tagEvent(e, tag.mediaData.autoUnfold);
                      }} style={{ zIndex: 99 }}></div>
                    </div>
                    : null
                }
                {
                  tag.mediaData.type === 'text'
                    ? <div className="model-tag-content">
                      <span className="model-tag-content__text">{tag.mediaData.name}</span>
                    </div>
                    : null
                }
                {
                  tag.mediaData.type === 'image'
                    ? <div className="model-tag-media">
                        <div className="model-tag-media__view">
                        <img
                          width={254}
                          height={142}
                          src={imageProcess(mediaUrlProcess(tag.mediaData.mediaSrc, props.materialUrls), '1.jpg')}
                          alt="图片"
                        />
                      </div>
                      <div className="model-tag-media__info">
                        <h4 className="model-tag-media__title">{tag.mediaData.name}</h4>
                        <p className="model-tag-media__desc">{tag.mediaData.desc}</p>
                      </div>
                    </div>
                    : null
                }
                {
                  tag.mediaData.type === 'audio'
                    ? <>
                      <div className="model-tag-content">
                        <i className="model-tag-content__icon invision-icon-audio"></i>
                        <span className="model-tag-content__text">{tag.mediaData.name}</span>
                      </div>
                    </>
                    : null
                }
                {
                  tag.mediaData.type === 'video'
                    ? <div className="model-tag-media">
                        <div className="model-tag-media__view">
                          <video
                            width={254}
                            height={142}
                            src={mediaUrlProcess(tag.mediaData.mediaSrc, props.materialUrls)}
                            poster={imageProcess(mediaUrlProcess(tag.mediaData.coverSrc, props.materialUrls), '1.jpg')}
                            alt="视频"
                          />
                          <button className="model-tag-media__play-btn">
                            <img width={22} height={28} src={iconPlay} alt="" />
                          </button>
                        </div>
                        <div className="model-tag-media__info">
                          <h4 className="model-tag-media__title">{tag.mediaData.name}</h4>
                          <p className="model-tag-media__desc">{tag.mediaData.desc}</p>
                        </div>
                      </div>
                    : null
                }
                {
                  tag.mediaData.type === 'hyperLink'
                    ? <div className="invision-media model-tag-media">
                      <div className="invision-media__center model-tag-media__center">
                        <p className="model-tag-media__desc" style={{ color: '#FFFFFF', fontSize: '14px' }}>{tag.mediaData.name}</p>
                      </div>
                    </div>
                    : null
                }
              </div>
              {
                tag.mediaData.type === 'scenes'
                  ? <>
                    <div className="model-tag-content">
                      <span className="model-tag-content__text">{tag.mediaData.name}</span>
                    </div>
                    <img src={tag.mediaData.scene?.icon || iconLocation} alt="icon" />
                  </>
                  : null
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

Tags.propTypes = {
  sdkType: PropTypes.string,
  materialUrls: PropTypes.object,
  inPreview: PropTypes.bool,
  showTags: PropTypes.array,
  currentEditingTag: PropTypes.object,
  updateEditTagPosition: PropTypes.func,
};

export default Tags;
