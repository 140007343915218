import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
  Input,
} from '@tencent/ten-design-react';
import { useUser } from '../../../context/userProvider';
import client from '../../../utils/apiClient';

const nameMap = {
  project: '项目',
  class: '文件夹',
  material: '素材',
};

export const RenameProject = (props) => {
  const user = useUser();
  const [renameVal, setRenameVal] = useState('');
  const [renameType, setRenameType] = useState('');

  function rename() {
    let url = '';
    let query;
    switch (renameType) {
      case 'project':
        url = 'PaaS/Project/ModifyProject';
        query = { ProjectId: props.targetItem.ProjectId, Name: renameVal };
        break;
      case 'class':
        url = 'PaaS/Material/ModifyClass';
        query = { ClassId: props.targetItem.ClassId, Name: renameVal, Owner: { Id: user.TfUid, Type: 'PERSON' } };
        break;
      case 'material':
        url = 'PaaS/Material/ModifyMaterial';
        query = { MaterialId: props.targetItem.BasicInfo.MaterialId, Name: renameVal };
        break;
    }
    client(url, { body: query }).then(handleRenameResponse);
  }

  function handleRenameResponse(res) {
    if (res.data.Code === 'Success') {
      props.reflashName(renameVal);
      props.setShowRename(false);
    }
  }

  useEffect(async () => {
    if (!props.targetItem) return;
    if (props.targetItem.ProjectId) {
      setRenameType('project');
      setRenameVal(props.targetItem.Name);
    }
    if (props.targetItem.ClassId) {
      setRenameType('class');
      setRenameVal(props.targetItem.Name);
    }
    if (props.targetItem.BasicInfo) {
      setRenameType('material');
      setRenameVal(props.targetItem.BasicInfo.Name);
    }
  }, [props.targetItem]);

  return (
    <Modal
      title={`${nameMap[renameType]}重命名`}
      width={500}
      className="invision-dialog"
      onClose={() => props.setShowRename(false)}
      footer={
        <div className="invision-dialog__button-wrap">
          <Button
            className="invision-button invision-button--primary invision-button--round"
            theme="primary"
            onClick={rename}
          >
            确认
          </Button>
          <Button
            className="invision-button invision-button--secondary invision-button--round"
            theme="default"
            onClick={() => {
              props.setShowRename(false);
            }}
          >
            取消
          </Button>
        </div>
      }
      visible={props.showRename}
    >
      <Form className="invision-form">
        <Form.Item
          className="invision-form__item"
          label={<div className="invision-form__label">{`${nameMap[renameType]}名称`}</div>}
        >
          <div className="invision-form__controls">
            <div className="invision-form__controls-inner">
              <Input
                className="invision-input invision-input--border-none size-sm"
                placeholder={`请输入${nameMap[renameType]}名称`}
                value={renameVal}
                onChange={({ target: { value } }) => {
                  setRenameVal(value);
                }}
              />
            </div>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

RenameProject.propTypes = {
  showRename: PropTypes.bool,
  targetItem: PropTypes.object,
  setShowRename: PropTypes.func,
  reflashName: PropTypes.func,
};
