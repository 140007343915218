const axios = require('axios').default;
const API_URL = process.env.REACT_APP_RUNNING_ENV === 'production' ? 'https://api.vs.tencent.com' : 'https://api-base.vs.tencent.com/tfusion-imm-tomwei';

function client(action, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem('__linjing_token__');
  const headers = { 'content-type': 'application/json' };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    withCredentials: true,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.data = JSON.stringify(body);
  }

  return axios(`${API_URL}/${action}`, config);
}

export default client;
