import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from '@tencent/ten-design-react';
import screenShotDefault from '../../../assets/images/screen-shot-default.png';
import sceneModelIcon from '../../../assets/images/svg/icon-model.svg';
const iconLocation = 'https://invision.tencentcs.com/static/media/icon-location.svg';
const iconFront = 'https://invision.tencentcs.com/static/media/icon-front.svg';
const iconTurnLeft = 'https://invision.tencentcs.com/static/media/icon-turn-left.svg';
const iconHelicopter = 'https://invision.tencentcs.com/static/media/icon-helicopter.svg';
const iconTurnRight = 'https://invision.tencentcs.com/static/media/icon-turn-right.svg';

const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
const scenesLinkIconMap = [
  {
    key: 'location',
    iconLink: iconLocation,
  },
  {
    key: 'front',
    iconLink: iconFront,
  },
  {
    key: 'turnLeft',
    iconLink: iconTurnLeft,
  },
  {
    key: 'turnRight',
    iconLink: iconTurnRight,
  },
  {
    key: 'helicopter',
    iconLink: iconHelicopter,
  },
];
let isComposition = false;

export const SceneSetting = (props) => {
  const [currentEditMedia, setCurrentEditMedia] = useState({
    sourceIndex: -1,
    type: '',
    name: '',
    desc: '',
    link: '',
    coverSrc: '',
    mediaSrc: '',
    scene: {
      key: '',
      link: '',
      icon: '',
    },
    autoUnfold: '1',
  });
  const [sceneInfoSet, setSceneInfoSet] = useState([]);
  const [errorTitleObj, setErrorTitleObj] = useState({ status: '', text: '' });

  // 处理拼音输入的监听
  function handleComposition(e) {
    if (e.type === 'compositionend') {
      isComposition = false;
      if (!isComposition && isChrome) {
        handleTitleChange(e);
      }
    } else {
      isComposition = true;
    }
  }

  function handleTitleChange(e) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    if (isComposition) {
      mediaTmp.name = e.target.value;
    } else {
      if (e.target.value.length > 20) {
        setErrorTitleObj({ status: 'error', text: '标签标题不超过20个字符' });
        mediaTmp.name = e.target.value.substr(0, 20);
      } else {
        setErrorTitleObj({ status: '', text: '' });
        mediaTmp.name = e.target.value;
      }
    }
    setCurrentEditMedia(Object.assign({}, mediaTmp));
  }

  // 处理关联场景的图标
  function handleSceneIconChange(scene) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    if (!mediaTmp.scene) {
      mediaTmp.scene = {
        key: '',
        link: '',
        icon: '',
      };
    }
    mediaTmp.scene.key = scene.key;
    mediaTmp.scene.icon = scene.iconLink;
    setCurrentEditMedia(Object.assign({}, mediaTmp));
  }

  // 处理关联场景的素材 id
  function handleSceneLinkChange(id) {
    const mediaTmp = Object.assign({}, currentEditMedia);
    if (!mediaTmp.scene) {
      mediaTmp.scene = {
        key: '',
        link: '',
        icon: '',
      };
    }
    mediaTmp.scene.link = id;
    setCurrentEditMedia(Object.assign({}, mediaTmp));
  }

  function getComfirmButtonStatus() {
    return !currentEditMedia.name || !currentEditMedia.scene?.key || !currentEditMedia.scene.link;
  }

  // 确认，更新标签数组
  async function handleConfirm() {
    const mediaTmp = Object.assign({}, currentEditMedia);
    if (!mediaTmp.name) {
      message.warning('标签标题不能为空', 1000);
      return;
    }
    if (mediaTmp.name.length > 20) {
      message.warning('标签标题不超过20个字符', 1000);
      return;
    }
    props.updateCurrentTag(mediaTmp, () => {
      setCurrentEditMedia({
        sourceIndex: -1,
        type: '',
        name: '',
        desc: '',
        link: '',
        coverSrc: '',
        mediaSrc: '',
        scene: {
          key: '',
          link: '',
          icon: '',
        },
        autoUnfold: '1',
      });
      resetFormStatus();
      props.handleShowTagSetting(false, 'scenes');
    });
  }

  function handleCancel() {
    resetFormStatus();
    props.handleShowTagSetting(false, 'scenes');
    props.deleteTag(currentEditMedia.sourceIndex);
  }

  // 表单状态重置
  function resetFormStatus() {
    setErrorTitleObj({ status: '', text: '' });
  }

  useEffect(async () => {
    if (props.materialsInProject?.length) {
      const materialsWithDecoration = props.materialsInProject.map((item) => {
        const decoration = item.Immersive3DModelMaterial.Decoration;
        const decorationInfoSet = Object.assign({}, JSON.parse(decoration === '' ? '{}' : decoration));
        return Object.assign(item, { decorationInfoSet });
      });
      setSceneInfoSet(materialsWithDecoration);
    }
  }, [props.materialsInProject]);

  useEffect(() => {
    setCurrentEditMedia(props.currentEditMedia);
  }, []);

  return (
    <>
      <div className="invision-config__header">
        <h3 className="invision-config__title">场景跳转设置</h3>
        <button onClick={handleCancel} className="invision-config__close ">
          <i className="invision-icon-cross" />
        </button>
      </div>
      <div className="invision-config__body">
        <div className="invision-config__item">
          <p className="invision-config__text">选择跳转图标</p>
          <div className="scene-jump__config-icon-wrap">
            {
                scenesLinkIconMap.map(item => (
                  <div
                    key={item.key}
                    className={`scene-jump__config-icon ${item.key === currentEditMedia.scene?.key ? 'is-selected' : ''}`}
                    onClick={() => {
                      handleSceneIconChange(item);
                    }}>
                    <img src={item?.iconLink} alt="icon" />
                  </div>
                ))
              }
          </div>
        </div>
        <div className="invision-config__item">
          <p className="invision-config__text">跳转文案</p>
          <div className={`invision-config__input ${errorTitleObj.status === 'error' ? 'invision-is-error' : ''}`}>
            <textarea
              maxLength={20}
              placeholder="请输入跳转文案"
              style={{ minHeight: 52 }}
              value={currentEditMedia.name || ''}
              onChange={handleTitleChange}
              onCompositionStart={handleComposition}
              onCompositionEnd={handleComposition}
            ></textarea>
            <span className="invision-config__input-limit">0/20</span>
          </div>
        </div>
        <div className="invision-config__item">
          <p className="invision-config__text">目标场景</p>
          <div className="scene-jump__config-card-wrap">
            {
              sceneInfoSet.filter(item => item.BasicInfo.MaterialId !== props.materialId).map(item => (
                <div key={item.BasicInfo.MaterialId} className={`scene-jump__config-card ${item.BasicInfo.MaterialId === currentEditMedia.scene?.link ? 'is-selected' : ''}`} onClick={() => {
                  handleSceneLinkChange(item.BasicInfo.MaterialId);
                }}>
                  <img
                    className="scene-jump__config-card-img"
                    src={item.decorationInfoSet.modelCover || screenShotDefault}
                    alt="scene"
                  />
                  <div className="scene-jump__config-card-info">
                    <p className="scene-jump__config-card-name">
                      { item.BasicInfo.Name }
                    </p>
                    <img
                      className="scene-jump__config-card-icon"
                      src={sceneModelIcon}
                      alt=""
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="invision-config__footer">
        <Button
          className="invision-button invision-button--round size-md"
          disabled={getComfirmButtonStatus()}
          onClick={handleConfirm}
        >
          确认
        </Button>
        <Button
          className="invision-button invision-button--round invision-button--danger size-md"
          onClick={handleCancel}
        >
          取消
        </Button>
      </div>
  </>);
};

SceneSetting.propTypes = {
  owner: PropTypes.object,
  materialUrls: PropTypes.object,
  currentEditMedia: PropTypes.object,
  handleShowTagSetting: PropTypes.func,
  updateCurrentTag: PropTypes.func,
  changeToAddTag: PropTypes.func,
  deleteTag: PropTypes.func,
  materialsInProject: PropTypes.array,
  materialId: PropTypes.string,
};
