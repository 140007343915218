import TcVod from 'vod-js-sdk-v6';
import { message } from '@tencent/ten-design-react';
import client from './apiClient';

let uploadFileType = '';
let targetClassId = '';
let targetOwner;
const uploadVerify = {
  UploadContext: '', // 获取上传文件的签名附带的上下文
  VodVerifyKey: '', // VOD 上传的验证 KEY
};

// 获取上传文件签名
async function getUploadSign(filename) {
  const query = {
    // ClassId: 10, // 素材
    MaterialType: uploadFileType,
    Name: filename,
    Owner: targetOwner,
  };
  if (targetClassId) query.ClassId = targetClassId; // for 上传全景图片
  return client('PaaS/Material/CreateUploadMaterial', {
    body: query }).then((res) => {
    if (res.data.Data) {
      return {
        sign: res.data.Data.VodUploadSign,
        uploadContext: res.data.Data.UploadContext,
      };
    }
    // TODO 报错处理
  });
}

// 获取媒体资源的 MaterialId
async function getMaterialId(fileId) {
  return client('PaaS/Material/FinishUploadMaterial', {
    body: {
      ...uploadVerify,
      VodFileId: fileId,
    } }).then((res) => {
    if (res.data.Data) {
      return res.data.Data.MaterialId;
    }
    // TODO 报错处理
  });
}

// 调用上传 sdk
async function uploadFile(type, fileObj, owner, classId) {
  // const reader = new FileReader();
  // reader.onload = function (e) {
  // };
  // reader.readAsDataURL(fileObj.file);
  if (classId) targetClassId = classId;
  targetOwner = owner;
  switch (type) {
    case 'IMAGE':
      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(fileObj.file.type)) {
        message.warning('图片格式不符合要求', 1000);
        return;
      }
      break;
    case 'AUDIO':
      if (!['audio/mpeg', 'audio/mp3'].includes(fileObj.file.type)) {
        message.warning('音频格式不符合要求', 1000);
        return;
      }
      break;
    case 'VIDEO':
      if (!['video/mpeg', 'video/mp4'].includes(fileObj.file.type)) {
        message.warning('视频格式不符合要求', 1000);
        return;
      }
      break;
    default:
      break;
  }
  uploadFileType = type;
  const { sign, uploadContext } = await getUploadSign(fileObj.file.name);
  const tcVod = new TcVod({
    getSignature: () => sign,
  });
  const uploader = tcVod.upload({
    mediaFile: fileObj.file,
  });
  uploader.on('media_progress', (info) => {
    const total = 100;
    const loaded = info.percent * total;
    fileObj.onProgress(fileObj.file, { loaded, total });
  });
  const doneResult = await uploader.done();
  uploadVerify.UploadContext = uploadContext;
  uploadVerify.VodVerifyKey = doneResult.video.verify_content;
  fileObj.onSuccess && fileObj.onSuccess(fileObj.file);
  const materialId = await getMaterialId(doneResult.fileId);
  // callback && callback({ url: doneResult.video.url, materialId });
  return { url: doneResult.video.url, materialId };
  // await uploader.done().then(async (doneResult) => {
  //   uploadVerify.VodVerifyKey = doneResult.video.verify_content;
  //   fileObj.onSuccess && fileObj.onSuccess(fileObj.file);
  //   const materialId = await getMaterialId(doneResult.fileId);
  //   // callback && callback({ url: doneResult.video.url, materialId });
  //   return { url: doneResult.video.url, materialId };
  // })
  //   .catch((err) => {
  //   });
}

export default uploadFile;
