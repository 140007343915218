import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Slider,
  Select,
} from '@tencent/ten-design-react';
import { TagSetting } from './tagSetting';
import { SceneSetting } from './sceneSetting';
import {
  imageProcess,
  mediaUrlProcess,
} from '../../../utils/tools';
import screenShotDefault from '../../../assets/images/screen-shot-default.png';
import iconLocation from '../../../assets/images/svg/icon-location.svg';
import iconClose from '../../../assets/images/svg/icon-close.svg';
import iconPlay from '../../../assets/images/svg/icon-play.svg';

export const HelperAside = (props) => {
  const [showTagSetting, setShowTagSetting] = useState(false);
  const [showSceneSetting, setShowSceneSetting] = useState(false);
  const [currentEditMedia, setCurrentEditMedia] = useState(null);
  const [initPanoIndex, setInitPanoIndex] = useState(props.initPanoIndex);

  function handleShowTagSetting(value, type) {
    const mediaData = {
      sourceIndex: `invision_tag_${String(new Date().getTime())}`,
      type: type || 'text',
      name: '',
      desc: '',
      coverSrc: '',
      mediaSrc: '',
      sceneLink: '',
      sceneIcon: '',
      autoUnfold: '1',
    };
    setCurrentEditMedia(mediaData);
    if (value) {
      props.addOrUpdateTag(-1, mediaData, null, () => {
        type === 'scenes' ? setShowSceneSetting(value) : setShowTagSetting(value);
      });
    } else {
      type === 'scenes' ? setShowSceneSetting(value) : setShowTagSetting(value);
    }
    props.setEditingTag(null);
  }

  // 点击标签把数据带上
  function handleMediaClick(tag) {
    setCurrentEditMedia(Object.assign({}, tag.mediaData));
    tag.mediaData.type === 'scenes' ? setShowSceneSetting(true) : setShowTagSetting(true);
    props.setEditingTag(tag);
  }

  // 跳转至目标标签
  function foucusToTag(tag) {
    if (props.sdkType === 'lab') {
      if (tag.panoIndex === parseInt(props.vTour.currentPano.index, 10)) {
        // 标签在当前点位，就不需要移动点位了
        props.vTour.updateCamera({ lonLat: [tag.longitude, tag.latitude] });
      } else {
        props.vTour.moveTo(String(tag.panoIndex), { lonLat: [tag.longitude, tag.latitude] });
      }
    } else {
      props.vTour.updateWithStates({
        mode: 'Panorama',
        panoIndex: tag.panoIndex,
        longitude: tag.longitude,
        latitude: tag.latitude,
        fov: 100,
      });
    }
  }

  useEffect(() => {
    setInitPanoIndex(props.initPanoIndex);
  }, [props.initPanoIndex]);

  return (
    <aside className="model-details__inspector">
      {
        !props.modelIsLoading
          ? <div className="invision-config">
            {/* 初始信息 tool start */}
            {
              props.editType === 'init'
                ? <>
                  <div className="invision-config__header">
                    <h3 className="invision-config__title">初始点位</h3>
                  </div>
                  <div className="invision-config__body">
                    <div className="model-properties">
                      <div className="model-property">
                        <div className="model-property__label">
                          <span className="model-property__label-text">初始点位</span>
                        </div>
                        <div className="model-property__content">
                          {
                            props.vTourData
                              ? <Select
                                  options={props.vTourData.observers}
                                  size="small"
                                  valueType="key"
                                  value={initPanoIndex}
                                  placeholder="请选择初始点位"
                                  optionKey={optionData => optionData.index - 0 + 1}
                                  optionText={optionData => `第${optionData.index - 0 + 1}个点位`}
                                  // renderOption={item => <span>第{item.index}个点位</span>}
                                  onSelect={(item) => {
                                    setInitPanoIndex(item.index);
                                    props.setPanoImgLoading(true);
                                    props.vTour.moveTo
                                      ? props.vTour.moveTo(item.index) : props.vTour.moveToPano(item.index);
                                  }}
                                />
                              : null
                          }
                        </div>
                      </div>
                      <div className="model-property">
                        <div className="model-property__label">
                          <span className="model-property__label-text">封面预览</span>
                        </div>
                        <div className="model-property__content">
                          <div className="model-property__content--image">
                            <div
                              className="model-property__content-image"
                              style={{
                                backgroundImage: `url(${imageProcess(props.viewShotSrc, '1.jpg') || screenShotDefault})`,
                                backgroundSize: 'cover',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="model-property">
                        <div className="model-property__label">
                          <span className="model-property__label-text">初始角预览</span>
                        </div>
                        <div className="model-property__content">
                          <div className="model-property__content--image">
                            <div
                              className="model-property__content-image"
                              style={{
                                backgroundImage: `url(${imageProcess(props.viewSenseSrc, '1.jpg') || screenShotDefault})`,
                                backgroundSize: 'cover',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                : null
            }
            {/* 初始信息 tool end */}
            {/* 标签设置 tool start */}
            {
              props.editType === 'tag' && !showTagSetting
                ? <>
                  <div className="invision-config__header">
                    <Button className="invision-button invision-button--round invision-button--outline size-md" onClick={() => handleShowTagSetting(true, '')}>
                      <i className="invision-icon-add"></i>
                      <span>添加标签</span>
                    </Button>
                  </div>
                  <div className="invision-config__body">
                    <p className="invision-config__text">当前标签</p>
                    {
                      props.tagsList.filter(tag => tag.mediaData.type !== 'scenes').length
                        ? props.tagsList.filter(tag => tag.mediaData.type !== 'scenes').map((tag, index) => (
                        <div className="invision-config__tag" key={index} onClick={() => {
                          handleMediaClick(tag);
                          foucusToTag(tag);
                        }}>
                          {
                            tag.mediaData.type === 'text' || tag.mediaData.type === 'audio'
                              ? <>
                                <i className={`invision-icon-${tag.mediaData.type} invision-config__tag-icon`}></i>
                                <p className="invision-config__tag-text">{tag.mediaData.name}</p>
                              </>
                              : null
                          }
                          {
                            tag.mediaData.type === 'hyperLink'
                              ? <>
                                <i className="invision-icon-link invision-config__tag-icon"></i>
                                <p className="invision-config__tag-text">{tag.mediaData.name}</p>
                              </>
                              : null
                          }
                          {
                            tag.mediaData.type === 'image'
                              ? <>
                                <div className="invision-config__tag-media">
                                  <img width={105} height={60} src={imageProcess(mediaUrlProcess(tag.mediaData.mediaSrc, props.materialUrls), '1.jpg')} alt="图片" />
                                </div>
                                <div className="invision-config__tag-info">
                                  <p className="invision-config__tag-text">{tag.mediaData.name}</p>
                                  <p className="invision-config__tag-desc">{tag.mediaData.desc}</p>
                                </div>
                              </>
                              : null
                          }
                          {
                            tag.mediaData.type === 'video'
                              ? <>
                                <div className="invision-config__tag-media">
                                  <video
                                    width={105}
                                    height={60}
                                    src={mediaUrlProcess(tag.mediaData.mediaSrc, props.materialUrls)}
                                    poster={imageProcess(mediaUrlProcess(tag.mediaData.coverSrc, props.materialUrls), '1.jpg')}
                                    alt="视频"
                                  />
                                  <button className="invision-config__tag-play-btn">
                                    <img width={14} height={18} src={iconPlay} alt="" />
                                  </button>
                                </div>
                                <div className="invision-config__tag-info">
                                  <p className="invision-config__tag-text">{tag.mediaData.name}</p>
                                  <p className="invision-config__tag-desc">{tag.mediaData.desc}</p>
                                </div>
                              </>
                              : null
                          }
                          <div className="invision-config__tag-btns">
                            {/* <i className="invision-icon-edit"></i> */}
                            <i className="invision-icon-delete" onClick={(e) => {
                              e.stopPropagation();
                              props.handleDeleteTag(tag);
                            }}></i>
                          </div>
                        </div>
                        ))
                        : <div className="invision-config__empty">
                          <span>暂无内容</span>
                        </div>
                    }
                  </div>
                </>
                : null
            }
            {
              props.editType === 'tag' && showTagSetting
                ? <TagSetting
                  owner={props.owner}
                  currentEditMedia={currentEditMedia}
                  materialUrls={props.materialUrls}
                  handleShowTagSetting={handleShowTagSetting}
                  changeToAddTag={props.changeToAddTag}
                  deleteTag={props.deleteTag}
                  updateCurrentTag={props.updateCurrentTag} />
                : null
            }
            {/* 标签设置 tool end */}
            {/* 马赛克 tool start */}
            {
              props.editType === 'mosaic'
                ? <>
                  <div className="invision-config__header">
                    <h3 className="invision-config__title">马赛克</h3>
                  </div>
                  <div className="invision-config__body">
                    <div className="invision-config__item">
                      <ul className="model-mosaic__list">
                        {
                          props.mosaicsList.map(mosaic => (
                            <li className={ props.currentEditMosaicIndex === mosaic.sourceIndex ? 'is-selected' : ''} key={`mosaic${mosaic.sourceIndex}`} onClick={() => {
                              props.foucusToMosaic(mosaic);
                            }}>
                              <div className="model-mosaic__item">
                                <span>{mosaic.sourceIndex}</span>
                              </div>
                            </li>))
                          }
                      </ul>
                    </div>
                    {
                      props.mosaicMode && props.currentEditMosaicIndex !== -1
                        ? <div className="invision-config__item">
                          <p className="invision-config__text">面积</p>
                          <div className="model-mosaic-slider">
                            <Slider
                              className="model-mosaic-slider__wrap"
                              defaultValue={props.mosaicSize}
                              step={10}
                              renderTip={value => `${value}%`}
                              onChange={(value) => {
                                props.updateCurrentMosaicSize(value);
                              }}
                            />
                            <span className="model-mosaic-slider__value">{props.mosaicSize}%</span>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                </>
                : null
            }
            {/* 马赛克 tool end */}
            {/* 场景关联 tool start */}
            {
              props.editType === 'scenes' && !showSceneSetting
                ? <>
                   <div className="invision-config__header">
                    <Button className="invision-button invision-button--round invision-button--outline size-md" onClick={() => handleShowTagSetting(true, 'scenes')}>
                      <i className="invision-icon-add"></i>
                      <span>添加场景跳转</span>
                    </Button>
                  </div>
                  <div className="invision-config__body">
                    <p className="invision-config__text">场景跳转</p>
                    {
                      props.tagsList.filter(tag => tag.mediaData.type === 'scenes').length === 0
                        ? <div className="invision-config__empty">
                          <span>暂无内容</span>
                        </div>
                        : props.tagsList.filter(tag => tag.mediaData.type === 'scenes').map((tag, index) => (
                          <div key={index} className="scene-jump__card" onClick={() => {
                            handleMediaClick(tag);
                          }}>
                            <div className="scene-jump__card-head">
                              <img src={tag.mediaData.scene?.icon || iconLocation} alt="icon" />
                              <p className="scene-jump__card-desc">{tag.mediaData.name}</p>
                              <button className="scene-jump__config-close" onClick={(e) => {
                                e.stopPropagation();
                                props.handleDeleteTag(tag);
                              }}>
                                <img src={iconClose} alt="close" />
                              </button>
                            </div>
                            <div className="scene-jump__card-img-wrap">
                              <img src={props.materialsInProject.find(m => m.BasicInfo.MaterialId === tag.mediaData.scene.link)?.decorationInfoSet?.modelCover || screenShotDefault} alt="scene" />
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </>
                : null
            }
            {
              props.editType === 'scenes' && showSceneSetting
                ? <SceneSetting
                  owner={props.owner}
                  materialId={props.materialId}
                  currentEditMedia={currentEditMedia}
                  materialUrls={props.materialUrls}
                  handleShowTagSetting={handleShowTagSetting}
                  changeToAddTag={props.changeToAddTag}
                  deleteTag={props.deleteTag}
                  updateCurrentTag={props.updateCurrentTag}
                  materialsInProject={props.materialsInProject} />
                : null
            }
            {/* 场景关联 tool end */}
        </div>
          : null
      }
    </aside>
  );
};

HelperAside.propTypes = {
  modelIsLoading: PropTypes.bool,
  sdkType: PropTypes.string,
  vTour: PropTypes.object,
  vTourData: PropTypes.object,
  materialId: PropTypes.string,
  materialsInProject: PropTypes.array,
  materialUrls: PropTypes.object,
  editType: PropTypes.string,
  owner: PropTypes.object,
  initPanoIndex: PropTypes.number,
  viewSenseSrc: PropTypes.string,
  viewShotSrc: PropTypes.string,
  tagsList: PropTypes.array,
  mosaicSize: PropTypes.number,
  mosaicMode: PropTypes.bool,
  mosaicsList: PropTypes.array,
  currentEditMosaicIndex: PropTypes.number,
  updateCurrentMosaicSize: PropTypes.func,
  foucusToMosaic: PropTypes.func,
  handleDeleteTag: PropTypes.func,
  setPanoImgLoading: PropTypes.func,
  changeToAddTag: PropTypes.func,
  addOrUpdateTag: PropTypes.func,
  deleteTag: PropTypes.func,
  updateCurrentTag: PropTypes.func,
  setEditingTag: PropTypes.func,
};
