import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, message } from '@tencent/ten-design-react';
import PropTypes from 'prop-types';
import client from '../../utils/apiClient';
import './index.css';

ModelTitle.propTypes = {
  materialId: PropTypes.string,
  projectId: PropTypes.string,
  modelName: PropTypes.string,
};

function ModelTitle(props) {
  const history = useHistory();
  const [isEditingModelName, setIsEditingModelName] = useState('');
  const [modelName, setModelName] = useState(''); // 模型名称

  function handleTitleClick() {
    setIsEditingModelName(true);
  }

  function handleCancelEdit() {
    setIsEditingModelName(false);
  }

  async function handleTitleBlur(e) {
    if (e.target.value === '') {
      message.error('名字不能为空', 1000);
      return;
    }
    client('PaaS/Project/ModifyProject', { body: { ProjectId: props.projectId, Name: e.target.value } }).then((res) => {
      if (res.data.Code === 'Success') {
        message.success('设置完成', 1000);
        setIsEditingModelName(false);
        setModelName(e.target.value);
      }
    });
  }

  function goBackToModel() {
    const currentPathname = window.location.pathname.split('/');
    const routeName = currentPathname.pop();
    let targetRoute = '';
    if (routeName === 'detail') {
      targetRoute = '/project';
    } else {
      targetRoute = `/detail${window.location.search}`;
    }
    history.push(`${currentPathname.join('/')}${targetRoute}`);
  }

  useEffect(() => {
    setModelName(props.modelName);
  }, [props.modelName]);

  return (
    <header className="invision-header invision-layout__header">
      <nav className="invision-navbar">
        <div className="invision-navbar__back">
          <span className="invision-navbar__back-button" onClick={() => {
            goBackToModel();
          }}>
            <i className="invision-navbar__back-icon invision-icon-arrow-left"></i>
            <span className="invision-navbar__back-text">返回</span>
          </span>
        </div>
        <div className="invision-navbar__title">
          <div className="invision-navbar__title-rename">
            {
              isEditingModelName
                ? <>
                  <Input
                    className="invision-navbar__title-input"
                    size="small"
                    initialValue={modelName === '________' ? '' : modelName}
                    maxLength={ 100 }
                    placeholder="请输入模型名字"
                    style={{ width: '180px' }}
                    onBlur={handleTitleBlur}
                  />
                  <i className="invision-icon-cross" onClick={handleCancelEdit}></i>
                </>
                : <>
                  <span className="invision-navbar__title-text">{modelName}</span>
                  <i className="invision-icon-edit" onClick={handleTitleClick}></i>
                </>
            }
          </div>
        </div>
        <div className="invision-navbar__operation">
          <Button theme="primary" size="default" round={ true } onClick={() => {
            history.push(`${window.URL_BASE_NAME}/preview?projectId=${props.projectId}`);
          }}>预览分发</Button>
        </div>
      </nav>
    </header>
  );
}

export default ModelTitle;
