/*
 * @Descripttion:
 * @version: X3版本
 * @Author: maplexiao
 * @Date: 2020-11-20 16:12:02
 * @LastEditors: maplexiao
 * @LastEditTime: 2020-11-20 17:56:20
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AuthProvider } from './authProvider';
import { UserProvider } from './userProvider';

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.object,
};

export default AppProviders;
